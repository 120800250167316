import React from 'react'
import BreadCrum from '../../components/BreadCrum'

export default function SusPResult() {
  const breadCrum = {
    topName: "Suspended Results",
    lastName: "Suspended Results"
  }
  return (
    <div className='w-full min-h-[100vh] bg-slate-100'>
      <BreadCrum breadCrum={breadCrum}/>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 bg-white p-4 my-4">
        <div className='bg-[#1fabb5] text-center rounded p-2 text-white'>Suspended Fancy Result</div>
        <div className='bg-[#1fabb5] text-center rounded p-2 text-white'>Suspended Market Result</div>
      </div>
    </div>
  )
}
