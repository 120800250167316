import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import { useContext } from 'react';
import { MainContext } from './contexts/Main';
import allRouts from './routing';


function App() {
  const {isLogin} = useContext(MainContext);

  const routes = createBrowserRouter(allRouts);

  return (
    <RouterProvider router={routes}/>
  );
}

export default App;
