import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../components/BreadCrum";
import { MainContext } from "../contexts/Main";
import { PiWarningCircleLight } from "react-icons/pi";
import { IoIosSettings } from "react-icons/io";
import axios from "axios";
import { IoIosClose } from "react-icons/io";

export default function BlockMarket() {
  const [sportsData, setSportsData] = useState([]);
  const [popup, setPopUp] = useState(false);
  const { apiSportsUrl, apiBaseUrl, notify } = useContext(MainContext);
  const [close, setClose] = useState(null);

  const [confirmCheck, setConformCheck] = useState({
    id: undefined,
    status: undefined,
  });

  const breadCrum = {
    topName: "Block Sports",
    lastName: "Sports List",
  };

  // fetch all sports data
  const fetchSportsData = () => {
    axios
      .get(apiBaseUrl + apiSportsUrl)
      .then((success) => {
        if (success.data.status) {
          setSportsData(success.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // first render
  useEffect(fetchSportsData, []);

  const updateStatus = () => {
    if (confirmCheck.id != undefined && confirmCheck.status != undefined) {
      axios
        .patch(
          `${apiBaseUrl + apiSportsUrl}/update/${confirmCheck.id}/${
            confirmCheck.status
          }`
        )
        .then((success) => {
          fetchSportsData();
          if (success.data.status) {
            notify(success.data.message, "success");
          }
          console.log(success);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  console.log("object", sportsData)

  return (
    <div className="w-full min-h-[100vh]">
      <BreadCrum breadCrum={breadCrum} />
      <div className="lists border">
        <div className="heading font-semibold text-lg p-3 bg-white">List</div>
        <div className="overflow-x-auto">
          <table className="select-none w-full text-sm text-left border-2 rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs border-b-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  So.
                </th>
                <th scope="col" className="px-6 py-3">
                  BetfairId
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Name{" "}
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Sport Settings
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="">
              {sportsData.length != 0 ? (
                sportsData.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4">{data?.Id}</td>
                      <td className="px-6 py-4">{data?.SportId}</td>
                      <td className=" py-4 text-blue-600 font-semibold cursor-pointer">
                        {data?.SportName}
                      </td>
                      <td className="px-6 py-4 text-green-600">
                        Soccer is {data.Status == 1 ? "On" : "Off"}
                      </td>
                      <td className="px-6 py-4 text-green-600">
                        <button onClick={()=>setClose(data?.Id)} className="bg-[#1fabb5] flex p-2 relative  text-white items-center text-md rounded gap-1">
                          <IoIosSettings className="text-lg settingsIcon text-white" />
                          <span>Settings</span>
                          <GamePopUp data={data}  close={close} setClose={setClose}/>
                        </button>
                      </td>
                      <td className="px-6 py-4 ">
                        {data.Status == 1 ? (
                          <input
                            type="checkbox"
                            defaultValue={data.Status}
                            onChange={() => {
                              setConformCheck({
                                ...confirmCheck,
                                id: data.Id,
                                status: 0,
                              });
                              setPopUp(true);
                            }}
                            checked={data.Status == 1 ? true : false}
                            name=""
                            id=""
                          />
                        ) : (
                          <input
                            type="checkbox"
                            defaultValue={data.Status}
                            onChange={() => {
                              setConformCheck({
                                ...confirmCheck,
                                id: data.Id,
                                status: 1,
                              });
                              setPopUp(true);
                            }}
                            checked={data.Status == 1 ? true : false}
                            name=""
                            id=""
                          />
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="text-center">
                  <td className="font-bold p-4 w-full" colSpan={5}>
                    Loading Data....
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Toggle-PopUp */}
        <div
          style={{ background: "rgba(0,0,0,0.5)" }}
          className={`select-none z-[999999999] top-0 left-0 fixed w-[100vw] h-[100vh] duration-50 scale-0 opacity-0 ${
            popup ? "scale-100 opacity-100 duration-200" : ""
          }`}
        >
          <div className="grid place-items-center w-full  h-[100vh]">
            <div className="max-w-[70%] bg-white px-6 md:px-12 py-3 md:py-5 rounded-lg shadow-2xl">
              <div className="text-8xl text-yellow-500 flex justify-center items-center">
                <PiWarningCircleLight />
              </div>
              <div className="flex items-center flex-col gap-10">
                <div className="flex flex-col items-center gap-2">
                  <h2 className="font-semibold text-2xl text-center">
                    Are You Sure
                  </h2>
                  <p className="text-center">You Want to Block This Game!</p>
                </div>
                <div className="flex gap-2 justify-center">
                  <button
                    onClick={() => {
                      updateStatus();
                      setPopUp(false);
                    }}
                    className="bg-blue-500 text-white px-3 rounded py-2"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => {
                      setPopUp(false);
                    }}
                    className="bg-red-500 text-white px-2 rounded py-2"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const GamePopUp = ({data, close, setClose}) => {
  const {apiBaseUrl, apiSportsUrl, notify} = useContext(MainContext);
  const [updateData, setUpdateData] = useState({
    Id: "",
    MaxOddLimit: "",
    MinOddLimit: "",
    MaxBookmakerLimit: "",
    MinBookmakerLimit: "",
    MaxFancyLimit: "",
    MinFancyLimit: ""
  })

  useEffect(()=>{
    setUpdateData({
      Id: data?.Id,
      MaxOddLimit: data?.MaxOddLimit,
      MinOddLimit: data?.MinOddLimit,
      MaxBookmakerLimit: data?.MaxBookmakerLimit,
      MinBookmakerLimit: data?.MinBookmakerLimit,
      MaxFancyLimit: data?.MaxFancyLimit,
      MinFancyLimit: data?.MinFancyLimit
    })
  },[]);

  const updateHandler = () => {
    console.log("id", updateData?.Id)
    axios.put(`${apiBaseUrl+apiSportsUrl}/update-limit/${updateData.Id}`,updateData).then((success)=>{
      if(success.data.status){
        setClose(null);
        notify("Data Updated", "success");
      }else{
        notify(success.data.success, "error");
      }
    }).catch((err)=>{
      notify("Client Side Error", "error");
    })
  }

  return (
    <div className={`fixed bg-[rgba(0,0,0,0.3)] z-[999999] p-4 top-0 left-0 w-full min-h-[100vh] duration-200 ${data?.Id==close?"visible opacity-100":"opacity-0 invisible"}`}>
      <div className="max-w-[650px] mx-auto p-2">
        <div className="flex justify-between font-medium text-[15px] p-2 bg-[#1fabb5]">
          <span>{data?.SportName}</span>
          <span className="text-2xl">
            <IoIosClose onClick={(e)=>{
              e.stopPropagation()
              setClose(null)
            }} />
          </span>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 border py-3">
                  
                </th>
                <th scope="col" className="px-6 border py-3">
                  Min
                </th>
                <th scope="col" className="px-6 border py-3">
                  Max
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 border">Odds</td>
                <td onChange={(e)=>{
                  setUpdateData({
                    ...updateData,
                    MinOddLimit: e.target.value
                  })
                }} className="px-6 py-4 border"><input className="border-2 p-1 rounded outline-none" type="number" name="MinOddLimit" defaultValue={updateData?.MinOddLimit} id="" /></td>
                <td onChange={(e)=>{
                  setUpdateData({
                    ...updateData,
                    MaxOddLimit: e.target.value
                  })
                }} className="px-6 py-4 border"><input className="border-2 p-1 rounded outline-none" type="number" name="MaxOddLimit" defaultValue={updateData?.MaxOddLimit}  id="" /></td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 border">BookMarker</td>
                <td onChange={(e)=>{
                  setUpdateData({
                    ...updateData,
                    MinBookmakerLimit: e.target.value
                  })
                }} className="px-6 py-4 border"><input className="border-2 p-1 rounded outline-none" type="number" name="MinBookmakerLimit" defaultValue={updateData?.MinBookmakerLimit} id="" /></td>
                <td onChange={(e)=>{
                  setUpdateData({
                    ...updateData,
                    MaxBookmakerLimit: e.target.value
                  })
                }} className="px-6 py-4 border"><input className="border-2 p-1 rounded outline-none" type="number" name="MaxBookmakerLimit" defaultValue={updateData?.MaxBookmakerLimit} id="" /></td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 border">Session</td>
                <td onChange={(e)=>{
                  setUpdateData({
                    ...updateData,
                    MinFancyLimit: e.target.value
                  })
                }} className="px-6 py-4 border"><input className="border-2 p-1 rounded outline-none" type="number" name="MinFancyLimit" defaultValue={updateData?.MinFancyLimit} id="" /></td>
                <td onChange={(e)=>{
                  setUpdateData({
                    ...updateData,
                    MaxFancyLimit: e.target.value
                  })
                }} className="px-6 py-4 border"><input className="border-2 p-1 rounded outline-none" type="number" name="MaxFancyLimit" defaultValue={updateData?.MaxFancyLimit} id="" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex bg-white gap-2 justify-start p-2"><button onClick={(e)=>{
              e.stopPropagation()
              setClose(null)
            }} className="bg-[#1fabb5] rounded p-2">Cancel</button><button onClick={updateHandler} className="bg-[#1fabb5] rounded p-2">Update</button></div>
      </div>
    </div>
  );
};
