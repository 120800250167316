import React, { useState } from 'react';
import BreadCrum from '../components/BreadCrum';

export default function Language() {
  const [language,setLanguage] = useState(0);
  const breadCrum = {
    topName: "Change Language",
    lastName: "Language"
  }
  return (
    <div className='w-full min-h-[100vh] bg-gray-100 '>
      <BreadCrum breadCrum={breadCrum} />
      <div className='shadow-lg bg-whitefont-semibold'>
        <div className="heading text-[14px] font-bold border-b p-4 ">
          Change Language
        </div><hr />
        <div className='flex flex-col gap-2 p-4 w-full'>
           <div onClick={()=>{
            setLanguage(0)
           }} className={`px-3 text-center sm:w-[50%] w-full cursor-pointer lg:w-[30%] py-1 text-black text-[14px]  border  duration-300  ${language==0?"bg-[#6c8dab] text-white":"bg-gray-200 hover:bg-[#f0f0f0] hover:text-black"}  rounded`}>English</div>
           <div onClick={()=>{
            setLanguage(1)
           }} className={`px-3 text-center sm:w-[50%] w-full cursor-pointer lg:w-[30%] py-1 text-black text-[14px]  border  duration-300  ${language==1?"bg-[#6c8dab] text-white":"bg-gray-200 hover:bg-[#f0f0f0] hover:text-black"}  rounded`}>हिंदी</div>
           <div onClick={()=>{
            setLanguage(2)
           }} className={`px-3 text-center sm:w-[50%] w-full cursor-pointer lg:w-[30%] py-1 text-black text-[14px]  border  duration-300  ${language==2?"bg-[#6c8dab] text-white":"bg-gray-200 hover:bg-[#f0f0f0] hover:text-black"}  rounded`}>தமிழ்</div>
           <div onClick={()=>{
            setLanguage(3)
           }} className={`px-3 text-center sm:w-[50%] w-full cursor-pointer lg:w-[30%] py-1 text-black text-[14px]  border  duration-300  ${language==3?"bg-[#6c8dab] text-white":"bg-gray-200 hover:bg-[#f0f0f0] hover:text-black"}  rounded`}>मराठी</div>
        </div>
      </div>
    </div>
  )
}
