import React, { useContext, useEffect, useState } from "react";
import { IoIosClose, IoIosSettings } from "react-icons/io";
import BreadCrum from "../../components/BreadCrum";
import { MainContext } from "../../contexts/Main";
import { PiWarningCircleLight } from "react-icons/pi";
import Moment from "react-moment";
import axios from "axios";

export default function MatchSettings() {
  const [matchData, setMatchData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [active, setActive] = useState({
    gameId: undefined,
    ActiveMatch: undefined,
    IsBetLock: undefined,
    Matchodds: undefined,
    BookMaker: undefined,
    Fancy: undefined,
  });
  const [length, setLength] = useState(null);
  const [filterMatchStatus, setFilterMatchStatus] = useState(null);
  const [filterMatchSport, setFilterMatchSport] = useState(null);
  const [found, setFound] = useState(true);
  const [next, setNext] = useState(true);
  const [page, setPage] = useState(1);
  const [popup, setPopUp] = useState(false);
  const { apiSportsUrl, apiBaseUrl, notify, findMatchAllData, spiner, setSpiner } =
    useContext(MainContext);
  const [close, setClose] = useState(null);

  const fetchAllData = () => {
    setSpiner(true);
    findMatchAllData(page, filterMatchStatus, filterMatchSport, searchName)
      .then((success) => {
        console.log("api succces in match settings: ",success)
        if (success.result.length != 0) {
          setFound(true);
          setNext(true);
          setMatchData(success.result);
        } else {
          setSpiner(false);
          setFound(false);
          setNext(false);
        }

        setLength(success.total_length);
      })
      .catch((err) => {
        setSpiner(false);
        console.log(err);
      });
  };


  useEffect(fetchAllData, [page, filterMatchSport, filterMatchStatus]);
  const breadCrum = {
    topName: "Match Settings",
    lastName: "match",
  };

  return (
    <div className="w-full min-h-[100vh]">
      <BreadCrum breadCrum={breadCrum} />
      <div className="lists border">
        <div className="heading font-semibold text-lg p-3 bg-[#1fabb5] text-white">
          Match Setting
        </div>
        <div className="flex gap-4 sm:justify-start justify-center p-2 flex-wrap my-4">
          <div className="flex gap-2">
            <select
              value={filterMatchStatus}
              onChange={(v) => {
                setSearchName('');
                setFilterMatchStatus(v.target.value);
                setPage(1);
              }}
              className="px-2 py-1 border"
              name=""
              id=""
            >
              <option value={""}>Select Status</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
            <select
              value={filterMatchSport}
              onChange={(e) => {
                setSearchName('');
                setPage(1);
                setFilterMatchSport(e.target.value);
              }}
              className="px-2 py-1 border"
              name=""
              id=""
            >
              <option value={""}>Select Sport</option>
              <option value={1}>Soccer</option>
              <option value={2}>Tennis</option>
              <option value={4}>Cricket</option>
            </select>
          </div>
          <div className="flex gap-2">
            <input
              placeholder="Match Name"
              className="px-3 py-1 shadow-sm border active:border-none outline-[#1fabb5]"
              type="search"
              value={searchName}
              onChange={(e) => {
                setFilterMatchSport("");
                setFilterMatchStatus("");
                setSearchName(e.target.value);
              }}
              name=""
              id=""
            />
            <button
              onClick={() => {
                setPage(1)
                setFilterMatchSport("");
                setFilterMatchStatus("");
                fetchAllData();
              }}
              className="px-2 rounded py-1 border bg-[#1fabb5] text-white"
            >
              Search
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left border-2 rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs border-b-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  So.
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  MatchName
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap ">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Settings
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Match ON/OFF
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Bet Lock ON/OFF
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Match Odds ON/OFF
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  BookMarker ON/OFF
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Fancy ON/OFF
                </th>
              </tr>
            </thead>

            <tbody className="">
              {setSpiner(false)}
              {found ? (
                matchData.length != 0 ? (
                  matchData.map((data, index) => {
                    return (
                      <tr className="bg-white border-b text-nowrap dark:bg-gray-800 dark:border-gray-700">
                        <td className="px-6 py-4">
                          {index + (page - 1) * 50 + 1}
                        </td>
                        <td className="px-6 py-4">{data.eventName}</td>
                        <td className=" py-4 text-blue-600 font-semibold cursor-pointer">
                          {
                            <Moment format="DD/MM/YY, hh:mm:ss A">
                              {data.eventDate}
                            </Moment>
                          }
                        </td>
                        <td className="px-6 py-4 text-green-600">
                          <button
                            onClick={() => setClose(data.gameId)}
                            className="bg-[#1fabb5] flex p-2 relative  text-white items-center text-md rounded gap-1"
                          >
                            <IoIosSettings className="text-lg settingsIcon text-white" />
                            <span>Settings</span>
                            <GamePopUpSecond
                              data={data}
                              close={close}
                              setClose={setClose}
                            />
                          </button>
                        </td>
                        <td className="px-6 py-4 ">
                          <input
                            onChange={(e) => {
                              setActive({
                                ...active,
                                ActiveMatch: e.target.checked ? 1 : 0,
                                gameId: data.gameId,
                              });
                              setPopUp(true);
                            }}
                            type="checkbox"
                            checked={data.ActiveMatch == 1 ? true : false}
                            name=""
                            id=""
                          />
                        </td>
                        <td className="px-6 py-4 ">
                          <input
                            onChange={(e) => {
                              setActive({
                                ...active,
                                IsBetLock: e.target.checked ? 1 : 0,
                                gameId: data.gameId,
                              });
                              setPopUp(true);
                            }}
                            type="checkbox"
                            checked={data.IsBetLock == 1 ? true : false}
                            name=""
                            id=""
                          />
                        </td>
                        <td className="px-6 py-4 ">
                          <input
                            onChange={(e) => {
                              setActive({
                                ...active,
                                Matchodds: e.target.checked ? 1 : 0,
                                gameId: data.gameId,
                              });
                              setPopUp(true);
                            }}
                            type="checkbox"
                            checked={data.Matchodds == 1 ? true : false}
                            name=""
                            id=""
                          />
                        </td>
                        <td className="px-6 py-4 ">
                          <input
                            onChange={(e) => {
                              setActive({
                                ...active,
                                BookMaker: e.target.checked ? 1 : 0,
                                gameId: data.gameId,
                              });
                              setPopUp(true);
                            }}
                            type="checkbox"
                            checked={data.BookMaker == 1 ? true : false}
                            name=""
                            id=""
                          />
                        </td>
                        <td className="px-6 py-4 ">
                          <input
                            onChange={(e) => {
                              setActive({
                                ...active,
                                Fancy: e.target.checked ? 1 : 0,
                                gameId: data.gameId,
                              });
                              setPopUp(true);
                            }}
                            type="checkbox"
                            checked={data.Fancy == 1 ? true : false}
                            name=""
                            id=""
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    <td className="font-bold p-4 w-full" colSpan={5}>
                      Loading Data....
                    </td>
                  </tr>
                )
              ) : (
                <tr className="text-center">
                  <td className="font-bold p-4 w-full" colSpan={5}>
                    Data Not Found....
                  </td>
                </tr>
              )
            }{setSpiner(false)}
            </tbody>
          </table>
        </div>

        {/* Toggle-PopUp */}
        <div
          style={{ background: "rgba(0,0,0,0.5)" }}
          className={`select-none z-[999999999] top-0 left-0 fixed w-[100vw] h-[100vh] duration-50 scale-0 opacity-0 ${
            popup ? "scale-100 opacity-100 duration-200" : ""
          }`}
        >
          <div className="grid place-items-center w-full  h-[100vh]">
            <div className="max-w-[70%] bg-white px-6 md:px-12 py-3 md:py-5 rounded-lg shadow-2xl">
              <div className="text-8xl text-yellow-500 flex justify-center items-center">
                <PiWarningCircleLight />
              </div>
              <div className="flex items-center flex-col gap-10">
                <div className="flex flex-col items-center gap-2">
                  <h2 className="font-semibold text-2xl text-center">
                    Are You Sure
                  </h2>
                  <p className="text-center">You Want to Block This Game!</p>
                </div>
                <div className="flex gap-2 justify-center">
                  <button
                    onClick={() => {
                      const {
                        gameId,
                        ActiveMatch,
                        IsBetLock,
                        Matchodds,
                        BookMaker,
                        Fancy,
                      } = active;
                      if (gameId != undefined) {
                        // for change match on/off
                        if (ActiveMatch != undefined) {
                          axios
                            .patch(
                              `${
                                apiBaseUrl + apiSportsUrl
                              }/update-match-status/${gameId}`,
                              { ActiveMatch }
                            )
                            .then((success) => {
                              if (success.data.status) {
                                console.log("match on/of success", success);
                                fetchAllData();
                                notify(success.data.message, "success");
                                setActive({
                                  gameId: undefined,
                                  ActiveMatch: undefined,
                                  IsBetLock: undefined,
                                  Matchodds: undefined,
                                  BookMaker: undefined,
                                  Fancy: undefined,
                                });
                              } else {
                                notify(success.data.message, "error");
                              }
                            })
                            .catch((err) => {
                              notify("Client Side Error", "error");
                            });
                        }

                        // for Change betLock Status on/off
                        else if (IsBetLock != undefined) {
                          axios
                            .patch(
                              `${
                                apiBaseUrl + apiSportsUrl
                              }/update-bet-status/${gameId}`,
                              { IsBetLock }
                            )
                            .then((success) => {
                              console.log("bet on/of success", success);
                              if (success.data.status) {
                                fetchAllData();
                                notify(success.data.message, "success");
                              } else {
                                notify(success.data.message, "error");
                              }
                              setActive({
                                gameId: undefined,
                                ActiveMatch: undefined,
                                IsBetLock: undefined,
                                Matchodds: undefined,
                                BookMaker: undefined,
                                Fancy: undefined,
                              });
                            })
                            .catch((err) => {
                              notify("Client Side Error", "error");
                            });
                        }

                        // for match odds
                        else if (Matchodds != undefined) {
                          axios
                            .patch(
                              `${
                                apiBaseUrl + apiSportsUrl
                              }/update-odds-status/${gameId}`,
                              { Matchodds }
                            )
                            .then((success) => {
                              console.log("Matchodds on/of success", success);
                              if (success.data.status) {
                                fetchAllData();
                                notify(success.data.message, "success");
                              } else {
                                notify(success.data.message, "error");
                              }
                              setActive({
                                gameId: undefined,
                                ActiveMatch: undefined,
                                IsBetLock: undefined,
                                Matchodds: undefined,
                                BookMaker: undefined,
                                Fancy: undefined,
                              });
                            })
                            .catch((err) => {
                              console.log(err);
                              notify("Client Side Error", "error");
                            });
                        }

                        // for BookMaker status
                        else if (BookMaker != undefined) {
                          axios
                            .patch(
                              `${
                                apiBaseUrl + apiSportsUrl
                              }/update-bookmaker-status/${gameId}`,
                              { BookMaker }
                            )
                            .then((success) => {
                              console.log("BookMaker on/of success", success);
                              if (success.data.status) {
                                fetchAllData();
                                notify(success.data.message, "success");
                              } else {
                                notify(success.data.message, "error");
                              }
                              setActive({
                                gameId: undefined,
                                ActiveMatch: undefined,
                                IsBetLock: undefined,
                                Matchodds: undefined,
                                BookMaker: undefined,
                                Fancy: undefined,
                              });
                            })
                            .catch((err) => {
                              console.log(err);
                              notify("Client Side Error", "error");
                            });
                        }

                        // for BookMaker status
                        else if (Fancy != undefined) {
                          axios
                            .patch(
                              `${
                                apiBaseUrl + apiSportsUrl
                              }/update-fancy-status/${gameId}`,
                              { Fancy }
                            )
                            .then((success) => {
                              console.log("Fancy on/of success", success);
                              if (success.data.status) {
                                fetchAllData();
                                notify(success.data.message, "success");
                              } else {
                                notify(success.data.message, "error");
                              }
                              setActive({
                                gameId: undefined,
                                ActiveMatch: undefined,
                                IsBetLock: undefined,
                                Matchodds: undefined,
                                BookMaker: undefined,
                                Fancy: undefined,
                              });
                            })
                            .catch((err) => {
                              console.log(err);
                              notify("Client Side Error", "error");
                            });
                        }
                      } else {
                        notify("gameId is not defined", "error");
                      }
                      setPopUp(false);
                      fetchAllData();
                    }}
                    className="bg-blue-500 text-white px-3 rounded py-2"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => {
                      setPopUp(false);
                    }}
                    className="bg-red-500 text-white px-2 rounded py-2"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex text-sm my-4 p-3 justify-center sm:flex-row gap-5 flex-col flex-wrap items-center sm:justify-between">
          <div>
            Showing {page * 50 - 49 >= 0 ? page * 50 - 49 : 0} to{" "}
            {page * 50 < length ? page*50: length } of entries {length}
          </div>
          <div className="border rounded">
            <button
              onClick={() => {
                if (page == 1) return;
                setPage(page - 1);
              }}
              className="px-2 py-1 border-r "
            >
              Previous
            </button>
            <button className="px-2">{page}</button>
            <button
              disabled={
                page * 50 > length ? length : page * 50 == length ? true : false
              }
              onClick={(e) => {
                setPage(page + 1);
              }}
              className="px-2 py-1 border-l"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const GamePopUpSecond = ({ data, close, setClose }) => {
  const { apiBaseUrl, apiSportsUrl, notify } = useContext(MainContext);
  const [updateData, setUpdateData] = useState({
    MaxOddLimit: "",
    MinOddLimit: "",
    MaxBookmakerLimit: "",
    MinBookmakerLimit: "",
    MaxFancyLimit: "",
    MinFancyLimit: "",
  });

  useEffect(() => {
    const { gameId, eid, tournamentId } = data;
    if (
      gameId !== undefined &&
      eid !== undefined &&
      tournamentId !== undefined
    ) {
      axios
        .post(`${apiBaseUrl + apiSportsUrl}/get-match-settings`, {
          gameId,
          eid,
          tournamentId,
        })
        .then((success) => {
          if (success.data.status) {
            setUpdateData(success.data.result[0]);
          } else {
            console.log("-------", success);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const updateHandler = () => {
    axios
      .put(
        `${apiBaseUrl + apiSportsUrl}/update-match-settings/${data.gameId}`,
        updateData
      )
      .then((success) => {
        console.log(success);
        if (success.data.status) {
          setClose(null);
          notify("Data Updated", "success");
        } else {
          notify(success.data.success, "error");
        }
      })
      .catch((err) => {
        notify("Client Side Error", "error");
      });
  };

  return (
    <div
      className={`fixed bg-[rgba(0,0,0,0.3)] z-[999999] p-4 top-0 left-0 w-full min-h-[100vh] duration-200 ${
        data?.gameId == close ? "visible opacity-100" : "opacity-0 invisible"
      }`}
    >
      <div className="max-w-[650px] mx-auto p-2">
        <div className="flex justify-between font-medium text-[15px] p-2 bg-[#1fabb5]">
          <span>{data?.SportName}</span>
          <span className="text-2xl">
            <IoIosClose
              onClick={(e) => {
                e.stopPropagation();
                setClose(null);
              }}
            />
          </span>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 border py-3"></th>
                <th scope="col" className="px-6 border py-3">
                  Min
                </th>
                <th scope="col" className="px-6 border py-3">
                  Max
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 border">Odds</td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MinOddLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MinOddLimit"
                    defaultValue={updateData?.MinOddLimit}
                    id=""
                  />
                </td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MaxOddLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MaxOddLimit"
                    defaultValue={updateData?.MaxOddLimit}
                    id=""
                  />
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 border">BookMarker</td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MinBookmakerLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MinBookmakerLimit"
                    defaultValue={updateData?.MinBookmakerLimit}
                    id=""
                  />
                </td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MaxBookmakerLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MaxBookmakerLimit"
                    defaultValue={updateData?.MaxBookmakerLimit}
                    id=""
                  />
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 border">Session</td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MinFancyLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MinFancyLimit"
                    defaultValue={updateData?.MinFancyLimit}
                    id=""
                  />
                </td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MaxFancyLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MaxFancyLimit"
                    defaultValue={updateData?.MaxFancyLimit}
                    id=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex bg-white gap-2 justify-start p-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setClose(null);
            }}
            className="bg-[#1fabb5] rounded p-2"
          >
            Cancel
          </button>
          <button onClick={updateHandler} className="bg-[#1fabb5] rounded p-2">
            Update
          </button>
        </div>
      </div>
    </div>
  );
};
