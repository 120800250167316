import React from 'react'

export default function Footer() {
  return (
    <footer className='flex mt-10 sm:flex-row flex-col bottom-0 w-full items-center gap-2 sm:justify-between border text-[14px] text-gray-600 px-4 py-2 border-t-1'>
        <div className='flex gap-2 text-[14px]'>
            <span><b>NICE247.PRO</b></span>
            <span>|</span>
            <span>Copyright © 2014-2021</span>
        </div>
        <div>
            Admin Panel v2.0.0
        </div>
    </footer>
  )
}
