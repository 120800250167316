import React, { useContext, useEffect, useState } from "react";

import { TiEdit } from "react-icons/ti";
import { IoLockClosed } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CreateNewClient from "./CreateNewClient";
import { MainContext } from "../contexts/Main";

export default function ManageTable({ data, create, blockUser}) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [dataWithPagination, setDataWithPagination] = useState([]);
  const { edit, setEdit, unBlockUser, setUnBloackUser, search, setSearch } =
    useContext(MainContext);

  let endSlice = 50 * page,
    startSlice = endSlice - 50;
  useEffect(() => {
    if (data.length != 0) {
      let updatedData = data.slice(startSlice, endSlice);
      console.log("update Data: ", updatedData);

      if (updatedData.length != 0) {
        setDataWithPagination(updatedData);
      } else {
        setDataWithPagination([]);
      }
    } else {
      setDataWithPagination([]);
    }
  }, [page, data]);


  useEffect(()=>{
    if(dataWithPagination.length==0){
      if(page!=1){
        setPage(page-1);
      }
    }
  },[dataWithPagination])

  console.log("edits: ",edit)

  return (
    <>
      <div className="w-full">
        {create != false && (
          <div className="flex justify-between items-center border-t-2 border-b-[1px] p-2">
            <span className="font-semibold text-gray-700">All Agents</span>
            <span>
              <button
                onClick={() => {
                  navigate("/create-client");
                }}
                className="bg-[#1fabb5] text-white text-[16px] px-2 py-1 rounded-md"
              >
                Create {create}
              </button>
            </span>
          </div>
        )}

        <div className="w-full table  px-2 pt-1">
          <div className="flex shrink-1 flex-wrap gap-2 justify-between items-center p-4">
            <div className=" flex gap-3 uppercase">
              <button className="px-2 select-none rounded-sm hover:bg-gray-200 uppercase text-sm bg-gray-100 text-black border border-black">
                csf
              </button>
              <button className="px-2 select-none rounded-sm hover:bg-gray-200 uppercase text-sm bg-gray-100 text-black border border-black">
                pdf
              </button>
            </div>
            <div className="select-none searchInput">
              <input
                onChange={(e)=>setSearch(e.target.value)}
                value={search}
                type="search"
                name=""
                className="outline-none px-2 font-semibold border-2"
                placeholder="Search.."
                id=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative overflow-x-auto my-2">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs border-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Id
              </th>
              <th scope="col" className="px-6 py-3">
                User Name
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Website
              </th>
              <th scope="col" className="px-6 py-3">
                Fix Limit
              </th>
              <th scope="col" className="px-6 py-3">
                My Share
              </th>
              <th scope="col" className="px-6 py-3">
                Max Share
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="font-manage">
            {dataWithPagination.length != 0 &&
              dataWithPagination.map((d, i) => {
                return (
                  <tr
                    key={i}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td className="px-6 py-4"> {d?.Id}</td>
                    <td
                      onClick={() => {
                        navigate("/user-dashboard/" + d?.UserName);
                        setEdit(dataWithPagination[i]);
                      }}
                      className="px-6 py-4 cursor-pointer text-blue-400 font-semibold"
                    >
                      {d?.UserName} (
                      {d.FullName != undefined ? d?.FullName : "-"})
                    </td>
                    <td className="px-6 py-4">
                      {d.FullName != undefined ? d?.FullName : "-"}
                    </td>
                    <td className="px-6 py-4">nice247.pro</td>
                    <td className="px-6 py-4">-</td>
                    <td className="px-6 py-4">-</td>
                    <td className="px-6 py-4">-</td>
                    <td className="px-6 py-4 flex gap-2 items-center">
                      <button
                        onClick={() => {
                          if (blockUser) {
                            setUnBloackUser(blockUser);
                          }
                          navigate("/edit-user/" + d?.UserName);
                          setEdit(dataWithPagination[i]);
                        }}
                        className="px-2 py-1 rounded-md me-1 border text-black flex gap-1 items-center"
                      >
                        <TiEdit /> edit
                      </button>
                      <button
                        onClick={() => {
                          navigate("/change-password/" + d?.UserName);
                          setEdit(dataWithPagination[i]);
                        }}
                        className="uppercase rounded-md px-2 py-1 bg-[#1fabb5] text-white"
                      >
                        pwd
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="flex text-sm my-4 p-3 items-center gap-4 flex-col sm:flex-row sm:justify-between">
        <div>
          Showing {page * 50 - 50 + 1} to{" "}
          {dataWithPagination.length < 50
            ? data.length
            : dataWithPagination.length}{" "}
          of entries {data.length}
        </div>
        <div className="border rounded">
          <button
            onClick={() => {
              if (page == 1) return;
              setPage(page - 1);
            }}
            className="px-2 py-1 border-r "
          >
            Previous
          </button>
          <button className="px-2">{page}</button>
          <button
            onClick={(e) => {
              // if(dataWithPagination.length<50) return;
              setPage(page + 1);
            }}
            className="px-2 py-1 border-l"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
