import React from 'react'
import BreadCrum from '../../components/BreadCrum'

export default function Result() {
  const breadCrum = {
    topName: "Results",
    lastName: "Results"
  }
  return (
    <div className='w-full min-h-[100vh] bg-slate-100'>
      <BreadCrum breadCrum={breadCrum}/>
      <div className="flex justify-center gap-4 bg-white p-4 my-4">
        <div className='bg-[#1fabb5] max-w-[30%] text-center rounded px-4 py-2 text-white'>Deposit Chips</div>
        <div className='bg-[#1fabb5] max-w-[30%] text-center rounded px-4 py-2 text-white'>Add WebSite</div>
      </div>
    </div>
  )
}
