import React from 'react'
import BreadCrum from '../../components/BreadCrum'

export default function Surveillance() {
  const breadCrum = {
    topName: "Surveillance",
    lastName: "Surveillance"
  }
  return (
    <div className='w-full min-h-[100vh] bg-slate-100'>
    <BreadCrum breadCrum={breadCrum}/>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4">
      <div>
        <div className='grid gap-2 md:grid-cols-2'>
            <div className='bg-[#1fabb5] p-2 text-center rounded text-white'>User Surveillance</div>
            <div className='bg-[#1fabb5] p-2 text-center rounded text-white'>Surveillance User P/L</div>
        </div>
      </div>
      <div>
        <div className='grid grid-cols-2 gap-2'>
          <div className='text-white text-center rounded bg-black p-2'>Rejected Bets</div>
          <div className='text-white text-center rounded bg-black p-2'>Rejected Bets</div>
          <div className='text-white text-center rounded bg-red-500 p-2'>Rejected Bets</div>
        </div>
      </div>
    </div>
  </div>
  )
}
