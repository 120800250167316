import React from 'react'
import BreadCrum from '../../components/BreadCrum'

export default function Info() {
  const breadCrum = {
    topName: "Information",
    lastName: "Information"
  }
  return (
    <div className='w-full min-h-[100vh] bg-slate-100'>
      <BreadCrum breadCrum={breadCrum}/>
      <div className="flex justify-center my-5 gap-3">
        <div className='bg-[#1fabb5] text-white p-2  rounded'>Roll Black Info</div>
        <div className='bg-[#1fabb5] text-white p-2  rounded'>Pending Result</div>
      </div>
    </div>
  )
}
