import React from 'react'
import BreadCrum from '../../components/BreadCrum'

export default function Sport() {
  const breadCrum = {
    topName: "Matches",
    lastName: "Matches"
  }
  return (
    <div className='w-full bg-slate-100 min-h-[100vh]'>
      <BreadCrum breadCrum={breadCrum}/>
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-4 bg-white">
        <div className='bg-[#1fabb5] p-2 rounded text-white text-center'>Live Market Bets</div>
        <div className='bg-[#1fabb5] p-2 rounded text-white text-center'>Open Bets</div>
        <div className='bg-[#1fabb5] p-2 rounded text-white text-center'>Statements</div>
        <div className='bg-[#1fabb5] p-2 rounded text-white text-center'>Profit & Loss</div>
        <div className='bg-[#1fabb5] p-2 rounded text-white text-center'>BetFair Matches</div>
      </div>
      <div className=' bg-white my-4 items-center border-t-2 border--b[1px] p-2'>
            <div className='font-semibold text-gray-700'>All Matches</div>
      </div>
      <div className="relative overflow-x-auto p-4 bg-white select-none">
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <thead>
              <tr>
                <th className='border bg-slate-100 p-2 font-semibold text-[14px] text-black'>Title</th>
                <th className='border bg-slate-100 p-2 font-semibold text-[14px] text-black'>Sport</th>
                <th className='border bg-slate-100 p-2 font-semibold text-[14px] text-black'>DATE</th>
                <th className='border bg-slate-100 p-2 font-semibold text-[14px] text-black'>Type</th>
                <th className='border bg-slate-100 p-2 font-semibold text-[14px] text-black'>Declared</th>
                <th className='border bg-slate-100 p-2 font-semibold text-[14px] text-black'>Won By</th>
                <th className='border bg-slate-100 p-2 font-semibold text-[14px] text-black'>Profit / Loss</th>
              </tr>
            </thead>
            <tbody className='text-[14px] search_user font-normal'>
              {/* <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                <th className='border-2 p-2 font-normal text-gray-600'>15 may 2024</th>
                <th className='border-2 p-2 font-normal text-gray-600'>1111111111</th>
                <th className='border-2 p-2 font-normal text-[#337ab7]'>	Rajasthan Royals v Punjab Kings</th>
                <th className='border-2 p-2 font-normal text-gray-600'>	1813949.25</th>
                <th className='border-2 p-2 font-normal text-gray-600'>-181435.16</th>
                <th className='border-2 p-2 font-normal text-gray-600'>0.00</th>
              </tr> */}
            </tbody>
          </table>
        </div>
    </div>
  )
}
