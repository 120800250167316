import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../components/BreadCrum";
import { AiTwotoneTool } from "react-icons/ai";
import { useParams } from "react-router-dom";
import axios from "axios";
import { MainContext } from "../contexts/Main";

export default function Stmt() {
  const [dataByUserId, setDataByUserId] = useState([]);
  const [date, setDate] = useState({
    from: new Date(new Date().setDate(new Date().getDate() - 2))
      .toISOString()
      .slice(0, 10),
    to: new Date().toISOString().slice(0, 10),
  });
  const { apiBaseUrl, apiAdminsUrl } = useContext(MainContext);
  const { userId, username, role } = useParams();
  console.log("role is : ", role);

  const fetchAllData = () => {
    if (userId != undefined && date.from != undefined && date.to != undefined) {
      axios
        .get(
          `${apiBaseUrl + apiAdminsUrl}/getUserProfitLoss/${userId}?from=${
            date.from
          }&to=${date.to}`
        )
        .then((success) => {
          if (success.data.status) {
            setDataByUserId(success.data.result);
          } else {
            console.log("success: ", success);
          }
        })
        .catch((err) => {
          console.log("error: ", err);
        });
    }
  };
  useEffect(fetchAllData, [userId]);

  console.log("dataByUserId: ", dataByUserId);
  const breadCrum = {
    topName: "Agent",
    lastName: "Statement",
  };
  return (
    <div className="w-full min-h-[100vh] bg-gray-100 ">
      <BreadCrum breadCrum={breadCrum} />
      <div className="grid grid-cols-2 p-2 lg:flex gap-2">
        <input
          value={date.from}
          max={date.to}
          onChange={(e) => {
            setDate({ ...date, from: e.target.value });
          }}
          className="px-2 lg:w-[200px] py-1 text-gray-500 text-[14px] border border-black"
          type="date"
          name=""
          id=""
        />
        <input
          value={date.to}
          min={date.from}
          onChange={(e) => {
            setDate({ ...date, to: e.target.value });
          }}
          className="px-2 lg:w-[200px] py-1 text-gray-500 text-[14px] border border-black"
          type="date"
          name=""
          id=""
        />
        <button
          onClick={fetchAllData}
          className="bg-[#1fabb5] col-span-2 sm:col-span-1 text-white py-1 px-2 rounded text-[14px]"
        >
          Search
        </button>
      </div>
      <div className="w-full my-5 shadow-lg bg-white overflow-x-auto border-t-2 border-gray-300">
        <div className="p-3 border-b-2 border-gray-100 items-center flex justify-between">
          <div className="text-[13px] font-[500] select-none">
            Statement of {username}
          </div>
          <AiTwotoneTool className="text-gray-400 text-xl" />
        </div>
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="border px-4 text-[13px] font-[500] py-2">
                  Date
                </th>
                <th className="border px-4 text-[13px] font-[500] py-2">
                  Description
                </th>
                <th className="border px-4 text-[13px] font-[500] py-2">Cr</th>
                <th className="border px-4 text-[13px] font-[500] py-2">Dbt</th>
                <th className="border px-4 text-[13px] font-[500] py-2">
                  Com+
                </th>
                <th className="border px-4 text-[13px] font-[500] py-2">
                  Com-
                </th>
                {
                  role==8?"":
                  <th className="border px-4 text-[13px] font-[500] py-2">
                    Balance
                  </th>
                }
              </tr>
            </thead>
            <tbody className="text-[13px]">
              {dataByUserId.length != 0
                ? dataByUserId.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td className="border text-nowrap p-2">
                          {new Date(row.Date).toUTCString().replace("GMT", "")}
                        </td>
                        <td className="border text-nowrap p-2">
                          {row.Event != null
                            ? row.ResultAmount < 0
                              ? `Loss From ${row.UserName} By EventName ${row.EventName} Market ${row.Market}` +
                                (row.Market === "Fancy"
                                  ? ` Selection ${row.Selection}`
                                  : "")
                              : `Profit By ${row.UserName} By EventName ${row.EventName} Market ${row.Market}` +
                                (row.Market === "Fancy"
                                  ? ` Selection ${row.Selection}`
                                  : "")
                            : row.UserId > row.ToUser
                            ? row.Withdraw == 0
                              ? `Chip Credited to ${row.UserUserName} (${row.UserFullName}) by ${row.ToUserUserName} (${row.ToUserFullName}) - Done By - ${row.ByUserName}`
                              : `Chip Withdrawn to ${row.UserUserName} (${row.UserFullName}) by ${row.ToUserUserName} (${row.ToUserFullName}) - Done By - ${row.ByUserName}`
                            : row.Withdraw == 0
                            ? `Limit Decreased for ${row.ToUserUserName} (${row.ToUserFullName}) by ${row.UserUserName} (${row.UserFullName}) - Done By - ${row.ByUserName}`
                            : `Chip Credited to ${row.ToUserUserName} (${row.ToUserFullName}) by ${row.UserUserName} (${row.UserFullName}) - Done By - ${row.ByUserName}`}
                        </td>

                        <td className="border text-nowrap  p-2">
                          {row.Event != undefined
                            ? row.ResultAmount < 0
                              ? 0
                              : row.ResultAmount
                            : row.Deposit}
                        </td>
                        <td className="border text-nowrap  p-2">
                          {row.Event != undefined
                            ? row.ResultAmount >= 0
                              ? 0
                              :  row.ResultAmount
                            : row.Withdraw}
                        </td>
                        <td className="border text-nowrap  p-2">{0}</td>
                        <td className="border text-nowrap  p-2">{0}</td>
                        {
                          role==8?"":
                        <td className="border text-nowrap  p-2">
                        {row.Balance}
                      </td>
                        }
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
