import React from 'react'
import BreadCrum from '../components/BreadCrum'

export default function NullUsers() {
  const breadCrum = {
    topName: "Null Values Users",
    lastName: "Null Values Users"
  }
  return (
    <div className='w-full min-h-[100vh] bg-gray-100 p-4'>
      <BreadCrum breadCrum={breadCrum}/>
      <div className="my-4 w-full bg-white p-5">
        
      </div>
      <div className="text-center font-semibold text-[14px] my-2">No User Availble</div>
    </div>
  )
}
