import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BreadCrum from "../../components/BreadCrum";
import axios from "axios";
import { MainContext } from "../../contexts/Main";
import moment from "moment";

function BetFairMatches() {
  const { apiBaseUrl, apiSportsUrl } = useContext(MainContext);
  const [stateData, setStateData] = useState([]);
  const [matches, setMatches] = useState(null);
  const { seriesId } = useParams();
  const breadCrum = {
    topName: "Matches",
    lastName: "Settings Dashboard",
    prevPath: "/Settings",
    finalName: "Betfair Matches",
  };
  console.log("matches: ", matches);
  const getBetFairMatches = () => {
    if (seriesId) {
      axios
        .get(`${apiBaseUrl + apiSportsUrl}/matches/getbetfair/${seriesId}`)
        .then((success) => {
          console.log("Success: ", success);
          if (success.data.status) {
            setStateData(success.data.result);
          } else {
            setStateData([]);
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          setStateData([]);
        });
    }
  };

  useEffect(getBetFairMatches, [seriesId]);

  console.log("State Data: ", stateData);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[matches])

  return (
    <div className="w-full min-h-[100vh] bg-gray-100">
      <BreadCrum breadCrum={breadCrum} />
      {matches == null ? (
        <>
          <div className="w-full">
            <button className="px-4 mx-3 my-2 rounded-md py-1 bg-[#23c6c8] text-white">
              <Link to={"/betfair"}>Back</Link>
            </button>
          </div>

          {stateData.length != 0
            ? stateData.map((item) => {
                return (
                  <div className="flex justify-between items-center px-4 py-2 border-t-2 border-gray-300 mt-1">
                    <div className="text-center ">
                      <div>
                        {moment(item?.eventDate).format(
                          "ddd, DD MMM YYYY HH:mm:ss"
                        )}
                      </div>
                      <div className="font-bold text-[#2789CE] text-md">
                        {" "}
                        {item?.eventName}
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        setMatches(item);
                      }}
                      className="px-3 py-1 bg-[#23c6c8] rounded-md text-white"
                    >
                      Market
                    </button>
                  </div>
                );
              })
            : ""}
        </>
      ) : (
        <>
          <div className="w-full">
            <button
              onClick={() => setMatches(null)}
              className="px-4 mx-3 my-2 rounded-md py-1 bg-[#1c84c6] text-white"
            >
              Back
            </button>
          </div>
          <div className="w-full overflow-auto">
            <table className="w-full bg-white">
                <thead className="">
                    <tr className="w-full border-b-2 border-gray-200">
                        <th className="text-left text-[13px] px-6 py-3 text-gray-500">Id</th>
                        <th className="text-left text-[13px] px-6 py-3 text-gray-500">Market Name</th>
                        <th className="text-left text-[13px] px-6 py-3 text-gray-500">Total Matched</th>
                        <th className="text-left text-[13px] px-6 py-3 text-gray-500"></th>
                    </tr>
                </thead>
                <tbody className="text-left">
                    <tr className="w-full border-b-2 border-gray-200">
                        <td className="text-left text-[#2789CE] font-bold px-6 py-3">{matches?.marketId}</td>
                        <td className="text-left text-[#2789CE] font-bold px-6 py-3">MatchOdds</td>
                        <td className="text-left text-[#2789CE] font-bold px-6 py-3">{matches?.totalMatched}</td>
                        <td className="text-left text-[#2789CE] font-bold px-6 py-3"><input type="checkbox" name="" id="" /></td>
                    </tr>
                </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default BetFairMatches;
