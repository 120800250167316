import React from 'react'
import BreadCrum from '../components/BreadCrum';
import ManageTable from '../components/ManageTable';

export default function Agent() {
  const breadCrum = {
    topName: "Agent",
    lastName: "Agent"
};
  return (
    <div className='min-h-[100vh]'>
        <BreadCrum breadCrum={breadCrum}/>
        <ManageTable create={breadCrum}/>
    </div>
  )
}
