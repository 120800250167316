import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../contexts/Main";
import axios from "axios";

export default function CreateNewClient() {
  const navigate = useNavigate();
  const { admin_stages, isLogin, apiBaseUrl, apiUserUrl, notify, } =
    useContext(MainContext);
  const [userDetails, setUserDetails] = useState({
    webSite: "nice247.pro",
    userName: "",
    fullName: "",
    fixLimit: "0",
    myshare: "0",
    share: "0",
    match_commission: "0",
    session_commission: "0",
    password: "",
    confirm_pass: "",
    roleId: isLogin.roleId + 1,
    ParentId: isLogin.userId,
    ParentName: isLogin.userName,
  });

  const breadCrum = admin_stages.find(
    (data) => isLogin.roleId + 1 == data.roleId
  );

  useEffect(() => {
    axios
      .get(`${apiBaseUrl + apiUserUrl}/newId`)
      .then((success) => {
        if (success.data.status) {
          setUserDetails({
            ...userDetails,
            userName: breadCrum?.ShortName + success.data.id,
          });
        }
      })
      .catch((err) => {
        notify("Error", "error");
      });
  }, []);

  const submitHandler = (e) => {
    let {
      webSite,
      userName,
      fullName,
      fixLimit,
      myshare,
      share,
      match_commission,
      session_commission,
      password,
      confirm_pass,
    } = userDetails;
    if (
      webSite != "" &&
      userName != "" &&
      fullName != "" &&
      fixLimit != "" &&
      share != "" &&
      match_commission != "" &&
      session_commission != "" &&
      password != "" &&
      confirm_pass != ""
    ) {
      if (password === confirm_pass) {
        if (password.length>=5) {
          axios.post(`${apiBaseUrl+apiUserUrl}/add-new-user`, userDetails).then((success)=>{
            if(success.data.status){
              setUserDetails({
                webSite: "nice247.pro",
                userName: "",
                fullName: "",
                fixLimit: "0",
                myshare: "0",
                share: "0",
                match_commission: "0",
                session_commission: "0",
                password: "",
                confirm_pass: "",
                roleId: isLogin.roleId + 1,
                ParentId: isLogin.userId,
                ParentName: isLogin.userName,
              });
              notify(success.data.message, "success");
              setTimeout(()=>{
                navigate(breadCrum?.path);
              },1000)
            }
          }).catch((err)=>{
            notify("error", "error");
          })
        } else {
          notify('The length of the input exceeds 5 characters.');
        }
      } else {
        notify("Both password must match", "error");
      }
    } else {
      notify("enter complete data", "error");
    }
  };

  return (
    <div className="min-h-[100vh] bg-gray-100">
      <div className="px-4 select-none bg-white md:py-2 py-4 border-t-[1px] border-b-[1px] my-2">
        <h2 className="lg:text-2xl text-lg font-sans text-gray-500 uppercase">
          New {breadCrum?.roleName}
        </h2>
        <div className="">
          <Link to={"/dashboard"}>
            <span className="text-sm font-semibold text-gray-500">
              Dashboard
            </span>
          </Link>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            <Link to={breadCrum?.path}>{breadCrum?.roleName}</Link>
          </span>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            Create {breadCrum?.roleName}
          </span>
        </div>
      </div>
      <div className="newSc p-2 border bg-white">
        <div className="p-3 font-bold border-b">New {breadCrum?.roleName}</div>
        <div className="pt-2">
          {/* website name */}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 select-none font-bold w-full md:w-[20%] text-left md:text-right">
              Select Website
            </div>
            <div className="  md:w-[80%] w-full select-none border  rounded">
              <input
                className="px-4 py-1 cursor-not-allowed select-none w-full outline-green-500"
                type="text"
                value={userDetails.webSite}
                readOnly
              />
            </div>
          </div>

          {/* user name */}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              User Name
            </div>
            <div className="  md:w-[80%] w-full border  rounded">
              <input
                className="px-4 py-1 w-full outline-green-500"
                type="text"
                value={userDetails.userName}
                readOnly
              />
            </div>
          </div>

          {/* full name */}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              Full Name
            </div>
            <div className="  md:w-[80%] w-full border  rounded">
              <input
                className="px-4 py-1 w-full outline-green-500"
                type="text"
                value={userDetails.fullName}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    fullName: event.target.value,
                  });
                }}
              />
            </div>
          </div>

          {/* Fix limit */}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              Fix Limit
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 newClient block border py-1 w-full outline-green-500"
                type="number"
                value={userDetails.fixLimit}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    fixLimit: event.target.value,
                  });
                }}
              />
              <div>
                <b>N</b>ote : Fix Limit can be set from 0 to 6973604959.00
              </div>
            </div>
          </div>

           {/*my Match Share*/}
           <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              My Match Share
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 newClient block border py-1 w-full outline-green-500"
                type="number"
                value={userDetails.myshare}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    myshare: event.target.value,
                  });
                }}
              />
              <div>
                <b>N</b>ote : My Match Share can be set from 0 to 100
              </div>
            </div>
          </div>

          {/*Tech_Admin Match Share*/}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              {breadCrum?.roleName} Match Share
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 newClient block border py-1 w-full outline-green-500"
                type="number"
                value={userDetails.share}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    share: event.target.value,
                  });
                }}
              />
              <div>
                <b>N</b>ote : My Match Share can be set from 0 to 100
              </div>
            </div>
          </div>

          {/*Tech_Admin Match Commission */}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              {breadCrum?.roleName} Match Commission
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 block newClient border py-1 w-full outline-green-500"
                type="number"
                value={userDetails.match_commission}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    match_commission: event.target.value,
                  });
                }}
              />
              <div>
                <b>N</b>ote : Note : SC Match Share can be set to 100
              </div>
            </div>
          </div>

          {/*Tech_Admin Session Commission*/}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              {breadCrum?.roleName} Session Commission
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 block newClient border py-1 w-full outline-green-500"
                type="number"
                value={userDetails.session_commission}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    session_commission: event.target.value,
                  });
                }}
              />
            </div>
          </div>

          {/* password field */}
          <div className="gap-2  md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              Password
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 block border py-1 w-full outline-green-500"
                type="password"
                value={userDetails.password}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    password: event.target.value.replace(" ", ""),
                  });
                }}
              />
            </div>
          </div>

          {/* confirm password */}
          <div className="gap-2 md:py-2 py-4 text-sm flex md:flex-row flex-col justify-center items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              Confirm password
            </div>
            <div className=" md:w-[80%] w-full rounded">
              <input
                className="px-4 block border py-1 w-full outline-green-500"
                type="password"
                value={userDetails.confirm_pass}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    confirm_pass: event.target.value.replace(" ", ""),
                  });
                }}
              />
            </div>
          </div>
          <div className="md:grid grid-cols-5 bg-white lg:gap-4 text-black">
            <div></div>
            <div className="col-span-4">
              <button className="px-4 mx-2 mt-2 py-1 border  rounded">
                Cancel
              </button>
              <button
                onClick={submitHandler}
                className="px-4 mx-2 mt-2 py-1 border bg-[#1fabb5] text-white rounded"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
