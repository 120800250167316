import React, { useContext, useEffect, useState } from "react";
import { AiFillTool } from "react-icons/ai";
import { FaAngleUp } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import BreadCrum from "../../components/BreadCrum";
import { MainContext } from "../../contexts/Main";
import { Link } from "react-router-dom";
import axios from "axios";
import { IoMdTime } from "react-icons/io";
import Moment from "react-moment";

export default function MatchLedger() {
  const { edit } = useContext(MainContext);
  const [p_sData, setP_SData] = useState([]);
  const [sport, setSport] = useState([]);

  const { apiBaseUrl, apiAdminsUrl, notify } = useContext(MainContext);
  const twoDaysBefore = () => {
    const today = new Date();
    today.setDate(today.getDate() - 2);
    return today.toISOString().split("T")[0];
  };
  const [date, setDate] = useState({
    from: twoDaysBefore(),
    to: new Date().toISOString().split("T")[0],
  });

  const breadCrum = {
    topName: "Agent",
    lastName: edit?.UserName + " (" + edit?.FullName + ")",
    finalName: "Ledger",
  };



  
  const fetchAllData = () => {
    if (edit?.Id != undefined) {
      if (date.from != "" && date.to != "") {
        const dateQuery = new URLSearchParams(date).toString();
        axios
          .post(
            `${
              apiBaseUrl + apiAdminsUrl
            }/profitloss/${edit?.Id}/${edit?.RoleId}?${dateQuery}`,
            { sport }
          )
          .then((success) => {
            if (success.data.status) {
              console.log("success proift and loss: ", success);
              setP_SData(success.data.result);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      } else {
        notify("Date MisMatch", "error");
      }
    }
  };
  useEffect(fetchAllData, [edit]);


  return (
    <div className="w-full bg-slate-100 min-h-[100vh]">
      <div className="px-4 bg-white py-2 border-t-[1px] border-b-[1px] my-2">
        <h2 className="lg:text-2xl text-lg font-sans text-gray-500 uppercase">
          {breadCrum?.topName}
        </h2>
        <div className="">
          <Link to={"/dashboard"}>
            <span className="text-sm font-semibold text-gray-500">
              Dashboard
            </span>
          </Link>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            <Link to={"/user-dashboard/" + edit?.UserName}>
              {breadCrum?.lastName}
            </Link>
          </span>
          {breadCrum.finalName && (
            <>
              <span className="text-gray-400 px-2">/</span>
              <span className="text-sm font-semibold text-black uppercase">
                {breadCrum?.finalName}
              </span>
            </>
          )}
        </div>
      </div>
      

      <div className="flex gap-4 flex-wrap justify-center sm:justify-start content-center">
        <input
          className="px-2 py-1 rounded outline-blue-500"
          value={date.from}
          max={date.to}
          onChange={(e) => {
            setDate({
              ...date,
              from: e.target.value,
            });
          }}
          type="date"
          name=""
          id=""
        />
        <input
          className="px-2 py-1 rounded outline-blue-500"
          value={date.to}
          min={date.from}
          max={new Date().toISOString().slice(0, 10)}
          onChange={(e) => {
            setDate({
              ...date,
              to: e.target.value,
            });
          }}
          type="date"
          name=""
          id=""
        />
        <button
          onClick={fetchAllData}
          className="bg-[#1fabb5] py-1 px-3 rounded text-white"
        >
          Search
        </button>
      </div>
      <div className="bg-white my-3">
        <div className="flex justify-between p-2 items-center">
          <h2 className="text-gray-600 font-semibold text-lg">Client Ledger</h2>
          <div className="flex text-[16px]">
            <FaAngleUp className="text-gray-400" />
            <AiFillTool className="text-gray-400" />
            <IoClose className="text-gray-400 " />
          </div>
        </div>
        <div className="relative overflow-x-auto p-2 shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs border-b-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  DATE/TIME
                </th>
                <th scope="col" className="px-6 py-3">
                  Collection Name
                </th>
                <th scope="col" className="px-6 py-3">
                  DEBIT
                </th>
                <th scope="col" className="px-6 py-3">
                  CREDIT
                </th>
                <th scope="col" className="px-6 py-3">
                  Note
                </th>
              </tr>
            </thead>
            <tbody>
              {
                p_sData.length != 0 && 
                p_sData.map((item, index)=>{
                  return(
                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="px-6 py-4 flex items-center gap-2"><IoMdTime className="font-bold text-black" /><Moment className="font-semibold" format='MMM D,'>{item?.SettleTime}</Moment><Moment format=' HH:mm:ss a'>{item?.SettleTime}</Moment></td>
                      <td className="px-6 py-4">{item?.EventName}</td>
                      <td className="px-6 py-4">{item?.ResultAmount<0?item?.ResultAmount:0}</td>
                      <td className="px-6 py-4">{item?.ResultAmount>0?item?.ResultAmount:0}</td>
                      <td className="px-6 py-4">-</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
