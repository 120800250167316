import React, { useContext, useEffect, useState } from 'react'
import BreadCrum from '../components/BreadCrum'
import { MainContext } from '../contexts/Main';
import ManageTable from '../components/ManageTable';

export default function SuperAdmin() {
  const {dataByParent,fetchDatabyParendId, isLogin,admin_stages, search} = useContext(MainContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchDatabyParendId().then(({ data }) => {
      const newData = data.filter((data) => {
        if (data.RoleId == 3) {
          return true;
        }
      });
      setData(newData.reverse());
    });
  }, [search]);
  console.log("data state:",data)
  const breadCrum = {
    topName: "Super-Admin",
    lastName: "Super-admin",
  }
  return (
    <div className='w-full min-h-[100vh]'>
      <BreadCrum breadCrum={breadCrum}/>
      {
        // data.length!=0 &&
      <ManageTable data={data}  create={isLogin?.roleId+1==admin_stages[2]?.roleId&&"SA"}/>
      }
    </div>
  )
}
