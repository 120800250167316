import React, { useContext } from "react";
import CollectionReport from "../../components/CollectionReport";
import BreadCrum from "../../components/BreadCrum";
import { Link } from "react-router-dom";
import { MainContext } from "../../contexts/Main";

export default function Colection() {
  const { prevPath } = useContext(MainContext);

  const data = [
    {
      title: "PAYMENT RECEIVING FROM (Lena hai)",
      Data: [
        {
          Client: "SP1(demo)",
          Balance: 58.0,
        },
        {
          Client: "SP10(Demo10)",
          Balance: 1024.0,
        },
      ],
    },
    {
      title: "PAYMENT PAID TO (Dena hai)",
      Data: [
        {
          Client: "SC33905(Medical)",
          Balance: -671.1,
        },
        {
          Client: "SC63122(Haseeb)",
          Balance: -845.5,
        },
      ],
    },
    // {
    //   title: "PAYMENT CLEAR (clear hai)",
    //   Data: [
    //     {
    //       Client: "SC4(Surajsnd)",
    //       Balance: "0.00"
    //     }
    //   ]
    // }
  ];
  return (
    <div className="w-full bg-gray-100 mb-5 min-h-[100vh]">
      <div className="py-2 px-4 bg-white">
        <h2 className="text-gray-600 text-2xl mb-2 ">{prevPath?.title}</h2>
        <div className="flex gap-2 flex-wrap text-sm">
          <span>
            <Link to={"/dashboard"}>Dashboard</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={"/liveGames/1"}>Matches</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={prevPath?.pathname}>{prevPath?.eventName}</Link>
          </span>
          <span className="font-semibold">/</span>
          <span className="font-semibold">{prevPath?.title}</span>
        </div>
      </div>
      <div className="flex gap-2 p-2 xl:flex-row flex-col">
        <CollectionReport data={data} />
      </div>
    </div>
  );
}
