import axios from "axios";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
const MainContext = createContext();

function Main(props) {
  const encryptionKey = "asdfg@#$";
  const [aside, setAside] = useState(true);
  const [loginNotify, setLoginNotify] = useState(true);
  const [toggleHide, setToggleHide] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [ip, setIp] = useState(undefined);
  const [geoLoc, setGeoLoc] = useState(undefined);
  const [dataByParent, setDataByParent] = useState([]);
  const [refetch, setRefetchData] = useState(false);
  const [edit, setEdit] = useState(null);
  const [spiner, setSpiner] = useState(false);
  const [agents, setAgents] = useState([]);
  const [unBlockUser, setUnBloackUser] = useState(null);
  const [matchOddsNegativeValue, setMatchOddsNegativeValue] = useState([]);
  const [bookMakerNegativeValue, setBookMakerNegativeValue] = useState([]);
  const [fancyNegativeValue, setFancyNegativeValue] = useState([]);
  const [prevPath, setPrevPath] = useState(null);
  const [search, setSearch] = useState("");
  const [heading, setHeading] = useState("");
  const [heading1, setHeading1] = useState("");
  const [betFairInfo, setBetFairInfo] = useState({
    back: "",
    seriesId: "",
  });
  const [impMsgUser, setImpMsgUser] = useState("");
  const [impMsgMgmt, setImpMsgMgmt] = useState("");
  //console.log("Search ", search);

  useEffect(() => {
    if (prevPath != null) {
      Cookies.set("prevPath", JSON.stringify(prevPath));
    }
  }, [prevPath]);

  useEffect(() => {
    const prevPath = Cookies.get("prevPath");
    if (prevPath != undefined) {
      setPrevPath(JSON.parse(prevPath));
    }
  }, []);

  useEffect(() => {
    if (unBlockUser != null) {
      Cookies.set("unblockpage", JSON.stringify(unBlockUser));
    }
  }, [unBlockUser]);
  useEffect(() => {
    const getUserPath = Cookies.get("unblockpage");
    if (getUserPath != undefined) {
      setUnBloackUser(JSON.parse(getUserPath));
    }
  }, []);

  useEffect(() => {
    const editData = Cookies.get("editData");
    if (editData != null) {
      setEdit(JSON.parse(editData));
    }
  }, []);

  useEffect(() => {
    if (edit != null) {
      Cookies.set("editData", JSON.stringify(edit));
    }
  }, [edit]);
  const admin_stages = [
    {
      roleName: "Domain",
      roleId: 1,
      path: "/domain",
      ShortName: "DO",
    },
    {
      roleName: "TechAdmin",
      roleId: 2,
      path: "/techadmin",
      ShortName: "TA",
    },
    {
      roleName: "SuperAdmin",
      roleId: 3,
      path: "/superadmin",
      ShortName: "SA",
    },
    {
      roleName: "SubAdmin",
      roleId: 4,
      path: "/subadmin",
      ShortName: "SU",
    },
    {
      roleName: "SuperSuper",
      roleId: 5,
      path: "/super-super",
      ShortName: "SS",
    },
    {
      roleName: "Super",
      roleId: 6,
      path: "/super",
      ShortName: "SP",
    },
    {
      roleName: "Master",
      roleId: 7,
      path: "/master",
      ShortName: "MA",
    },
    {
      roleName: "User",
      roleId: 8,
      path: "/user",
      ShortName: "US",
    },
  ];

  const findAdminStage = useMemo(() => {
    if (isLogin != false) {
      let stage = admin_stages.find((stage) => stage.roleId == isLogin.roleId);
      return stage;
    }
  }, [isLogin]);

  let ipUrl = "https://api.ipify.org/?format=json";
  let geoLocationUrl = "https://freeipapi.com/api/json/"; // + ip

  // enc. data
  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
  };
  //  decr. data
  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  // fetch ip
  const fetchIP = () => {
    axios
      .get(ipUrl)
      .then((ip) => {
        setIp(ip.data.ip);
      })
      .catch((err) => {
        //console.log("ip not found..");
      });
  };

  // store in the cookies (login-info)
  // useEffect(() => {
  //   if (isLogin != false) {

  //   }
  // }, [isLogin]);

  useEffect(() => {
    const encryptedLoginData = Cookies.get("LoginData");
    if (encryptedLoginData != undefined) {
      const decryptedLoginData = JSON.parse(decryptData(encryptedLoginData));
      setIsLogin(decryptedLoginData);
    }
  }, []);

  const fetchGeoLocation = () => {
    if (ip != undefined) {
      axios
        .get(geoLocationUrl + ip)
        .then((location) => {
          setGeoLoc(location.data);
        })
        .catch((err) => {
          //console.log("geo location not found");
        });
    }
  };

  useEffect(fetchIP, []);
  useEffect(fetchGeoLocation, [ip]);

  useEffect(() => {
    if (toggleHide) {
      setAside(true);
    }
  }, [toggleHide]);

  // .env files apis
  let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  let apiSportsUrl = process.env.REACT_APP_API_SPORTS_URL;
  let apiAdminsUrl = process.env.REACT_APP_API_ADMIN_URL;
  let apiUserUrl = process.env.REACT_APP_API_USER_URL;

  // logout admin
  const logOutHandler = () => {
    // Change the URL without reloading the page
    window.history.pushState(null, "", "/admin/login");

    // Optionally, you can dispatch an event to handle the route change
    window.dispatchEvent(new PopStateEvent("popstate"));
    if (isLogin != false) {
      axios
        .patch(
          `${apiBaseUrl + apiAdminsUrl}/change-login-status/${isLogin.userId}`
        )
        .then((success) => {
          if (success.data.status) {
            Cookies.remove("LoginData");
            setIsLogin(false);
            localStorage.removeItem("currenturl");
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  // notify message
  const notify = (msg, type) => toast(msg, { type, autoClose: 500 });

  async function fetchDatabyParendId() {
    const query = new URLSearchParams({ search });
    //console.log("query: ", query.toString())
    const encryptedLoginData = await Cookies.get("LoginData");
    const parentId = await JSON.parse(decryptData(encryptedLoginData));
    let res = await fetch(
      `${apiBaseUrl + apiAdminsUrl}/getUserByParentId/${
        parentId.userId
      }?${query.toString()}`
    );
    return await res.json();
  }

  const findSeriesAllData = async () => {
    const response = await fetch(
      `${apiBaseUrl + apiSportsUrl}/getSeriesAllData`
    );
    const data = await response.json();
    return data;
  };

  const findMatchAllData = async (
    page,
    filterMatchStatus,
    filterMatchSport,
    searchName
  ) => {
    if (page <= 0) {
      page = 1;
    }
    //console.log("pagesss", page);
    const searchParams = new URLSearchParams();
    if (filterMatchStatus != null && filterMatchStatus != "") {
      searchParams.append("ActiveMatch", filterMatchStatus);
    }
    if (
      filterMatchSport != null &&
      filterMatchSport != "" &&
      filterMatchSport != "Select Sport"
    ) {
      searchParams.append("eid", filterMatchSport);
    }
    if (searchName != "" && searchName != null) {
      searchParams.append("eventName", searchName);
    }
    const response = await fetch(
      `${apiBaseUrl + apiSportsUrl}/match/${page}?${searchParams.toString()}`
    );
    const data = await response.json();
    return data;
  };

  const getInOutPlay = async (status, page, selectedMatch) => {
    try {
      const response = await fetch(
        `${
          apiBaseUrl + apiSportsUrl
        }/matches/get-in-out-play/${status}/${page}/${selectedMatch}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      //console.log(error);
    }
  };

  const getCompletedMatch = async (sportId = 4, page = 1, filterDate) => {
    try {
      let login = decryptData(Cookies.get("LoginData"));
      login = JSON.parse(login);
      //console.log("userId comp: ",login.userId)
      //console.log("filterZDate", filterDate);
      const searchParams = new URLSearchParams(filterDate);
      const response = await fetch(
        `${apiBaseUrl + apiSportsUrl}/completed-matches/${sportId}/${page}/${
          login.userId
        }?${searchParams.toString()}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      //console.log("completed match error:", error);
      throw error;
    }
  };

  const MaxNegValMatchOdds = [];
  const MaxNegValBookMaker = [];
  const allSumByEv = [];

  const fetchExposure = async (userId) => {
    setBookMakerNegativeValue([]);
    setFancyNegativeValue([]);
    setMatchOddsNegativeValue([]);
    //console.log("userIDs",userId);
    try {
      const response = await fetch(
        `${apiBaseUrl + apiAdminsUrl}/info/betsByUserId/${userId}`
      );
      const data = await response.json();

      const betData = data.betData;
      const runnersData = data.runnerData;
      const MatchOddsFilter = [];
      const MatchOddsBetsFilter = [];
      const BookMakerBetsFilter = [];
      const BookMakerFilter = [];
      const range = (selectionId, max, min, EvId) => {
        let rng = max - min + 10;
        let filterBySelId = betData.filter((data) => {
          return (
            data.SelectionId == selectionId &&
            data.Market == "Fancy" &&
            data.EventId == EvId
          );
        });
        let startIndex = min - 5,
          endIndex = startIndex + rng - 1;
        let calcEach = [];
        for (let i = startIndex; i <= endIndex; i++) {
          //console.log("index: ",i)
          let count = i;
          let sum = 0;
          filterBySelId.forEach((item) => {
            if (item.Type == "lay") {
              if (item.OddsRequest <= i) {
                sum += item.Liability;
              } else {
                sum += item.Profit;
              }
            } else {
              if (item.OddsRequest <= i) {
                sum += item.Profit;
              } else {
                sum += item.Liability;
              }
            }
          });
          calcEach.push({
            [i]: sum,
          });
        }
        let Allvalue = [];
        calcEach.forEach((item) => {
          if (Object.values(item)[0] < 0) {
            Allvalue.push(...Object.values(item));
          }
        });
        let maxNegVal = Math.min(...Allvalue);

        //console.log("Start & EndIndex => ", startIndex, " & ", endIndex);
        //console.log("filtered data By SelectionId : ", filterBySelId);
        //console.log(`range of ${selectionId} selectionId: ${rng}`);
        //console.log("Calculated information of Fancy Market: ", calcEach);
        //console.log("Calculated values: ", maxNegVal);
        return maxNegVal;
      };

      let Match = null;

      if (data.uniqueEventIds.length !== 0) {
        data.uniqueEventIds.forEach((element) => {
          betData.forEach((matchs) => {
            if (matchs.EventId == element) {
              Match = matchs.Event;
            }
          });

          let fanc = [];
          betData.forEach((item) => {
            if (item.Market == "Fancy" && item.EventId == element) {
              fanc.push(item.SelectionId);
            }
          });
          fanc = [...new Set(fanc)];
          let fancInfo = [];
          fanc.forEach((item) => {
            let d = [];
            betData.forEach((innerItem) => {
              if (
                item == innerItem.SelectionId &&
                innerItem.Market == "Fancy" &&
                innerItem.EventId == element
              ) {
                d.push(innerItem.OddsRequest);
              }
            });
            fancInfo.push({ [item]: d });
          });
          let sumfanc = 0;
          fancInfo.forEach((item) => {
            const getKeys = Object.keys(item);
            getKeys.length !== undefined &&
              getKeys.forEach((inItem) => {
                //console.log("valuess: ", item[inItem]);
                const min = Math.min(...item[inItem]);
                const max = Math.max(...item[inItem]);
                sumfanc += range(inItem, max, min, element);

                // minMaxCalc[inItem] = {
                //   min,
                //   max,
                // };
                // //console.log("min-max of " + inItem + " : ", min, " & ", max);
              });

            // minMaxAll.push(minMaxCalc);
          });
          if (sumfanc != 0) {
            allSumByEv.push({
              [element]: sumfanc,
              Market: "Fancy",
              Match,
              exposure: sumfanc,
            });
          }
          //console.log("AllSumBy => ",allSumByEv);
          // //console.log("MinMaxAll: ", minMaxAll);
          //console.log("fancyInfo:  ", fancInfo);

          // Filter betData for the current event
          const betsItem = betData.filter((bets) => {
            return bets.EventId == element && bets.Market == "Matchodds";
          });

          // Push the betsItem for the current event to MatchOddsBetsFilter
          MatchOddsBetsFilter.push({
            [element]: betsItem,
          });

          // Initialize data object for the current event
          let data = {};

          // Process each bet item ( Teams )
          betsItem.forEach((bett) => {
            runnersData.forEach((runner) => {
              if (element == runner.EventId && runner.Market == "Match Odds") {
                if (bett.Type == "back") {
                  if (runner.SelectionId == bett.SelectionId) {
                    // Calculate profit for matching SelectionId
                    data[runner.SelectionId] =
                      data[runner.SelectionId] == undefined
                        ? bett.Profit
                        : data[runner.SelectionId] + bett.Profit;
                  } else {
                    // Calculate liability for non-matching SelectionId
                    data[runner.SelectionId] =
                      data[runner.SelectionId] == undefined
                        ? bett.Liability
                        : data[runner.SelectionId] + bett.Liability;
                  }
                } else {
                  {
                    if (runner.SelectionId == bett.SelectionId) {
                      // Calculate profit for matching SelectionId
                      data[runner.SelectionId] =
                        data[runner.SelectionId] == undefined
                          ? bett.Liability
                          : data[runner.SelectionId] + bett.Liability;
                    } else {
                      // Calculate liability for non-matching SelectionId
                      data[runner.SelectionId] =
                        data[runner.SelectionId] == undefined
                          ? bett.Profit
                          : data[runner.SelectionId] + bett.Profit;
                    }
                  }
                }
              }
            });
          });

          // Push the data object for the current event to MatchOddsFilter if it's not empty
          if (Object.keys(data).length > 0) {
            MatchOddsFilter.push({
              [element]: data,
            });
            const value = Math.max(Object.values(data).filter((v) => v < 0));
            MaxNegValMatchOdds.push({
              [element]: value,
              exposure: value,
              Market: "Matchodds",
              Match,
            });
            //console.log("data of " + element + " :", value);
          }

          // Filter betsItemBookMaker for the current event
          const betsItemBookMaker = betData.filter((bets) => {
            return bets.EventId == element && bets.Market == "BookMaker";
          });

          // Push the betsItem for the current event to MatchOddsBetsFilter
          BookMakerBetsFilter.push({
            [element]: betsItemBookMaker,
          });

          // Initialize data object for the current event
          let dataB = {};
          // Process each bet item ( Teams )
          betsItemBookMaker.forEach((bett) => {
            runnersData.forEach((runner) => {
              if (element == runner.EventId && runner.Market == "Bookmaker") {
                if (bett.Type == "back") {
                  if (runner.SelectionId == bett.SelectionId) {
                    // Calculate profit for matching SelectionId
                    dataB[runner.SelectionId] =
                      dataB[runner.SelectionId] == undefined
                        ? bett.Profit
                        : dataB[runner.SelectionId] + bett.Profit;
                  } else {
                    // Calculate liability for non-matching SelectionId
                    dataB[runner.SelectionId] =
                      dataB[runner.SelectionId] == undefined
                        ? bett.Liability
                        : dataB[runner.SelectionId] + bett.Liability;
                  }
                } else {
                  {
                    if (runner.SelectionId == bett.SelectionId) {
                      // Calculate profit for matching SelectionId
                      dataB[runner.SelectionId] =
                        dataB[runner.SelectionId] == undefined
                          ? bett.Liability
                          : dataB[runner.SelectionId] + bett.Liability;
                    } else {
                      // Calculate liability for non-matching SelectionId
                      dataB[runner.SelectionId] =
                        dataB[runner.SelectionId] == undefined
                          ? bett.Profit
                          : dataB[runner.SelectionId] + bett.Profit;
                    }
                  }
                }
              }
            });
          });

          // Push the data object for the current event to MatchOddsFilter if it's not empty
          if (Object.keys(dataB).length > 0) {
            BookMakerFilter.push({
              [element]: dataB,
            });
            const value = Math.max(Object.values(dataB).filter((v) => v < 0));
            MaxNegValBookMaker.push({
              [element]: value,
              exposure: value,
              Market: "Bookmaker",
              Match,
            });
          }
        });

        setMatchOddsNegativeValue(MaxNegValMatchOdds);
        setBookMakerNegativeValue(MaxNegValBookMaker);
        setFancyNegativeValue(allSumByEv);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getLoginInfo = () => {
    const loginData = decryptData(Cookies.get("LoginData"));
    if (loginData.length != undefined) {
      return JSON.parse(loginData);
    } else {
      return undefined;
    }
  };

  const getBetSlips = async (MatchId, issettlement) => {
    const id = getLoginInfo().userId;
    try {
      const response = await fetch(
        `${apiBaseUrl + apiSportsUrl}/matchinfo-by-eventid/${id}/${MatchId}`
      );
      let data = await response.json();
      console.log("Data in Main: ", data);
      if (issettlement != undefined) {
        let result = data.result.filter((item) => {
          return item.IsSettlement == issettlement;
        });
        data = { ...data, result };
        console.log("Data in Main with settlement 2: ", data);
        return data;
      } else {
        let result = data.result.filter((item) => {
          return item.IsSettlement == 1;
        });
        data = { ...data, result };
        console.log("Data in Main with settlement 1: ", data);
        return data;
      }
    } catch (error) {
      //console.log(error)
      //console.log("unable to fetch data");
      notify("Unable to fetch Data", "error");
    }
  };

  const getAppHeading = async () => {
    const api = `${apiBaseUrl + apiAdminsUrl}/get-message`;
    const response = await fetch(api);
    const data = await response.json();
    if (data.status) {
      setHeading(data.message);
      return data.message;
    } else {
      return "";
    }
  };

  const getAppHeading1 = async () => {
    const api = `${apiBaseUrl + apiAdminsUrl}/get-message-ss`;
    const response = await fetch(api);
    const data = await response.json();
    if (data.status) {
      setHeading1(data.message);
      return data.message;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (isLogin) {
      getAppHeading()
        .then((success) => {
          console.log("Success: ", success);
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
      getAppHeading1()
        .then((success) => {
          console.log("Success: ", success);
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    }
  }, [isLogin]);

  // get All Active Match--
  const getAllActiveMatch = async (page, search = "") => {
    try {
      let api = `${apiBaseUrl + apiSportsUrl}/getActiveMatch/${page}`;
      if (search != "" && search && search != "undefined") {
        api += `/${search}`;
      }
      const response = await fetch(api);
      const data = await response.json();
      return data;
    } catch (error) {
      notify("Client Side Error", "error");
      if (error) {
        console.log("Active Match Fetching Error: ", error);
      }
    }
  };

  // get All Inactive Match--
  const getAllInActiveMatch = async (page, search = "") => {
    try {
      const api = `${apiBaseUrl + apiSportsUrl}/get-inactive-matches/${page}/${
        search != "" ? search : ""
      }`;
      const response = await fetch(api);
      const data = await response.json();
      return data;
    } catch (error) {
      console.log("error in inactive matches: ", error);
    }
  };

  // get All Betfair--
  const getAllBetFair = async () => {
    return new Promise(async (resolve, reject) => {
      const api = `${apiBaseUrl + apiSportsUrl}/getbetfair/all`;
      const response = await fetch(api);
      console.log("response: ", response);
      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        reject();
      }
    });
  };

  // getImpMsgUser
  const getImpMsgUser = async () => {
    try {
      const api = `${apiBaseUrl + apiSportsUrl}/imp-msg-user`;
      const response = await fetch(api);
      if (response.ok) {
        const data = await response.json();
        setImpMsgUser(data.message);
      } else {
        console.log("error in response: ", response);
      }
    } catch (error) {
      console.log("getImp msg in Main Context: ", error);
    }
  };

  // UpdateImpMessageUser
  const ChangeImpMsgUser = async (newMessage) => {
    console.log("newMessage: ", newMessage);
    try {
      const response = await fetch(
        apiBaseUrl + apiSportsUrl + "/update-msg/imp-user",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message: newMessage }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.status) {
          getImpMsgUser();
          notify(data.message, "success");
          // Change the URL without reloading the page
          window.history.pushState(null, "", "/Settings");

          // Optionally, you can dispatch an event to handle the route change
          window.dispatchEvent(new PopStateEvent("popstate"));
          console.log("data update: ", data);
        }
      } else {
        console.log("response is not ok");
      }
    } catch (error) {
      console.log("during change message for imp user message: ", error);
    }
  };

  return (
    <MainContext.Provider
      value={{
        aside,
        getAllBetFair,
        betFairInfo,
        setBetFairInfo,
        ChangeImpMsgUser,
        heading,
        setHeading,
        getAppHeading1,
        getAllActiveMatch,
        getAllInActiveMatch,
        findMatchAllData,
        setAside,
        loginNotify,
        encryptData,
        setLoginNotify,
        toggleHide,
        setToggleHide,
        apiSportsUrl,
        apiBaseUrl,
        apiAdminsUrl,
        notify,
        isLogin,
        setIsLogin,
        geoLoc,
        ip,
        encryptData,
        logOutHandler,
        admin_stages,
        findAdminStage,
        dataByParent,
        apiUserUrl,
        setRefetchData,
        refetch,
        fetchDatabyParendId,
        edit,
        setEdit,
        findSeriesAllData,
        spiner,
        setSpiner,
        getInOutPlay,
        getCompletedMatch,
        agents,
        setAgents,
        unBlockUser,
        setUnBloackUser,
        fetchExposure,
        fancyNegativeValue,
        bookMakerNegativeValue,
        matchOddsNegativeValue,
        prevPath,
        setPrevPath,
        search,
        setSearch,
        getBetSlips,
        getLoginInfo,
        getAppHeading,
        heading1,
        setHeading1,
        impMsgUser,
        getImpMsgUser,
        impMsgMgmt,
      }}
    >
      <ToastContainer />
      {props.children}
      <div
        className={`fixed flex justify-center items-center font-2xl top-0 left-0 bg-[rgba(0,0,0,0.6)] w-full min-h-[100vh] z-[99999999] duration-50 ${
          spiner ? "visible opacity-100" : "invisible opacity-0"
        }`}
      >
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-14 h-14 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </MainContext.Provider>
  );
}
export default Main;
export { MainContext };
