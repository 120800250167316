import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { MdManageAccounts } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";
import { CgLivePhoto } from "react-icons/cg";
import { IoMdSearch } from "react-icons/io";
import { FaUsers } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { HiOutlineLanguage } from "react-icons/hi2";
import { MainContext } from "../contexts/Main";
export default function Aside() {
  const [openList, setOpenList] = useState(false);
  const [toggleList, setToggleList] = useState("");
  const [hideAdmin, setHideAdmin] = useState(false);
  const {
    aside,
    logOutHandler,
    isLogin,
    findAdminStage,
    admin_stages,
    dataByParent,
  } = useContext(MainContext);

  const adminListing = [
    {
      name: "Profile",
      path: "/dashboard",
    },
    {
      name: "Change Password",
      path: "/managePassword",
    },
    {
      name: "Chips",
      path: "/dashboard",
    },
    {
      name: "Logout",
      click: logOutHandler,
    },
  ];
  const navListing = [
    {
      path: "/dashboard",
      icon: <RxDashboard />,
      name: "Dashboard",
    },
    {
      name: "Manage",
      icon: <MdManageAccounts />,
      children: admin_stages.map((page) => {
        if (page.roleId > isLogin.roleId && page.roleId != 8) {
          return {
            path: page.path,
            name: page.roleName,
          };
        }
      })
    },
    {
      path: "/liveGames/1",
      icon: <CgLivePhoto />,
      name: "Live Matches",
    },
    {
      path: "/completedMatchesList/admin",
      icon: <CgLivePhoto />,
      name: "Completed Matches",
    },
    {
      path: "/AllList",
      icon: <CgLivePhoto />,
      name: "Live Casino",
    },
    {
      path: "/AroyalCasino",
      icon: <CgLivePhoto />,
      name: "Royal Casino",
    },
    {
      path: "/checkCasinoResult",
      icon: <IoMdSearch />,
      name: "Check Casino Result",
    },
    {
      path: "/blockSports",
      icon: <CgLivePhoto />,
      name: "Block Market",
    },
    {
      name: "Manage Clients",
      icon: <FaUsers />,
      children: [
        {
          name: "My Clients",
          path: "/listUsers/1",
        },
        {
          name: "Bloked Clients",
          path: "/blockedUser/1",
        },
        {
          name: "Commission & Limits",
          path: "/commissionandLimit",
        },
      ],
    },
    {
      path: "/managePassword",
      icon: <RiLockPasswordFill />,
      name: "Manage Password",
    },
    {
      path: "/SearchUser",
      icon: <IoMdSearch />,
      name: "Search User",
    },
    {
      path: "NullUsers",
      icon: <FaUsers />,
      name: "Null Value Users",
    },
    {
      path: "language",
      icon: <HiOutlineLanguage />,
      name: "Language",
    },
    {
      name: "Manage Ledgers",
      icon: <FaUsers />,
      children: [
        {
          name: "Collection Report",
          path: "/collectionReport",
        },
        {
          name: "My Stmt.",
          path: "/statementByUser/admin/0",
        },
        {
          name: "PDC",
          path: "/pdcSum",
        },
        {
          name: "Profit & loss",
          path: "/profitandloss/admin",
        },
      ],
    },
    {
      name: "NICE247.PRO Settings",
      icon: <FaUsers />,
      children: [
        {
          name: "Sport Settings",
          path: "/sportsetting",
        },
        {
          name: "Sport",
          path: "/newsport",
        },
        {
          name: "Settings",
          path: "/Settings",
        },
        {
          name: "Results",
          path: "/setresult",
        },
        {
          name: "Suspended Results",
          path: "/suspendedResult",
        },
        {
          name: "Surveillance",
          path: "/surveillance",
        },
        {
          name: "UpComing Matches",
          path: "/upComingMatches",
        },
        {
          name: "Info",
          path: "/infoPage",
        },
        {
          name: "Match-Settings",
          path: "/match-settings",
        },
        {
          name: "series",
          path: "/series",
        },
      ],
    },
  ];

  return (
    <>
      {aside ? (
        <>
          <div
            className={`w-full font-sans select-none cursor-pointer relative h-[120px] flex flex-col pl-4 text-[13px] font-semibold justify-center gap-1`}
          >
            <div className="text-white font-bold">
              {isLogin == false ? "admin" : isLogin?.userName} (
              {findAdminStage?.roleName})
            </div>
            <div
              onClick={() => {
                setHideAdmin(!hideAdmin);
              }}
              className="flex gap-1 items-center"
            >
              <span>Admin</span>
              <FaCaretDown/>
            </div>
            <ul
              className={`bg-white rounded duration-700 text-gray-500 py-2 px-1 w-[70%] absolute top-[6.5rem]  ${
                hideAdmin
                  ? "visible z-10 opacity-100 left-[5%]"
                  : "left-[70%] invisible opacity-0"
              }`}
            >
              {adminListing.length != 0 &&
                adminListing.map((data, index) => {
                  return (
                    <>
                      {data.click == null ? (
                        <li
                          key={index}
                          className="p-2 hover:bg-slate-100 duration-150"
                        >
                          <Link to={data.path}>{data.name}</Link>
                        </li>
                      ) : (
                        <li
                          key={index}
                          onClick={data.click}
                          className="p-2 hover:bg-slate-100 duration-150"
                        >
                          {data.name}
                        </li>
                      )}
                    </>
                  );
                })}
            </ul>
          </div>
          <ul className="select-none">
            {navListing.map((data, index) => {
              return (
                <React.StrictMode key={index}>
                  {data.path ? (
                    <li
                      key={index}
                      className={`flex mb-[8px] hover:text-white hover:font-semibold items-center gap-3 px-5 py-1 ${
                        data.name == "Dashboard" && "text-white font-semibold"
                      }`}
                    >
                      <span>{data.icon}</span>
                      <Link to={data.path}>{data.name}</Link>
                    </li>
                  ) : (
                    <OpenListing
                      key={index}
                      openList={openList}
                      setToggleList={setToggleList}
                      toggleList={toggleList}
                      setOpenList={setOpenList}
                      data={data}
                    />
                  )}
                </React.StrictMode>
              );
            })}
          </ul>
        </>
      ) : (
        <div className="w-full min-h-[100vh]">
          <div className="w-full font-[sans-serif] font-bold text-white flex items-center bg-[#1ab394] h-[73px]">
            NEW274
          </div>
          <ul className={`select-none mb-[8px] bg-blue-950 mt-3`}>
            {navListing.map((data, index) => {
              return (
                <>
                  {data.path ? (
                    <li
                      key={index}
                      className={`flex mb-[8px] h-[40px] hover:text-white hover:font-semibold items-center gap-3 px-5 py-1 ${
                        data.name == "Dashboard" && "text-white font-semibold"
                      }`}
                    >
                      <span className="text-xl ">{data.icon}</span>
                    </li>
                  ) : (
                    <OpenListing
                      key={index}
                      openList={openList}
                      setToggleList={setToggleList}
                      toggleList={toggleList}
                      setOpenList={setOpenList}
                      data={data}
                      aside={aside}
                      index={index}
                    />
                  )}
                </>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

const OpenListing = ({
  setOpenList,
  openList,
  data,
  toggleList,
  setToggleList,
  aside,
  index,
}) => {
  return (
    <li
      onClick={() => {
        setOpenList(!openList);
        if (data.name == toggleList) {
          setToggleList("");
        } else {
          setToggleList(data.name);
        }
      }}
      className={`px-5 py-1 cursor-pointer`}
    >
      <div className="flex mb-[8px] hover:text-white hover:font-semibold items-center gap-3 relative">
        <span
          className={`${aside == false ? "text-xl my-4" : ""} cursor-pointer`}
        >
          {data.icon}
        </span>
        <span className={`${aside == false ? "hidden" : ""}`}>{data.name}</span>
        <MdArrowBackIosNew key={index}
          className={`absolute text-[13px] ${
            aside == false ? "hidden" : ""
          } top-[7px] right-2 ${
            toggleList == data.name ? "rotate-[-90deg]" : ""
          }`}
        />
      </div>
      <ul
        onClick={(e) => e.stopPropagation()}
        className={`duration-300 ${
          aside == false ? "hidden" : ""
        } opacity-0 origin-top scale-y-0 ${
          toggleList == data.name ? "scale-y-100 opacity-100 min-h-0" : "h-0"
        } `}  
      >
        {data.children.map((childData, childIndex) => {
          return (
            <>
              {childData?.name != undefined ? (
                <li
                  key={childIndex}
                  className="flex text-[14px] hover:text-white hover:font-semibold items-center  gap-3 px-5 py-1"
                >
                  <span>{data.icon}</span>
                  <Link className="w-full" to={childData.path}>
                    {childData.name}
                  </Link>
                </li>
              ) : (
                ""
              )}
            </>
          );
        })}
      </ul>
    </li>
  );
};
