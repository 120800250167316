import React, { useContext } from "react";
import BreadCrum from "../../components/BreadCrum";
import { MainContext } from "../../contexts/Main";
import { Link } from "react-router-dom";

export default function CoinHistory() {
  const { edit } = useContext(MainContext);
  const breadCrum = {
    topName: "Agent",
    lastName: edit?.UserName + " (" + edit?.FullName + ")",
    finalName: "Coin History",
  };
  return (
    <div>
      <div className="px-4 bg-white py-2 border-t-[1px] border-b-[1px] my-2">
        <h2 className="lg:text-2xl text-lg font-sans text-gray-500 uppercase">
          {breadCrum?.topName}
        </h2>
        <div className="">
          <Link to={"/dashboard"}>
            <span className="text-sm font-semibold text-gray-500">
              Dashboard
            </span>
          </Link>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            <Link to={"/user-dashboard/" + edit?.UserName}>
              {breadCrum?.lastName}
            </Link>
          </span>
          {breadCrum.finalName && (
            <>
              <span className="text-gray-400 px-2">/</span>
              <span className="text-sm font-semibold text-black uppercase">
                {breadCrum?.finalName}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="bg-white my-3">
        <div className="flex justify-between p-2 items-center">
          <h2 className="text-gray-600 font-semibold text-lg">
            {edit?.UserName + " (" + edit?.FullName + ")"} Current User
            Changelog Details
          </h2>
        </div>
        <div className="relative overflow-x-auto p-2 shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs border-b-2 border-t-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Description
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Coins
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Fix Limit
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Match Commission
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Session Commission
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Created At
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 text-nowrap">-</td>
                <td className="px-6 py-4 text-nowrap">-</td>
                <td className="px-6 py-4 text-nowrap">-</td>
                <td className="px-6 py-4 text-nowrap">-</td>
                <td className="px-6 py-4 text-nowrap">- </td>
                <td className="px-6 py-4 text-nowrap">- </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
