import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../contexts/Main";
import axios from "axios";
import { useParams } from "react-router-dom";

function SessionEarningReportComponent({ table, issettlement }) {
  const [tableData, setTableData] = useState([]);
  const { apiBaseUrl, apiAdminsUrl, getLoginInfo } = useContext(MainContext);
  const [sessionSum, setSessionSum] = useState(0);
  const { gameId } = useParams();
  //console.log("SearchParams in component: ", issettlement);
  useEffect(() => {
    if (table.roleId && gameId) {
      let Id = table.roleId - 1;
      const loginId = getLoginInfo().userId;
      //console.log("Fetching Data By parentId : ", Id);
      axios
        .get(
          `${
            apiBaseUrl + apiAdminsUrl
          }/getReport/session-earning/${loginId}/${gameId}/${issettlement?issettlement:""}`
        )
        .then((success) => {
          setTableData(
            success.data.result.filter((item) => {
              return item.RoleId == table.roleId;
            })
          );
        })
        .catch((err) => {
          ////console.log("error: ", err);
        });
    }
  }, [table, apiBaseUrl, apiAdminsUrl, gameId, issettlement]);

  useEffect(() => {
    if (tableData.length != 0) {
      let sessionSumCopy = 0;
      tableData.forEach((item) => {
        sessionSumCopy += item.TotalFancyResult;
      });
      setSessionSum(sessionSumCopy);
    }
  }, [tableData]);

  //console.log("data of table for each section: ", tableData);
  return (
    <>
      {tableData.length != 0
        ? tableData.map((item, index) => {
            return (
              <tr
                key={index}
                className="bg-white border-b align-top dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="p-2 border text-sm">{`${item?.UserName} ${
                  item.FullName ? "( " + item.FullName + " )" : ""
                }`}</td>
                <td className="p-2 border text-sm">{item?.TotalFancyResult}</td>
                <td className="p-2 border text-sm">0</td>
                <td className="p-2 border text-sm">0</td>
                <td className="p-2 border text-sm">0</td>
                <td className="p-2 border text-sm">0</td>
              </tr>
            );
          })
        : ""}
        <tr>
          <th scope="col" className="p-2 text-nowrap border">
            Total
          </th>
          <th scope="col" className="p-2 text-nowrap border">
            {sessionSum}
          </th>
          <th scope="col" className="p-2 text-nowrap border">
            0.00
          </th>
          <th scope="col" className="p-2 text-nowrap border">
            0.00
          </th>
          <th scope="col" className="p-2 text-nowrap border">
            0.00
          </th>
          <th scope="col" className="p-2 text-nowrap border">
            0.00
          </th>
        </tr>
    </>
  );
}

export default SessionEarningReportComponent;
