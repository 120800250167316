import React, { useContext, useEffect, useState } from 'react';
import { IoMdMenu } from "react-icons/io";
import { FaSignOutAlt } from "react-icons/fa";
import { MainContext } from '../contexts/Main';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function Header() {
  const {aside, setAside, setToggleHide, toggleHide, getAppHeading, logOutHandler, heading, setHeading} = useContext(MainContext);
  const {pathname} = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{
    const logininfo = Cookies.get("LoginData");
    //console.log("lll",logininfo)
    if(logininfo==undefined){
      navigate("/admin/login");
    }else {
    if(pathname=="/"){
      navigate("/dashboard")
    }
    }
    //console.log("logincookie",Cookies.get("LoginData"))
  },[pathname])
  useEffect(()=>{
    localStorage.setItem("currenturl", pathname);
    setToggleHide(false);
    window.scrollTo(0,0);
  },[pathname]);

  useEffect(()=>{
  },[pathname])

  return (
    <div className='mt-2'>
      <div className='flex p-3'>
        <IoMdMenu onClick={()=>{setAside(!aside); setToggleHide(!toggleHide)}} className='bg-[#1fabb5] cursor-pointer text-white w-[35px] h-[25px] rounded text-2xl'/>
        <div className='w-full text-gray-400  flex items-center gap-3 justify-end'>
            <div className='uppercase font-[sans-serif] text-[13px] pt-[3px] text-[#676a6c]'>nice247.pro</div>
            <div onClick={logOutHandler} className='flex text-[13px] font-[600] items-center cursor-pointer gap-2'>
                <FaSignOutAlt />
                <span onClick={()=>{
                  localStorage.removeItem("currenturl");
                  navigate("/admin/login")
                }} className=''>Sign Out</span>
            </div>
        </div>
      </div>
      {
        heading != '' && 
        <div className='mt-6 bg-[#204b99] py-[6px] font-bold text-white flex items-center'>
          <marquee className="w-full" behavior="" direction="left">{heading}</marquee>
        </div>
      }
    </div>
  )
}
