import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../../components/BreadCrum";
import { MainContext } from "../../contexts/Main";
import { FaEdit } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";

export default function DirectClients() {
  const { edit,setEdit, apiBaseUrl, apiAdminsUrl } = useContext(MainContext);
  const [agents,setAgents] = useState([]);
  const fetchDataByParentId = () => {
    if(edit!=null){
    axios
      .get(`${apiBaseUrl + apiAdminsUrl}/getUserByParentId/${edit.Id}`)
      .then((success) => {
        const filterAgent = (success.data.data).filter((d)=>{
            return d.RoleId == 8;
        })
        setAgents(filterAgent);
      })
      .catch((err) => {
        setAgents([]);
      });}
  };
  useEffect(fetchDataByParentId,[edit])
  const breadCrum = {
    topName: "Agent",
    lastName: edit?.UserName + " (" + edit?.FullName + ")",
    finalName: " Direct Clients",
  };
  return (
    <div className="w-full bg-slate-100 min-h-[100vh]">
    <div className="px-4 bg-white py-2 border-t-[1px] border-b-[1px] my-2">
      <h2 className="lg:text-2xl text-lg font-sans text-gray-500 uppercase">
        {breadCrum?.topName}
      </h2>
      <div className="">
        <Link to={"/dashboard"}>
          <span className="text-sm font-semibold text-gray-500">
            Dashboard
          </span>
        </Link>
        <span className="text-gray-400 px-2">/</span>
        <span className="text-sm font-semibold text-gray-500 uppercase">
          <Link to={'/user-dashboard/'+edit?.UserName}>{breadCrum?.lastName}</Link>
        </span>
        {breadCrum.finalName && (
          <>
            <span className="text-gray-400 px-2">/</span>
            <span className="text-sm font-semibold text-black uppercase">
              {breadCrum?.finalName}
            </span>
          </>
        )}
      </div>
    </div>
      <div className="bg-white my-3">
        <div className="flex justify-between p-2 items-center">
          <h2 className="text-gray-600 font-semibold text-lg">All Users</h2>
        </div>
        <div className="relative overflow-x-auto p-2 shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs border-b-2 border-t-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  ID
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  User Name
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Agent Under
                </th>
                <th scope="col" className="px-6 py-3 text-nowrap">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {
                agents.length!=0?agents.map((data,index)=>{
                  return (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="px-6 py-4 text-nowrap">{index+1}</td>
                      <td className="px-6 py-4 text-nowrap">{data?.UserName}</td>
                      <td className="px-6 py-4 text-nowrap">{data.FullName?data.FullName:"NULL"}</td>
                      <td className="px-6 py-4 text-nowrap">{edit?.UserName}</td>
                      <td className="px-6 py-4 text-nowrap">
                        <button className="px-3 py-1 flex items-center gap-2 border rounded">
                          <FaEdit /> Edit
                        </button>
                      </td>
                    </tr>
                  )
                }) : ""
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
