import React from 'react'
import BreadCrum from '../../components/BreadCrum'
import { MdOutlineWatchLater } from 'react-icons/md'

export default function SportSettings() {
  const breadCrum = {
    topName: "NEW247 Settings",
    lastName: "Sport"
  }
  return (
    <div className='w-full bg-slate-100 min-h-[100vh]'>
      <BreadCrum breadCrum={breadCrum}/>
      <div className='w-full bg-white'>
            <div className='flex justify-between items-center border-t-2 border-b-[1px] p-2'>
                <span className='font-semibold text-gray-700'>Sport List</span>
            </div>
            <div className="w-full table  px-2 pt-1">
              <button className='bg-[#23c6c8] py-1 mx-3 px-3 rounded text-white mt-3'>Deafault & Add Setting</button>
                  <div className='flex shrink-1 flex-wrap gap-2 justify-between items-center p-4'>
                    <div className=' flex gap-3 uppercase'>
                      <button className='px-2 select-none rounded-sm hover:bg-gray-200 uppercase text-sm bg-gray-100 text-black border border-black'>csf</button>
                      <button className='px-2 select-none rounded-sm hover:bg-gray-200 uppercase text-sm bg-gray-100 text-black border border-black'>pdf</button>
                    </div>
                    <div className='select-none'>
                      <input type="text" name="" className='outline-none px-2 font-semibold border-2' placeholder='Search..' id="" />
                    </div>
                 </div>
            </div>
           
            <div className="overflow-x-auto">
      <table className="select-none w-full text-sm text-left border-2 rtl:text-right text-gray-500 dark:text-gray-400">
    <thead className="text-xs border-b-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" className="px-6 py-3">
        Match Id	
        </th>
        <th scope="col" className="px-6 py-3">
        Match Name
        </th>
        <th scope="col" className="px-6 py-3 ">
        Market Id
        </th>
        <th scope="col" className="px-6 py-3">
        Open Date
        </th>
        <th scope="col" className="px-6 py-3">
        Market Type
        </th>
        <th scope="col" className="px-6 py-3">
        Score	        </th>
        <th scope="col" className="px-6 py-3">
        Fancy Type		
        </th>
        <th scope="col" className="px-6 py-3">
        Action
        </th>
      </tr>
    </thead>

    
    <tbody className='Deafalut_table'>
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <td className="px-6 border">10000164</td>
        <td className="px-6 border">10000554</td>
          <td className=" text-blue-600 text-center font-semibold cursor-pointer">Lok Sabha 2024</td>
        <td className="px-6 border">CRICKET</td>
        
        <td className=" px-2  w-[200px]">
          <div className=' flex flex-col justify-center gap-2'>
          <select className='border-2 rounded p-1' name="" id="">
            <option value="BookMarker">BookMarker</option>
            <option value="Auto">Auto</option>
            <option value="Menu">Menu</option>
           </select>
           <button className='py-2 px-1 bg-[#23c6c8] rounded text-white'>Update Bhav</button>
          </div>
           
        </td>
        <td className="border px-2 w-[200px]"> 
        <div className='flex flex-col justify-center gap-2'>
        <select className='border-2 rounded p-1' name="" id="">
            <option value="Auto">Auto</option>
           </select>
           <button className='py-2 px-1 bg-[#23c6c8] rounded text-white'>Update Score</button>
        </div>
        </td>
        <td className="border px-1 "> 
        <div className='flex flex-col justify-center gap-2'>
        <select className='border-2 rounded p-1' name="" id="">
            <option value="Auto">Auto</option>
           </select>
           <button className='py-2 px-2 bg-[#23c6c8] rounded text-white'>Update Score</button>
        </div>
        </td>
        <td className="p-2 border flex flex-col gap-1 justify-between items-stretch font-bold">
        <button className='py-2 px-2 bg-[#23c6c8] rounded text-white'>Famcy Lists</button>
        <button className='py-2 px-2 bg-[#23c6c8] rounded text-white'>SetLimits</button>
        <button className='py-2 px-2 bg-[#23c6c8] rounded text-white'>Manipulation</button>
        <button className='py-2 px-2 bg-[#23c6c8] rounded text-white'>Set BookMarker</button>
        <button className='py-2 px-2 bg-blue-600 rounded text-white'>S.C.W</button>
        </td>
      </tr>
    </tbody>
  </table>
      </div>
    </div>
    </div>
  )
}
