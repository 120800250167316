import React from 'react'
import BreadCrum from '../components/BreadCrum'

export default function Pdc() {
  const breadCrum = {
    topName: "PDC",
    lastName: "PDC SUM"
  }
  return (
    <div className='w-full bg-slate-100 min-h-[100vh]'>
      <BreadCrum breadCrum={breadCrum}/>
      <div className="grid grid-cols-2 p-2 lg:flex gap-2">
        <input className='px-2 lg:w-[200px] py-1 text-gray-500 text-[14px] border border-black' type="date" name="" id="" />
        <input className='px-2 lg:w-[200px] py-1 text-gray-500 text-[14px] border border-black' type="date" name="" id="" />
        <button className='bg-[#1fabb5] col-span-2 sm:col-span-1 text-white py-1 px-2 rounded text-[14px]'>Search</button>
      </div>
      <div className="border-t-2 my-5 bg-white pb-4">
        <div className='p-2 font-bold '>
           Summary
        </div><hr />
        <div className='px-4 py-2 my-4  font-semibold flex justify-between'>
          <div className='w-[70%] p-2 px-4 bg-slate-100'>All time total</div>
          <div className='w-[30%] p-2 px-4 bg-slate-100'>0.00</div>
        </div>
      </div>
      <div className="mytbl text-[14px]">
        <div className='font-bold bg-white flex justify-between p-4'>
          <div>PDC Earning Report</div>
          <button className='px-2 py-1  bg-[#fc7a14] text-white'>PDF</button>
        </div>
        <div className="relative overflow-x-auto">
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <thead>
              <tr>
                <th style={{background: "linear-gradient(-180deg, #315195 0%, #14213D 100%)"}} className='border p-2 text-white'>DATE/TIME</th>
                <th style={{background: "linear-gradient(-180deg, #315195 0%, #14213D 100%)"}} className='border p-2 text-white'>MATCH ID</th>
                <th style={{background: "linear-gradient(-180deg, #315195 0%, #14213D 100%)"}} className='border p-2 text-white'>MATCH TITLE</th>
                <th style={{background: "linear-gradient(-180deg, #315195 0%, #14213D 100%)"}} className='border p-2 text-white'>TOTAL EARNINGS</th>
              </tr>
            </thead>
            <tbody className='text-[14px] search_user font-normal'>
              <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                <th className='border-2 p-2 font-normal text-gray-600'>15 may 2024</th>
                <th className='border-2 p-2 font-normal text-gray-600'>1111111111</th>
                <th className='border-2 p-2 font-normal text-gray-600'>	Rajasthan Royals v Punjab Kings</th>
                <th className='border-2 p-2 font-normal text-gray-600'>0.00</th>
              </tr>
              <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                <th className='border-2 p-2 font-normal text-gray-600'>15 may 2024</th>
                <th className='border-2 p-2 font-normal text-gray-600'>1111111111</th>
                <th className='border-2 p-2 font-normal text-gray-600'>	Rajasthan Royals v Punjab Kings</th>
                <th className='border-2 p-2 font-normal text-gray-600'>0.00</th>
              </tr>
              <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                <th className='border-2 p-2 font-normal text-gray-600'>15 may 2024</th>
                <th className='border-2 p-2 font-normal text-gray-600'>1111111111</th>
                <th className='border-2 p-2 font-normal text-gray-600'>	Rajasthan Royals v Punjab Kings</th>
                <th className='border-2 p-2 font-normal text-gray-600'>0.00</th>
              </tr>
              <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                <th className='border-2 p-2 font-normal text-gray-600'>15 may 2024</th>
                <th className='border-2 p-2 font-normal text-gray-600'>1111111111</th>
                <th className='border-2 p-2 font-normal text-gray-600'>	Rajasthan Royals v Punjab Kings</th>
                <th className='border-2 p-2 font-normal text-gray-600'>0.00</th>
              </tr>

              {/* Last row  */}
              <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                <th colSpan={3} className='border-2 p-2 font-semibold text-center text-gray-600'>All Page Total</th>
                <th className='border-2 p-2 font-semibold text-gray-600'>0.00</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
