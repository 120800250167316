import React, { useContext, useEffect, useState } from 'react'
import BreadCrum from '../../components/BreadCrum'
import { MainContext } from '../../contexts/Main'
import axios from 'axios';

function ImpMsgUser() {
    const {impMsgUser, getImpMsgUser, ChangeImpMsgUser} = useContext(MainContext);
    const [randomMsg, setRandomMsg] = useState('');
    const breadCrum = {
        topName: "Set Message",
        lastName: "Setting Dashboard",
        prevPath: "/Settings",
        finalName: "Set User Important Message"
    }
    useEffect(()=>{getImpMsgUser()}, []);
    useEffect(()=>{
        setRandomMsg(impMsgUser);
    }, [impMsgUser]);
  return (
    <div className='w-full min-h-[100vh] bg-gray-100'>
        <BreadCrum breadCrum={breadCrum}/>
        <div className="createMsg w-full">
            <textarea value={randomMsg} onChange={(e)=>setRandomMsg(e.target.value)} className='w-full min-h-[20vh] outline-none p-3' placeholder='Write Message..' name="" id=""></textarea>
        </div>
        <div className='p-3 text-[14px]'>
            <span className='text-[22px] text-red-500 font-[500]'>Is Important: </span><input className='scale-150 mx-2' type="checkbox" name="" id="" />{impMsgUser}
        </div>
        <div className='px-3 pb-0 text-[14px]'>
            <button onClick={()=>{
                ChangeImpMsgUser(randomMsg);
            }} className='px-3 py-2 bg-[#23c6c8] text-white rounded-lg'>Set Message</button>
        </div>
        <div className="currentMsg p-3 text-[14px]">
            Current Message: {impMsgUser}
        </div>
    </div>
  )
}

export default ImpMsgUser
