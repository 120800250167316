import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { MainContext } from "../../contexts/Main";
import { RiLoopRightLine } from "react-icons/ri";
import { FaCaretDown } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import axios from "axios";
import Moment from "react-moment";

export default function LiveReport() {
  const { prevPath, apiSportsUrl, apiBaseUrl, getBetSlips, getInOutPlay } =
    useContext(MainContext);
  const { gameId, issettlement, eid } = useParams();
  const [market, setMarket] = useState(null);
  const [declaredSessions, setDeclaredSessions] = useState(false);
  const [showBets, setShowBets] = useState(null);
  const [runners, setRunners] = useState([]);
  const [matchOddsSum, setMatchOddsSum] = useState([]);
  const [sessionPendingDetail, setSessionPendingDetail] = useState([]);
  const [declaredSessionsDetails, setDeclaredSessionsDetails] = useState([]);
  const [matches, setMatches] = useState([]);
  console.log("useParams: ", gameId, " & eid: ", eid);

  useEffect(() => {
    if (gameId != undefined) {
      axios
        .get(`${apiBaseUrl + apiSportsUrl}/livematch/getanalysis/${gameId}`)
        .then((success) => {
          if (success.data.status) {
            setMarket(success.data.result[0]);
          } else {
            setMarket(null);
          }
          // console.log("Success: ",success);
        })
        .catch((err) => {
          console.log("Error: ", err);
          setMarket(null);
        });
    }
  }, [gameId]);

  const getLiveMatches = () => {
    getInOutPlay(1, 1, eid)
      .then((success) => {
        console.log("object", success);
        if (success.status) {
          if (success.result.length != 0) {
            setMatches(success.result.slice(0, 6));
          } else {
            setMatches([]);
          }
        } else {
          setMatches([]);
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  useEffect(() => {
    if (eid != undefined) {
      getLiveMatches();
    }
  }, [eid]);

  console.log("Matches in live:(Upcoming) ", matches);

  const fetchAllData = () => {
    if (gameId != undefined) {
      getBetSlips(gameId, issettlement)
        .then((success) => {
          console.log("Success in Live Matches: ", success);
          setRunners(success.runners);
          const filteredData = success.result.filter((item) => {
            return item.Market == "Matchodds";
          });
          console.log("Filtered Data in liveReport: ",filteredData)
          if (success.runners.length != 0 && success.result.length != 0) {
            let TotalSum = [];
            success.runners.forEach((d) => {
              let sum = 0;
              filteredData.forEach((item) => {
                sum +=
                  item.Type == "lay"
                    ? item.Selection == d
                      ? item.Liability
                      : item.Profit
                    : item.Selection == d
                    ? item.Profit
                    : item.Liability;
              });
              TotalSum.push(sum);
            });
            setMatchOddsSum([...TotalSum]);
          }

          let sessionDetails = success.result.filter((item) => {
            return item.Market == "Fancy";
          });
          let makeUniqueSum = [];
          sessionDetails.forEach((item) => {
            let isExist = makeUniqueSum.find(
              (u) => u.SelectionId == item.SelectionId
            );
            if (isExist) {
              isExist.ResultAmount += parseInt(item.ResultAmount);
            } else {
              makeUniqueSum.push(item);
            }
          });
          setDeclaredSessionsDetails(makeUniqueSum);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(fetchAllData, [gameId, issettlement]);

  console.log("The Value of Market: ", market);
  console.log("Runners of this Match: ", runners);
  console.log("Sum of MatchOdds: ", matchOddsSum);
  console.log("Completed Details Of Fancy Session: ", declaredSessionsDetails);

  // get Pending Details of Fancy Sessoins--
  useEffect(() => {
    if (gameId != undefined) {
      getBetSlips(gameId, 2)
        .then((success) => {
          if (success.status) {
            let pendingSessionDetails = success.result.filter((item) => {
              return item.Market == "Fancy";
            });
            let uniquePending = [];
            console.log("pendingessiondetails: ", pendingSessionDetails);
            pendingSessionDetails.forEach((ele) => {
              let isExist = false;
              if (uniquePending.length !== 0) {
                for (let i = 0; i < uniquePending.length; i++) {
                  if (uniquePending[i].SelectionId == ele.SelectionId) {
                    uniquePending[i].Profit += ele.Profit;
                    uniquePending[i].Liability += ele.Liability;
                    isExist = true;
                    break;
                  }
                }
              }

              if (isExist == false || uniquePending.length == 0) {
                uniquePending.push(ele);
              }
            });
            // pendingSessionDetails.forEach((element)=>{
            //   let found = uniquePending.find((item)=>item.SelectionId == element.SelectionId);
            //   if(found){
            //     found.Profit += element.Profit;
            //     found.Liability += element.Liability;
            //   } else {
            //     uniquePending.push(element);
            //   }
            // })
            console.log("Uniqueee pending:  ", uniquePending);
            setSessionPendingDetail(uniquePending);
          }
        })
        .catch((err) => {
          console.log("Error during Fancy Pending Sessions: ", err);
        });
    }
  }, [gameId]);

  // Make Unque Array (DeclaredSessionResult) based on SelectionId---
  useEffect(() => {
    if (declaredSessionsDetails.length != 0) {
      let uniqueDeclaredSessionsDetails = [];
    }
  }, [declaredSessionsDetails]);

  return (
    <div className="w-full min-h-[100vh] bg-gray-200">
      {/* breadCrum */}
      <div className="py-2 px-4 bg-white">
        <h2 className="text-gray-600 text-2xl mb-2 ">LIVE MATCH REPORT</h2>
        <div className="flex gap-2 flex-wrap text-sm">
          <span>
            <Link to={"/dashboard"}>Dashboard</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={"/liveGames/1"}>Matches</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={prevPath?.pathname}>{prevPath?.eventName}</Link>
          </span>
          <span className="font-semibold">/</span>
          <span className="font-semibold">{prevPath?.title}</span>
        </div>
      </div>

      <div className="w-full p-2">
        <div className="flex mb-4 md:flex-row flex-col w-full gap-1  box-border">
          <div className="w-full lg:w-[60%] p-2 h-[5rem] bg-[#3a61a2] text-white text-center font-bold">
            <div>-</div>
            <div>-</div>
          </div>
          <div className="w-full lg:w-[40%] flex h-[5rem] gap-1">
            <div className="w-[80%] p-2 h-full bg-[#3a61a2]"></div>
            <div className="w-[20%] p-2 h-full bg-[#3a61a2] text-white flex justify-center items-center font-[700] text-2xl ">
              <RiLoopRightLine className="cursor-pointer" />
            </div>
          </div>
        </div>

        <div className="flex gap-1">
          <div className="lg:w-[75%] md:w-[70%] w-[60%] text-center bg-[#6e8fc9] p-2 text-white">
            Block MatchOdds
          </div>
          <div className="lg:w-[25%] md:w-[30%] w-[40%] text-nowrap text-center bg-[#6e8fc9] p-2 text-white">
            Block Fancy
          </div>
        </div>

        <div className="flex justify-between p-2 items-center bg-[#6e8fc9] text-white mt-2">
          <h2 className="text-sm">
            <span>Market: </span>Min {market != null ? market.MinOddLimit : 0} |
            Max {market != null ? market.MaxOddLimit : 0}
          </h2>
          <img src="/images/tv_icon.png" alt="file not found" />
        </div>

        <div className="w-full mt-3 overflow-auto">
          {/* heading */}
          <div className="flex w-full  uppercase font-[600]">
            <div className="flex gap-2 w-full min-w-[500px]">
              <div className="p-1 bg-[#49494A] text-white text-center w-full">
                Runner
              </div>
              <div className="p-1 bg-white w-full  text-center">Lagai</div>
              <div className="p-1 bg-white w-full  text-center">Khai</div>
              <div className="p-1 bg-[#49494A] text-white text-center w-full">
                Position
              </div>
            </div>
          </div>

          {/* Data.. */}
          {runners.length != 0
            ? runners.map((item, index) => {
                return (
                  <div className="flex w-full mt-2 text-sm">
                    <div className="flex gap-2 w-full min-w-[500px]">
                      <div className="p-2 bg-white text-center text-black w-full">
                        {item}
                      </div>
                      <div className="p-2 bg-[#61acde] w-full  text-center text-white">
                        0
                      </div>
                      <div className="p-2 bg-[#f6b771] w-full  text-center text-white">
                        0
                      </div>
                      <div className="p-2 font-[500] bg-white text-black text-center w-full">
                        {matchOddsSum.length != 0 ? matchOddsSum[index] : 0}
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>

        <div className="overflow-auto mt-2">
          <div className="min-w-[600px]">
            <h2 className="p-2 w-full m-0 text-sm bg-[#6e8fc9] text-white">
              <span>Pending Sessions: </span>Min:{" "}
              {market != null ? market.MinFancyLimit : 0} | Max:{" "}
              {market != null ? market.MaxFancyLimit : 0}
            </h2>
            <div className="flex gap-1 w-full my-2">
              <div className="w-[200px] px-2 py-1 text-wrap text-center text-[15px] font-[600] bg-[#49494A] text-white">
                Session
              </div>
              <div className="w-[200px] px-2 py-1 text-wrap text-center text-[15px] font-[600] bg-white text-black">
                No
              </div>
              <div className="w-[200px] px-2 py-1 text-wrap text-center text-[15px] font-[600] bg-[#49494A] text-white">
                Rate
              </div>
              <div className="w-[200px] px-2 py-1 text-wrap text-center text-[15px] font-[600] bg-white text-black">
                Yes
              </div>
              <div className="w-[200px] px-2 py-1 text-wrap text-center text-[15px] font-[600] bg-[#49494A] text-white">
                Rate
              </div>
              <div className="w-[200px] px-2 py-1 text-wrap text-center text-[15px] font-[600] bg-white text-black">
                Position No
              </div>
              <div className="w-[200px] px-2 py-1 text-wrap text-center text-[15px] font-[600] bg-white">
                Position Yes
              </div>
              <div className="w-[200px] px-2 py-1 text-wrap text-center text-[15px] font-[600] bg-[#49494A] text-white">
                Action
              </div>
            </div>

            {sessionPendingDetail.length != 0
              ? sessionPendingDetail.map((item) => {
                  return (
                    <div className="flex gap-1 w-full my-2">
                      <div className="w-[200px] px-2 py-1 text-wrap text-[13px] text-center bg-[#49494A] text-white">
                        {item?.Selection}
                      </div>
                      <div className="w-[200px] px-2 py-1 text-wrap text-[13px] text-center bg-white text-black">
                        {item?.Type == "back"
                          ? item?.Liability
                          : item?.Type == "lay"
                          ? item?.Profit
                          : "-"}
                      </div>
                      <div className="w-[200px] px-2 py-1 text-wrap text-[13px] text-center bg-[#49494A] text-white">
                        {item?.OddsRequest}
                      </div>
                      <div className="w-[200px] px-2 py-1 text-wrap text-[13px] text-center bg-white text-black">
                        {item?.Type == "back"
                          ? item?.Profit
                          : item?.Type == "lay"
                          ? item?.Liability
                          : "-"}
                      </div>
                      <div className="w-[200px] px-2 py-1 text-wrap text-[13px] text-center bg-[#49494A] text-white">
                        0
                      </div>
                      <div className="w-[200px] px-2 py-1 text-wrap text-[13px] text-center bg-white text-black">
                        0
                      </div>
                      <div className="w-[200px] px-2 py-1 text-wrap text-[13px] text-center bg-white">
                        0
                      </div>
                      <div className="w-[200px] px-2 py-1 text-wrap text-[13px] text-center bg-[#49494A] text-white">
                        Settled
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>

        {/* Declared Sessions-- */}
        <div className="overflow-auto my-2">
          <div className="min-w-[400px]">
            <div className="flex w-full uppercase justify-between text-white items-center p-2 text-sm bg-[#6e8fc9]">
              <div>Declared Sessions</div>
              <FaCaretDown
                onClick={() => {
                  setDeclaredSessions(!declaredSessions);
                }}
                className=" text-xl cursor-pointer"
              />
            </div>
            <div
              className={`w-full origin-top duration-100 ${
                declaredSessions
                  ? "scale-y-1 opacity-100 min-h-0"
                  : "scale-y-0 opacity-0 h-0"
              }`}
            >
              {/* Heading */}
              <div className="flex gap-1 mt-2">
                <div className="w-[60%] px-2 py-1 text-center bg-[#49494A] text-white">
                  Session
                </div>
                <div className="w-[20%] px-2 py-1 text-center bg-white text-black">
                  Result
                </div>
                <div className="w-[20%] px-2 py-1 text-center bg-white text-black">
                  Status
                </div>
              </div>

              {/* data.. */}
              {declaredSessionsDetails.length != 0
                ? declaredSessionsDetails.map((item) => {
                    return (
                      <div className="flex gap-1 mt-2">
                        <div className="w-[60%] px-2 py-1 text-center bg-white text-black">
                          {item?.Selection}
                        </div>
                        <div className="w-[20%] font-[600] px-2 py-1 text-center bg-white text-black">
                          {item?.Result}
                        </div>
                        <div className="w-[20%] font-[600] px-2 py-1 text-center bg-white text-black">
                          {item?.ResultAmount}
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>

        {/* MatchBets + */}
        <div className="w-full my-3">
          <div className="flex justify-between items-center p-2 bg-[#49494A] text-white">
            <div className="font-[500]">Matched Bets</div>
            <FaPlus
              onClick={() => {
                if (showBets == 1) {
                  setShowBets(null);
                } else {
                  setShowBets(1);
                }
              }}
              className="cursor-pointer"
            />
          </div>
          <div className={`w-full ${showBets == 1 ? "" : "hidden"}`}>
            <input
              className="border outline-none px-2 py-1"
              type="search"
              name=""
              placeholder="Search..."
              id=""
            />
          </div>
        </div>

        {/* SessionBets + */}
        <div className="w-full my-3">
          <div className="flex justify-between items-center p-2 bg-[#6e8fc9] text-white">
            <div className="font-[500]">Matched Bets</div>
            <FaPlus
              onClick={() => {
                if (showBets == 2) {
                  setShowBets(null);
                } else {
                  setShowBets(2);
                }
              }}
              className="cursor-pointer"
            />
          </div>
          <div className={`w-full ${showBets == 2 ? "" : "hidden"}`}>
            <input
              className="border outline-none px-2 py-1"
              type="search"
              name=""
              placeholder="Search..."
              id=""
            />
          </div>
        </div>

        {matches.length != 0
          ? matches.map((item) => {
              return (
                <div className="flex my-3 md:flex-row flex-col-reverse w-full min-h-[70px] md:h-[70px]  overflow-hidden bg-white rounded-lg justify-between">
                  <div className="md:w-[85%] h-full text-sm text-blue-600 uppercase p-4">
                    <Link to={""}>{item?.eventName}</Link>
                  </div>
                  <div className="md:w-[15%] cursor-pointer md:h-full h-[20px] p-5 md:gap-0 gap-1 uppercase md:lowercase flex text-sm justify-center items-center bg-[#3a61a2] text-center text-white md:flex-col">
                    <span>
                      <Moment format="DD">{item?.eventDate}</Moment>
                    </span>
                    <span>
                      <Moment format="MMM">{item?.eventDate}</Moment>
                    </span>
                    <span>
                      <Moment format="hh:mm A">{item?.eventDate}</Moment>
                    </span>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
}
