import React, { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Aside from '../components/Aside';
import Header from '../components/Header';
import { MainContext } from '../contexts/Main';
import Footer from '../components/Footer';
import { IoIosClose } from "react-icons/io";;

export default function Layout() {
  const {aside, toggleHide, setToggleHide} = useContext(MainContext);
  return (
    <>
    {
      aside ?
      <div className={`grid min-h-[100vh] grid-cols-5 mx-auto relative`}>
        <div className={`bg-blue-950 text-[14px] font-[600] overflow-y-auto overflow-x-hidden h-full lg:static left-[-100%] fixed duration-200 z-[1000] ${toggleHide==true?"left-[0%] duration-1000" : ""} text-gray-400 `}>
              <Aside/>
              <IoIosClose onClick={()=>{setToggleHide(false)}} className='lg:hidden text-3xl text-white absolute top-2 right-2' />
        </div>
        <div className={`lg:col-span-4 col-span-5 relative`}>
          <Header/>
          <Outlet/>
          <Footer/>
        </div>
     </div>:
      <div className='w-full min-h-[100vh] mx-auto flex relative'>
          <div className={`bg-blue-950 text-[14px] font-[600] lg:block hidden text-gray-400 min-w-[70px]`}>
                <Aside />
          </div>
          <div className={`w-full`}>
            <Header/>
            <Outlet/>
            <Footer/>
          </div>
      </div>
    }
     
    </>
  )
}
