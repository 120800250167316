import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrum from "../../components/BreadCrum";
import axios from "axios";
import { MainContext } from "../../contexts/Main";

function SetMessage() {
  const { state } = useLocation();
  const { apiBaseUrl, apiAdminsUrl, notify, getAppHeading, heading, setHeading } =
    useContext(MainContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  console.log("State: ", state);
  const breadCrum = {
    topName: "Set Massage",
    lastName: "Settings Dashboard",
    prevPath: state,
    finalName: "Set Massage",
  };
  console.log("BreadCrum: ", breadCrum);
  console.log("Message: ", message);

  const msg = () => {
    getAppHeading()
      .then((success) => {
        setMessage(success);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }

  useEffect(msg, []);

  const changeMessage = () => {
    if (message != heading) {
      console.log("value: ",message);
      axios.patch(`${apiBaseUrl+apiAdminsUrl}/update/message`, {message}).then((success)=>{
        console.log("Success After Updated Message: ", success);
        if(success.data.status){
          msg();
          notify("Message Changed", "success");
          navigate(state);
        }
      }).catch((err)=>{
        console.log("Error: ", err);
      })
    } else {
      notify("message not updated!! create new message", "error");
    }
  };

  return (
    <div className="w-full min-h-[100vh] bg-slate-100">
      <BreadCrum breadCrum={breadCrum} />
      <textarea
        className="w-full border-[2px] outline-none min-h-[70px] p-2 border-[#1fabb5]"
        name=""
        id=""
        placeholder="Enter You message.."
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      ></textarea>
      <div className="p-2">
        <button
          onClick={changeMessage}
          className="bg-[#23c6c8] text-white px-2 py-1 rounded-md"
        >
          Set Message
        </button>
      </div>

      <p className="p-2 font-[400]">
        <span>Current Maessage :: </span> {heading}
      </p>
    </div>
  );
}

export default SetMessage;
