
import Layout from './pages/Layout';
import DashBoard from './pages/DashBoard';
import Stockist from './pages/Stockist';
import Agent from './pages/Agent';
import LiveMatches from './pages/LiveMatches';
import CompleteMatch from './pages/CompleteMatch';
import LiveCashino from './pages/LiveCashino';
import RoyalCashino from './pages/RoyalCashino';
import BlockMarket from './pages/BlockMarket';
import ComLimit from './pages/CommisionLimits';
import BlockClient from './pages/BlockedClients';
import MyClients from './pages/MyClients';
import CheckCasino from './pages/CheckCasino';
import SearchUser from './pages/SearchUser';
import NullUsers from './pages/NullUsers';
import PassManager from './pages/PasswordManager';
import Lang from './pages/Language';
import Stmt from './pages/Stmt';
import Pdc from './pages/Pdc';
import ProfitLoss from './pages/ProfitLoss';
import SportSettings from './pages/new247settings/SportSettings';
import Sport from './pages/new247settings/Sport';
import Settings from './pages/new247settings/Settings';
import Results from './pages/new247settings/Result';
import SusResults from './pages/new247settings/SusPResult';
import Surveillance from './pages/new247settings/Surveillance';
import UpComingM from './pages/new247settings/UpComingMatches';
import Info from './pages/new247settings/Info';
import Login from './pages/Login';
import TechAdmin from './pages/Techadmin';
import SuperAdmin from './pages/SuperAdmin';
import SubAdmin from './pages/SubAdmin';
import Super2 from './pages/Super_Super';
import Super from './pages/Super';
import Master from './pages/Master';
import User from './pages/User';
import Error404 from './pages/Error404';
import CreateNewClient from './components/CreateNewClient';
import EditUser from './pages/EditUser';
import UserPasswordChange from './pages/UserPasswordChange';
import MatchSettings from './pages/new247settings/MatchSettings';
import Series from './pages/new247settings/Series';
import UserDashBoard from './pages/UserDashBoard';
import RecieveCash from './pages/Payment-handler/RecieveCash';
import PayCash from './pages/Payment-handler/PayCash';
import ClienLedger from './pages/Payment-handler/ClienLedger';
import CashLedger from './pages/Payment-handler/CashLedger';
import MatchLedger from './pages/Payment-handler/MatchLedger';
import DirectClients from './pages/Payment-handler/DirectClients';
import CoinHistory from './pages/Payment-handler/CoinHistory';
import DirectAgents from './pages/Payment-handler/DirectAgents';
import CompletedMatchDetails from './pages/CompletedMatchDetails';
import BetSlips from './pages/Agent-Match-Dashboard/BetSlips';
import BetSlips2 from './pages/Agent-Match-Dashboard/Betslips2';
import ClientReport from './pages/Agent-Match-Dashboard/ClientReport';
import LiveReport from './pages/Agent-Match-Dashboard/LiveReport';
import SessionBet from './pages/Agent-Match-Dashboard/SessionBet';
import SessionEarningReport from './pages/Agent-Match-Dashboard/SessionEarningReport';
import Collection from './pages/Agent-Match-Dashboard/Collection';
import SetMessage from './pages/new247settings/SetMessage';
import SetMsgSS from './pages/new247settings/SetMsgSS';
import ActiveMatchLists from './pages/new247settings/ActiveMatchLists';
import InactiveMatch from './pages/new247settings/InactiveMatch';
import BetFair from './pages/new247settings/BetFair';
import BetFairMatches from './pages/new247settings/BetFairMatches';
import ImpMsgUser from './pages/new247settings/ImpMsgUser';
import ImpMsgMgmt from './pages/new247settings/ImpMsgMgmt';



const allRouts = [
    {
      path: "/",
      element: <Layout/>,
      children: [
        {
          path: "dashboard",
          element: <DashBoard/>
        },{
          path: "/user-dashboard/:username?",
          element: <UserDashBoard/>
        },
        {
          path: "techadmin",
          element: <TechAdmin/>
        },
        {
          path: "superadmin",
          element: <SuperAdmin/>
        },{
          path: "subadmin",
          element: <SubAdmin/>
        },{
          path: "super-super",
          element: <Super2/>
        },{
          path: "super",
          element: <Super/>
        },{
          path: "master",
          element: <Master/>
        },{
          path: "user",
          element: <User/>
        },
        {
          path: "myStockist/1",
          element: <Stockist/>
        },
        {
          path: "myAgt/1",
          element: <Agent/>
        },
        {
          path: "liveGames/1",
          element: <LiveMatches/>
        },
        {
          path: "completedMatchesList/admin",
          element: <CompleteMatch/>
        },
        {
          path: "AllList",
          element: <LiveCashino/>
        },
        {
          path: "/checkCasinoResult",
          element: <CheckCasino/>
        },
        {
          path: "AroyalCasino",
          element: <RoyalCashino/>
        },
        {
          path: "blockSports",
          element: <BlockMarket/>
        },
        {
          path: "listUsers/1",
          element: <MyClients/>
        },
        {
          path: "blockedUser/1",
          element: <BlockClient/>
        },
        {
          path: "commissionandLimit",
          element: <ComLimit/>
        },
        {
          path: "managePassword",
          element: <PassManager/>
        },
        {
          path: "SearchUser",
          element: <SearchUser/>
        },
        {
          path: "NullUsers",
          element: <NullUsers/>
        },
        {
          path: "language",
          element: <Lang/>
        },
        {
          path: "statementByUser/admin/:username/:userId/:role?",
          element: <Stmt/>
        },{
          path: "pdcSum",
          element: <Pdc/>
        },{
          path: "profitandloss/admin/:userId/:roleId?",
          element: <ProfitLoss/>
        },
        {
          path: "sportsetting",
          element: <SportSettings/>
        },
        {
          path: "newsport",
          element: <Sport/>
        },
        {
          path: "Settings",
          element: <Settings/>
        },
        {
          path: "setresult",
          element: <Results/>
        },
        {
          path: "suspendedResult",
          element: <SusResults/>
        },
        {
          path: "surveillance",
          element: <Surveillance/>
        },
        {
          path: "upComingMatches",
          element: <UpComingM/>
        },{
          path: "infoPage",
          element: <Info/>
        },{
          path: "create-client",
          element: <CreateNewClient/>
        },{
          path: "edit-user/:user?",
          element: <EditUser/>
        },{
          path: "change-password/:user?",
          element: <UserPasswordChange/>
        },{
          path: "match-settings",
          element: <MatchSettings/>
        },{
          path: "series",
          element: <Series/>
        },{
          path: "live-match-details/:eid/:gameId/:eventName/:issettlement?",
          element: <CompletedMatchDetails/>
        },
        {
          path: "/betslips/0/:gameId/match/all-user/:gameId/:issettlement?",
          element: <BetSlips/>
        },
        {
          path: "/betslips2/0/:gameId/match/all-user/:gameId",
          element: <BetSlips2/>
        },
        {
            element: <SessionBet/>,
            path: "/session-bet-slips/0/:gameId/fancy/all-user/fancy/:issettlement?"
        },
        {
            element: <LiveReport/>,
            path: "/livegame-analysis/:eid/:gameId/:issettlement?"
        },
        {
            element: <ClientReport/>,
            path: "/client-report/:gameId/:issettlement?"
        },
        {
            element: <Collection/>,
            path: "/collection-report/:gameId/a"
        },
        {
            element: <SessionEarningReport/>,
            path: "/session-earning-report/:gameId/:issettlement?"
        },{
          path: "/set-message",
          element: <SetMessage/>,
        },{
          path: "/set-message-ss",
          element: <SetMsgSS/>,
        },{
          path: "/active-match",
          element: <ActiveMatchLists/>,
        },{
          path: "/inactive-match",
          element: <InactiveMatch/>,
        },{
          path: "/betfair",
          element: <BetFair/>,
        },{
          path: "/betfairMatches/:seriesId",
          element: <BetFairMatches/>,
        },{
          path: "/ImpMsgUser",
          element: <ImpMsgUser/>,
        },{
          path: "/ImpMsgMgmt",
          element: <ImpMsgMgmt/>,
        },
        {
          path: "/agent",
          children: [{
            path: "recieve-case/:userName/:fullName?",
            element: <RecieveCash/>
          },{
            path: "pay-case/:userName/:fullName?",
            element: <PayCash/>
          },
          ,{
            path: "ledger-show-ledger/:userName/:fullName?",
            element: <ClienLedger/>
          },{
            path: "ledger-show-case/:userName/:fullName?",
            element: <CashLedger/>
          },{
            path: "ledger-show-match/:userName/:fullName?",
            element: <MatchLedger/>
          },{
            path: "direct-clients/:userName/:fullName?",
            element: <DirectClients/>
          },{
            path: "coin-history/:userName/:fullName?",
            element: <CoinHistory/>
          },{
            path: "direct-agents/:userName/:fullName?",
            element: <DirectAgents/>
          },]
        }
      ]
    },
    {
      path: '/admin/login',
      element: <Login/>
    },
    {
      path: "*",
      element: <Error404/>
    }
  ]

  export default allRouts;