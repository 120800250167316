import React, { useContext, useEffect, useState } from 'react'
import BreadCrum from '../components/BreadCrum'
import { MainContext } from '../contexts/Main';
import ManageTable from '../components/ManageTable';

export default function Super_Super() {
  
  
  const {fetchDatabyParendId, isLogin, admin_stages, search} = useContext(MainContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchDatabyParendId().then(({ data }) => {
      const newData = data.filter((data) => {
        if (data.RoleId == 5) {
          return true;
        }
      });
      setData(newData.reverse());
    });
  }, [search]);

   const finds = admin_stages.find(d=>d.roleId==isLogin.roleId+1);
  const breadCrum = {
    topName: "Super Super",
    lastName: "Super-Super",
  }
  return (
    <div className='w-full min-h-[100vh]'>
      <BreadCrum breadCrum={breadCrum}/>
      {
        // data.length!=0&&
      <ManageTable data={data} create={isLogin?.roleId+1==admin_stages[4]?.roleId&&"SS"}/>
      }
    </div>
  )
}
