import React from 'react'
import BreadCrum from '../../components/BreadCrum';
import { Link, useLocation } from 'react-router-dom';

export default function Settings() {
  const breadCrum ={
    topName: "Settings",
    lastName: "Settings"
  }

  const location = useLocation();
  console.log("Current location: ", location);

  const settingsOptions = [
    {
      title: "Set Message",
      path: "/set-message"
    },{
      title: "Set Message SS",
      path: "/set-message-ss"
    },{
      title: "Active Match Lists",
      path: "/active-match"
    },{
      title: "In-Active Match Lists",
      path: "/inactive-match"
    },{
      title: "Rejected Bets",
      path: "/deleted-bets"
    },{
      title: "BetFair Matches",
      path: "/betfair"
    },{
      title: "Imp Msg Punter",
      path: "/ImpMsgUser"
    },{
      title: "Imp Msg Mgmt.",
      path: "/ImpMsgMgmt"
    },{
      title: "Rollbacked Results",
      path: "/RollbackedResults/1"
    },{
      title: "Concurent Users",
      path: "/gameUsersList/4/A/1"
    },     
  ];

  return (
    <div className='w-full min-h-[100vh] bg-slate-100'>
      <BreadCrum breadCrum={breadCrum}/>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 bg-white p-4 my-4">
        {
          settingsOptions.length !== 0 && 
          settingsOptions.map((item)=>{
            return(
              <div className='bg-[#1fabb5] text-center rounded p-2 text-white'>
                <Link to={item?.path} state={location.pathname}>{item?.title}</Link>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
