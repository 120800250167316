import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadCrum from "../../components/BreadCrum";
import axios from "axios";
import { MainContext } from "../../contexts/Main";
import Swal from "sweetalert2";
import { IoIosClose } from "react-icons/io";
import Moment from 'react-moment';
import moment from "moment";

function ActiveMatchLists() {
  const { state } = useLocation();
  const { getAllActiveMatch, apiSportsUrl, apiBaseUrl, notify } =
    useContext(MainContext);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [lengthTotal, setLengthTotal] = useState(0);
  const [activeMatch, setActiveMatch] = useState([]);
  const [search, setSearch] = useState("");
  const [updateDate, setUpdateDate] = useState({
    id: undefined,
    date: undefined,
    gameId: undefined,
  });

  //   breadCrum
  const breadCrum = {
    topName: "Active Match List",
    lastName: "Settings Dashboard",
    prevPath: state,
    finalName: "Active Match List",
  };

  //   Table Heading
  const tableHeading = [
    "Sport",
    "Match",
    "MatchId",
    "MarketId",
    "Date",
    "Market",
    "Status",
    "Action",
    "Show Bets",
    "Update Date",
  ];

  //   getActiveMatch

  const fetchActiveMatches = () => {
    getAllActiveMatch(page, search)
      .then((success) => {
        if (success.status) {
          setActiveMatch(success.result);
          setLastPage(Math.ceil(success.length / 100));
          setLengthTotal(success.length);
        }
      })
      .catch((err) => {
        console.log("Active Match Error: ", err);
      });
  };

  useEffect(fetchActiveMatches, [search, page]);

  console.log("Active Match Data: ", activeMatch);
  console.log("Length of Total Matches: ", lengthTotal);
  console.log("Index of Last Page: ", lastPage);
  console.log("change event date: ", updateDate);

  const upDateStatus = (gameId, active, status) => {
    console.log("GameId, active, status ", gameId, " ", active, " ", status);
    Swal.fire({
      title: "Are you sure?",
      text:
        "You won't to " + (active == 1 ? "inactive" : "active") + " this bet!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    })
      .then((success) => {
        console.log("success of popup: ", success);
        if (success.isConfirmed) {
          axios
            .patch(
              `${
                apiBaseUrl + apiSportsUrl
              }/match/update-active-status/${gameId}/${active == 1 ? 0 : 1}/${
                status == 1 ? 0 : 1
              }`
            )
            .then((ok) => {
              console.log("ok: ", ok);
              if (ok.data.status) {
                fetchActiveMatches();
                notify(ok.data.message, ok.data.status ? "success" : "error");
              }
            })
            .catch((err) => {
              console.log("error: ", err);
            });
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  return (
    <div className="w-full min-h-[100vh] bg-slate-100">
      <BreadCrum breadCrum={breadCrum} />
      <div className="w-full max-w-[300px]">
        <input
          defaultValue={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          type="search"
          name=""
          id=""
          className="w-full p-2 border border-[#23c6c8] rounded-lg ms-2 mt-1 outline-none"
          placeholder="Search Match.."
        />
      </div>
      <div className="w-full overflow-auto bg-white my-4">
        {tableHeading.length != 0 && (
          <table>
            <thead>
              <tr>
                {tableHeading.map((item) => {
                  return (
                    <th className="border px-6 py-1 text-nowrap">{item}</th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {activeMatch.length != 0
                ? activeMatch.map((item, index) => {
                    return (
                      <tr key={index} className="align-top">
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {item.eid == 4
                            ? "Cricket"
                            : item.eid == 2
                            ? "Tennis"
                            : item.eid == 1
                            ? "Soccer"
                            : ""}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {item?.eventName}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {item?.gameId}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {item?.marketId}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          
                          <Moment format="ddd MMM DD HH:mm:ss [GMT]Z YYYY">{item?.eventDate}</Moment>
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {"MatchOdds"}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {item?.Status == 1 ? "True" : "False"}
                        </td>
                        <td className="border px-6 py-2 text-nowrap">
                          <div className="flex flex-col gap-2">
                          <button
                            onClick={() =>
                              upDateStatus(
                                item.gameId,
                                item.ActiveMatch,
                                item.Status
                              )
                            }
                            className="bg-[#23c6c8] outline-none text-white px-2 py-1 rounded-lg  "
                          >
                            Update Status
                          </button>

                          <button className="bg-[#23c6c8] outline-none text-white px-2 py-1 rounded-lg  ">
                            Rollback PDC
                          </button>
                          </div>
                        </td>
                        <td className="border px-6 py-2 text-nowrap">
                          <button className="bg-[#23c6c8] outline-none text-white px-2 py-1 rounded-lg  ">
                            Show Bets
                          </button>
                        </td>
                        <td className="border px-6 py-2 text-nowrap">
                          <button
                            onClick={() =>
                              setUpdateDate({
                                id: item.SrNo,
                                date: moment(item.eventDate).format('YYYY-MM-DD HH:mm:ss'),
                                gameId: item.gameId,
                              })
                            }
                            className="bg-[#23c6c8] outline-none text-white px-2 py-1 rounded-lg  "
                          >
                            Update Game Date
                          </button>
                        </td>
                        <div
                          className={`fixed bg-[rgba(0,0,0,0.6)] z-[99999] top-0 left-0 w-[100vw] min-h-[100vh] flex justify-center items-start scale-y-0 opacity-0 duration-100 origin-top ${updateDate.id == item.SrNo?"scale-y-100 opacity-100":""}`}
                        >
                          <div className="w-[60vw] overflow-hidden mt-4 bg-white rounded-lg h-auto border">
                            <div className="flex p-2 lg:p-3  justify-between items-center border- border-b-1">
                              <h2 className="text-[14px] lg:text-[16px] text-gray-700 font-[500]">{item?.eventName}</h2>
                              <IoIosClose onClick={() =>
                              setUpdateDate({
                                id: undefined,
                                date: undefined,
                                gameId: undefined,
                              })
                            } className="text-2xl cursor-pointer lg:text-3xl" />
                            </div>
                            <div className="flex justify-center items-center p-6 border border-b-2">
                              <input className="text-black w-[80%] px-2 py-1 outline-none border rounded" type="text" onChange={(e)=>setUpdateDate({...updateDate,date: e.target.value})} value={updateDate.date} name="" id="" />
                            </div>
                            <div className="border-t-1 p-3 flex justify-end">
                              <button onClick={()=>{
                                axios.patch(`${apiBaseUrl+apiSportsUrl}/match/updateDateTime/${updateDate.gameId}/${updateDate.date}`).then((success)=>{
                                  console.log("Success Date update: ", success);
                                  notify(success.data.message, success.data.status?"success":"error");
                                  if(success.data.status){
                                    fetchActiveMatches();
                                    setUpdateDate({
                                      id: undefined,
                                      date: undefined,
                                      gameId: undefined,
                                    })
                                  }
                                }).catch((err)=>{
                                  console.log("error date update: ",err);
                                })
                              }} className="bg-[#1c84c6] mx-1 text-white px-3 py-1 rounded">Update</button><button onClick={() =>
                              setUpdateDate({
                                id: undefined,
                                date: undefined,
                                gameId: undefined,
                              })
                            } className="mx-1 bg-[#c2c2c2] rounded px-3 py-1 text-white">Close</button>
                            </div>
                          </div>
                        </div>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        )}
      </div>

      <div className="flex text-sm font-[500] p-4 bg-slate-100 justify-between">
        <div>
          showing {(page - 1) * 100 + 1} to{" "}
          {(page - 1) * 100 + activeMatch.length} of {lengthTotal}
        </div>
        <div className="flex border border-white rounded-lg bg-white overflow-hidden">
          <button
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
            className="px-2 py-1 hover:bg-slate-100 border-e-2"
          >
            Previous
          </button>
          <button className="px-3 py-1">{page}</button>
          <button
            onClick={() => {
              if (page != lastPage) {
                setPage(page + 1);
              }
            }}
            className="px-2 py-1 hover:bg-slate-100 border-l-2"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default ActiveMatchLists;
