import React, { useContext, useEffect, useMemo, useState } from "react";
import BreadCrum from "../components/BreadCrum";
import { MdOutlineWatchLater } from "react-icons/md";
import { MainContext } from "../contexts/Main";
import Moment from "react-moment";
import { Link } from "react-router-dom";

export default function CompleteMatch() {
  const breadCrum = {
    topName: "Matches",
    lastName: "Matches",
  };

  const [completed, setCompleted] = useState(null);
  const [total_length, setTotal_length] = useState(0);
  const [page, setPage] = useState(1);
  const [msg, setmsg] = useState("");
  const [matchIndex, setMatchIndex] = useState(4);

  // const newDate = useMemo(()=>{
  //   let date = new Date();
  //   const month = (date.getMonth()+1)<10?"0"+(date.getMonth()+1):date.getMonth();
  //   const day = (date.getDate()<10?"0"+date.getDate():date.getDate());
  //   const year = (date.getFullYear());
  //   return ({
  //     month,
  //     day,
  //     year
  //   })
  // },[]);

  //---------Date Filter States--------//
  const [filterDate, setFilterDate] = useState({
    from: undefined,
    to: undefined,
  });

  const beforeDate = useMemo(() => {
    let date = new Date();
    let twentyDayPrevious = new Date();
    twentyDayPrevious.setDate(twentyDayPrevious.getDate() - 20);
    setFilterDate({
      from: twentyDayPrevious.toISOString().slice(0, 10),
      to: date.toISOString().slice(0, 10),
    });
    console.log("current Date = ", date.toISOString().slice(0, 10));
    console.log(
      "previous Date = ",
      twentyDayPrevious.toISOString().slice(0, 10)
    );
  }, []);

  const { getCompletedMatch, setSpiner, notify } = useContext(MainContext);

  const fetchAllData = () => {
    setSpiner(true);
    getCompletedMatch(matchIndex, page, filterDate)
      .then((success) => {
        console.log("data: ", success);
        if (success.status) {
          setCompleted(success.result);
          setTotal_length(success.total_length);
          setmsg(success.message);
          setTimeout(() => {
            setSpiner(false);
          }, 500);
        } else {
          setCompleted([]);
          setTotal_length(0);
          setmsg("error");
        }
        setTimeout(() => {
          setSpiner(false);
        }, 500);
      })
      .catch((err) => {
        console.log("complet data error -- ", err);
        setTimeout(() => {
          setSpiner(false);
        }, 500);
      });
  };

  useEffect(fetchAllData, [page, matchIndex]);

  return (
    <div className="w-full min-h-[100vh] p-4">
      <BreadCrum breadCrum={breadCrum} />
      <div className="flex justify-between my-4 items-end">
        <div className="flex gap-2 flex-wrap">
          <span className="flex gap-2 ">
            <span className="font-bold">From:</span>
            <input
              max={filterDate.to}
              value={filterDate.from}
              onChange={(e) => {
                console.log(e);
                filterDate.to != "" && undefined
                  ? setFilterDate({
                      from: e.target.value,
                      to: "",
                    })
                  : setFilterDate({
                      ...filterDate,
                      from: e.target.value,
                    });
              }}
              className="border border-black px-1 rounded self-end"
              type="date"
              name=""
              id=""
            />
          </span>
          <span className="flex gap-2">
            <span className="font-bold">To:</span>
            <input
              value={filterDate.to}
              disabled={
                filterDate.from != undefined && filterDate.from != ""
                  ? false
                  : true
              }
              onChange={(e) => {
                setFilterDate({
                  ...filterDate,
                  to: e.target.value,
                });
              }}
              className="border border-black px-1 rounded self-end"
              type="date"
              name=""
              id=""
              min={filterDate.from}
            />
          </span>
        </div>
        <button
          onClick={() => {
            if (
              filterDate.from != undefined &&
              filterDate.from != "" &&
              filterDate.to != undefined &&
              filterDate != ""
            ) {
              fetchAllData();
            } else {
              notify("check both dates", "error");
            }
          }}
          className="border rounded bg-[#1fabb5] mt-2 text-white px-2 py-1"
        >
          Search
        </button>
      </div>
      <div className="max-w-full flex gap-3 justify-center py-2 my-2">
        <div
          onClick={() => {
            setPage(1);
            setMatchIndex(4);
          }}
          className={`cursor-pointer select-none ${
            matchIndex == 4 ? "border-2 border-black" : ""
          } w-[250px] text-center bg-[#1fabb5] text-white rounded p-2`}
        >
          Cricket
        </div>
        <div
          onClick={() => {
            setPage(1);
            setMatchIndex(2);
          }}
          className={`cursor-pointer select-none ${
            matchIndex == 2 ? "border-2 border-black" : ""
          } w-[250px] text-center bg-[#1fabb5] text-white rounded p-2`}
        >
          Tennis
        </div>
        <div
          onClick={() => {
            setPage(1);
            setMatchIndex(1);
          }}
          className={`cursor-pointer select-none ${
            matchIndex == 1 ? "border-2 border-black" : ""
          } w-[250px] text-center bg-[#1fabb5] text-white rounded p-2`}
        >
          Soccer
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className=" w-full text-sm text-left border-2 rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs border-b-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.NO
              </th>
              <th scope="col" className="px-6 py-3">
                PID
              </th>
              <th scope="col" className="px-6 py-3 ">
                Title
              </th>
              <th scope="col" className="px-6 py-3">
                Sport
              </th>
              <th scope="col" className="px-6 py-3">
                DATE
              </th>
              <th scope="col" className="px-6 py-3">
                Declared
              </th>
              <th scope="col" className="px-6 py-3">
                Won By
              </th>
              <th scope="col" className="px-6 py-3">
                Profit / Loss
              </th>
            </tr>
          </thead>

          <tbody className="">
            {completed != null ? (
              completed.length != 0 ? (
                completed.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 text-nowrap">
                        {page * 50 - 49 + index}
                      </td>
                      <td className="px-6 py-4 text-nowrap">{data.gameId}</td>
                      <td className=" py-4 text-nowrap text-[#1fabb5] font-semibold cursor-pointer">
                        <Link
                          to={`/live-match-details/${data.eid}/${data.gameId}/${data.eventName}`}
                          state={data}
                        >
                          {data?.eventName}
                        </Link>
                      </td>
                      <td className="px-6 py-4 text-nowrap">
                        {data?.eid == 1
                          ? "Soccer"
                          : data.eid == 2
                          ? "Tennis"
                          : "Cricket"}
                      </td>
                      <td className="px-6 py-4 text-nowrap flex items-center gap-2">
                        <MdOutlineWatchLater className="text-lg" />
                        <Moment format="DD MMM hh:mm A">
                          {data?.eventDate}
                        </Moment>
                      </td>
                      <td className="px-6 py-4 text-nowrap ">YES</td>
                      <td className="px-6 py-4 text-nowrap ">
                        {data?.Result != null ? data.Result : "null"}
                      </td>
                      <td
                        className={`px-6 py-4 text-nowrap font-bold ${
                          data.TotalResultAmount * -1 < 0
                            ? "text-[#dd5555]"
                            : "text-[#1fabb5]"
                        }`}
                      >
                        {data.TotalResultAmount * -1}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className="text-xl font-semibold text-black text-center p-3"
                    colSpan={8}
                  >
                    {msg}
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td
                  className="text-xl font-semibold text-gray-500 text-center p-3"
                  colSpan={8}
                >
                  Loading Data...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination flex sm:flex-row flex-col gap-3 sm:justify-between items-center mt-3">
        <div className="">
          {page * 50 - 49} to{" "}
          {completed?.length < 50 ? total_length : page * 50} of entries{" "}
          {total_length}
        </div>
        <div className="shadow-lg border-2 rounded-sm">
          <button
            onClick={() => {
              if (page == 1) return;
              setPage(page - 1);
            }}
            className="px-2 py-1"
          >
            Previous
          </button>
          <button className="px-2 border-x-2 py-1">{page}</button>
          <button
            onClick={() => {
              if (page == Math.ceil(total_length / 50)) return;
              setPage(page + 1);
            }}
            className="px-2 py-1"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
