import React from "react";
import { Link } from "react-router-dom";
import { AiTwotoneTool } from "react-icons/ai";

export default function CollectionReport({ data }) {
  return (
    <>
      {data.length != 0 &&
        data.map((values, index) => {
          return (
            <div className="w-full mb-5 shadow-lg bg-white overflow-x-auto border-t-2 border-gray-300">
              <div className="p-3 border-b-2 border-gray-100 items-center flex justify-between">
                <div className="font-bold text-[14px] select-none">
                  {values.title}
                </div>
                <AiTwotoneTool className="text-gray-400 text-xl" />
              </div>
              <div className="p-3">
                {/* Heading ---> Client & Balance */}
                <div className="flex border-b border-gray-200 justify-between">
                  <div className="w-full p-2 font-semibold text-[14px]">
                    Client
                  </div>
                  <div className="w-full p-2 font-semibold text-[14px]">
                    Balance
                  </div>
                </div>
                {values.Data.length != 0 &&
                  values.Data.map((d, i) => {
                    return (
                      <div className="flex border-b select-none border-gray-200 justify-between">
                        <div className="w-full p-2  text-[15px] text-blue-500">
                          <Link>{d.Client}</Link>
                        </div>
                        <div className="w-full p-2 text-[15px]">
                          {d.Balance}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </>
  );
}
