import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../components/BreadCrum";
import Select from "react-select";
import axios from "axios";
import { MainContext } from "../contexts/Main";
import { useParams } from "react-router-dom";
import Moment from "react-moment";

export default function ProfitLoss() {
  const { apiBaseUrl, apiAdminsUrl, notify } = useContext(MainContext);
  const twoDaysBefore = () => {
    const today = new Date();
    today.setDate(today.getDate() - 2);
    return today.toISOString().split("T")[0];
  };
  const [date, setDate] = useState({
    from: twoDaysBefore(),
    to: new Date().toISOString().split("T")[0],
  });
  console.log("date: ", date);
  const [p_sData, setP_SData] = useState([]);
  const { userId, roleId } = useParams();
  const games = [
    { label: "Cricket", value: 4 },
    { label: "Tennis", value: 2 },
    { label: "Soccer", value: 1 },
  ];
  const [sport, setSport] = useState([]);
  const breadCrum = {
    topName: "Profit & loss",
    lastName: "profit & loss",
  };

  const fetchAllData = () => {
    if (userId != undefined) {
      if (date.from != "" && date.to != "") {
        const dateQuery = new URLSearchParams(date).toString();
        axios
          .post(`${apiBaseUrl + apiAdminsUrl}/profitloss/${userId}/${roleId}?${dateQuery}`,{sport})
          .then((success) => {
            if (success.data.status) {
              console.log("success proift and loss: ",success)
              setP_SData(success.data.result);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      } else {
        notify("Date MisMatch", "error");
      }
    }
  };
  useEffect(fetchAllData, [userId, sport]);
  console.log("object", sport);
  return (
    <div className="w-full bg-slate-100 min-h-[100vh]">
      <BreadCrum breadCrum={breadCrum} />
      <div className="flex flex-wrap justify-center sm:justify-start p-3 gap-3">
        <div className="sm:w-[210px] w-[375px]">
          <Select
            isMulti
            name="games"
            options={games}
            onChange={(data) => {
              setSport(data.map((d)=>{
                return d.value;
              }));
            }}
            className="basic-multi-select "
            classNamePrefix="select"
          />
        </div>
        <div className="flex gap-4 flex-wrap justify-center sm:justify-start content-center">
          <input
            className="px-2 py-1 rounded outline-blue-500"
            value={date.from}
            max={date.to}
            onChange={(e) => {
              setDate({
                ...date,
                from: e.target.value,
              });
            }}
            type="date"
            name=""
            id=""
          />
          <input
            className="px-2 py-1 rounded outline-blue-500"
            value={date.to}
            min={date.from}
            max={new Date().toISOString().slice(0, 10)}
            onChange={(e) => {
              setDate({
                ...date,
                to: e.target.value,
              });
            }}
            type="date"
            name=""
            id=""
          />
          <button
            onClick={fetchAllData}
            className="bg-[#1fabb5] py-1 px-3 rounded text-white"
          >
            Search
          </button>
        </div>
      </div>
      <div className="border-t-2 my-5 bg-white pb-4">
        <div className="p-2 font-bold ">Summary</div>
        <hr />
        <div className="px-4 py-2 my-4 bg-slate-100 font-semibold flex justify-between">
          <div className=" p-2 px-4">All time total</div>
          <div className=" p-2 px-4">7734884.44</div>
        </div>
      </div>
      <div className="mytbl text-[14px]">
        <div className="font-bold bg-white flex justify-between p-4">
          <div>PDC Earning Report</div>
          <button className="px-2 py-1  bg-[#fc7a14] text-white">PDF</button>
        </div>
      </div>
      <div className="relative overflow-x-auto p-4 bg-white select-none">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead>
            <tr>
              <th className="border bg-slate-100 p-2 font-semibold text-[14px] text-black">
                DATE/TIME
              </th>
              <th className="border bg-slate-100 p-2 font-semibold text-[14px] text-black">
                MATCH ID
              </th>
              <th className="border bg-slate-100 p-2 font-semibold text-[14px] text-black">
                MATCH TITLE
              </th>
              <th className="border bg-slate-100 p-2 font-semibold text-[14px] text-black">
                Match Earnings
              </th>
              <th className="border bg-slate-100 p-2 font-semibold text-[14px] text-black">
                Commision Earnings
              </th>
              <th className="border bg-slate-100 p-2 font-semibold text-[14px] text-black">
                TOTAL EARNINGS
              </th>
            </tr>
          </thead>
          <tbody className="text-[14px] search_user font-normal">
            {p_sData.length != 0
              ? p_sData.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th className="border-2 p-2 font-normal text-gray-600">
                        <Moment format="DD MMM YY">{data.SettleTime}</Moment>
                      </th>
                      <th className="border-2 p-2 font-normal text-gray-600">
                        {data.EventId}
                      </th>
                      <th className="border-2 p-2 font-normal text-[#337ab7]">
                        {data.EventName}
                      </th>
                      <th className="border-2 p-2 font-normal text-gray-600">
                        {data.ResultAmount}
                      </th>
                      <th className="border-2 p-2 font-normal text-gray-600">
                        0
                      </th>
                      <th className="border-2 p-2 font-normal text-gray-600">
                        {data.ResultAmount}
                      </th>
                    </tr>
                  );
                })
              : ""}

            {/* Last row  */}
            {/* <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                colSpan={3}
                className="border-2 p-2 font-semibold text-center text-gray-600"
              >
                All Page Total
              </th>
              <th className="border-2 p-2 font-semibold text-gray-600">
                9804814.90
              </th>
              <th className="border-2 p-2 font-semibold text-gray-600">
                -2069930.46
              </th>
              <th className="border-2 p-2 font-semibold text-gray-600">
                7734884.44
              </th>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
