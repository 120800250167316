import React from 'react'

export default function DashboardInfo({data}) {
  return (
    <div className='shadow-xl my-3 border p-2'>
      <h2 className='text-gray-500 p-2 font-bold'>{data?.title}</h2>
      <hr />
      <div>
        <h2 className='p-2 text-3xl text-gray-500 font-sans'>{data?.value}</h2>
      </div>
    </div>
  )
}
