import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../../contexts/Main';
import BreadCrum from '../../components/BreadCrum';

function ImpMsgMgmt() {
    const {impMsgMgmt} = useContext(MainContext);
    const breadCrum = {
        topName: "SET MESSAGE",
        lastName: "Setting Dashboard",
        prevPath: "/Settings",
        finalName: "SET USER IMPORTANT MESSAGE"
    }
    const [changeMgmt, setChangeMgmt] = useState('');
    useEffect(()=>{
        setChangeMgmt(impMsgMgmt);
    },[impMsgMgmt]);
  return (
    <div className='w-full min-h-[100vh] bg-gray-100'>
        <BreadCrum breadCrum={breadCrum}/>
        <div className="createMsg w-full">
            <textarea value={changeMgmt} onChange={(e)=>setChangeMgmt(e.target.value)} className='w-full min-h-[20vh] outline-none p-3' placeholder='Write Message..' name="" id=""></textarea>
        </div>
    </div>
  )
}

export default ImpMsgMgmt
