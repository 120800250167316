import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MainContext } from "../contexts/Main";
import axios from "axios";

export default function EditUser() {
  const [userData, setUserData] = useState(null);

  const { user } = useParams();
  //   User details
  const [userDetails, setUserDetails] = useState({
    userName: userData?.UserName,
    fullName: userData?.FullName,
    CurrentLimit: "0",
    myshare: "0",
    share: userData?.Partnership,
    match_commission: userData?.MCommission,
    session_commission: userData?.SCommission,
    LockBetting: userData?.LockBetting,
    LockUser: userData?.LockUser,
  });
  const navigate = useNavigate();
  const {
    admin_stages,
    isLogin,
    apiBaseUrl,
    apiUserUrl,
    notify,
    edit,
    setEdit,unBlockUser, setUnBloackUser
  } = useContext(MainContext);
console.log("++++++++++++",unBlockUser)
  useEffect(() => {
    if (edit != null) {
      axios
        .get(`${apiBaseUrl + apiUserUrl}/find/${edit.Id}`)
        .then((success) => {
          setUserData(success.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [edit]);

  useEffect(() => {
    setUserDetails({
      userName: userData?.UserName,
      fullName: userData?.FullName,
      CurrentLimit: "0",
      myshare: "0",
      share: userData?.Partnership,
      match_commission: userData?.MCommission,
      session_commission: userData?.SCommission,
      LockBetting: userData?.LockBetting,
      LockUser: userData?.LockUser,
    });
  }, [userData]);


  //breadCrum details
  const breadCrum = admin_stages.find((d) => edit?.RoleId == d.roleId);
console.log(userData)
  const submitHandler = () => {
    let {
      userName,
      fullName,
      CurrentLimit,
      LockBetting,
      LockUser,
    } = userDetails;
    if (
      userName != "" &&
      fullName != "" &&
      CurrentLimit != "" &&
      LockBetting != null &&
      LockUser != null
    ) {
      axios.put(`${apiBaseUrl+apiUserUrl}/update/${userData?.Id}`,{FullName: fullName,CurrentLimit,LockBetting,LockUser}).then((ok)=>{
       console.log(ok);
       if(ok.data.status){
        notify("User Status Updated", "success");
        setUserDetails({
          userName:"",
          fullName:"",
          CurrentLimit: "",
          myshare: "",
          share:  "",
          match_commission:"",
          session_commission:"",
          LockBetting:"",
          LockUser:"",
        })
        setTimeout(() => {
          if(unBlockUser!=null){
            navigate(unBlockUser)
            setUnBloackUser(null)
          }else{
            navigate(breadCrum?.path);
          }
        }, 1000);
       }else{
        notify("something went wrong", "error");
       }
      }).catch((err)=>{
        notify("Client Side Error", "error");
        console.log(err)
      })
    } else {
      console.log(userDetails)
      notify("enter complete data", "error");
    }
  };

  return (
    <div className="min-h-[100vh] text-gray-500 bg-gray-100">
      <div className="px-4 select-none bg-white md:py-2 py-4 border-t-[1px] border-b-[1px] my-2">
        <h2 className="lg:text-2xl text-lg font-sans text-gray-500 uppercase">
          Edit {breadCrum?.roleName}
        </h2>
        <div className="">
          <Link to={"/dashboard"}>
            <span className="text-sm font-semibold text-gray-500">
              Dashboard
            </span>
          </Link>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            <Link to={breadCrum?.path}>agents</Link>
          </span>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            Edit
          </span>
        </div>
      </div>
      <div className="newSc p-2 border bg-white">
        <div className="p-3 font-bold border-b">Edit </div>
        <div className="pt-2">
          {/* user name */}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              User Name
            </div>
            <div className="  md:w-[80%] w-full border  rounded">
              <input
                className="px-4 py-1 w-full outline-green-500"
                type="text"
                value={userDetails.userName}
                readOnly
              />
            </div>
          </div>

          {/* full name */}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              Full Name
            </div>
            <div className="  md:w-[80%] w-full border  rounded">
              <input
                className="px-4 py-1 w-full outline-green-500"
                type="text"
                value={userDetails.fullName}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    fullName: event.target.value,
                  });
                }}
              />
            </div>
          </div>

          {/* Current Limit */}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              Current Limit
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 newClient block border py-1 w-full outline-green-500"
                type="number"
                value={userDetails.CurrentLimit}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    CurrentLimit: event.target.value,
                  });
                }}
              />
              <div>
                <b>N</b>ote : Fix Limit can be set from 0 to 6973604959.00
              </div>
            </div>
          </div>

          {/*My Match Share*/}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              My Match Share
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 newClient cursor-not-allowed block border py-1 w-full outline-green-500"
                type="number"
                disabled
                value={userDetails.share}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    myshare: event.target.value,
                  });
                }}
              />
              <div>
                <b>N</b>ote : My Match Share can be set from 0 to 100
              </div>
            </div>
          </div>

          {/*Match Share*/}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              Match Share
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 newClient cursor-not-allowed block border py-1 w-full outline-green-500"
                type="number"
                disabled
                value={userDetails.myshare}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    share: event.target.value,
                  });
                }}
              />
              <div>
                <b>N</b>ote : {breadCrum?.roleName} Share can be set from 0 to
                100
              </div>
            </div>
          </div>

          {/* Match Commission */}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              Match Commission
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 block cursor-not-allowed newClient border py-1 w-full outline-green-500"
                type="number"
                disabled
                value={userDetails.match_commission}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    match_commission: (event.target.value),
                  });
                }}
              />
            </div>
          </div>

          {/*Session Commission*/}
          <div className="gap-2 md:py-2 py-4 text-sm flex justify-center md:flex-row flex-col items-center">
            <div className="px-2 md:py-1 font-bold w-full md:w-[20%] text-left md:text-right">
              Session Commission
            </div>
            <div className=" md:w-[80%] w-full   rounded">
              <input
                className="px-4 cursor-not-allowed block newClient border py-1 w-full outline-green-500"
                type="number"
                disabled
                value={userDetails?.session_commission}
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    session_commission: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="px-10 font-bold text-gray-500">
            <div className="flex gap-2 my-5 items-center text-sm">
              <div>Agent Blocked</div>
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  onChange={(e) => {
                    console.log("object", e.target.checked);
                    setUserDetails({
                      ...userDetails,
                      LockUser: e.target.checked ? "1" : "0",
                    });
                  }}
                  checked={userDetails.LockUser == 1 ? true : false}
                  type="checkbox"
                  className={`peer sr-only`}
                />
                <label htmlFor="switch-2" className="hidden" />
                <div className="peer h-4 w-11 rounded-full border bg-slate-200 after:absolute after:-top-1 after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-300 peer-checked:after:translate-x-full peer-focus:ring-green-300" />
              </label>
            </div>
            <div className="flex gap-2 my-5 items-center text-sm">
              <div>Bets Blocked</div>
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  onChange={(e) => {
                    console.log("object", e.target.checked);
                    setUserDetails({
                      ...userDetails,
                      LockBetting: e.target.checked ? "1" : "0",
                    });
                  }}
                  checked={userDetails.LockBetting == 1 ? true : false}
                  type="checkbox"
                  className={`peer sr-only`}
                />
                <label htmlFor="switch-2" className="hidden" />
                <div className="peer h-4 w-11 rounded-full border bg-slate-200 after:absolute after:-top-1 after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-300 peer-checked:after:translate-x-full peer-focus:ring-green-300" />
              </label>
            </div>
          </div>
          <div className="md:grid grid-cols-5 bg-white lg:gap-4 text-black">
            <div></div>
            <div className="col-span-4">
              <button onClick={()=>{navigate(breadCrum?.path)}} className="px-4 mx-2 mt-2 py-1 border  rounded">
                Cancel
              </button>
              <button
                onClick={submitHandler}
                className="px-4 mx-2 mt-2 py-1 border bg-[#1fabb5] text-white rounded"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
