import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../components/BreadCrum";
import ManageTable from "../components/ManageTable";
import { MainContext } from "../contexts/Main";

export default function Techadmin() {
  const { dataByParent, isLogin, admin_stages, fetchDatabyParendId, search } =
    useContext(MainContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchDatabyParendId().then(({ data }) => {
      const newData = data.filter((data) => {
        if (data.RoleId == 2) {
          return true;
        }
      });
      setData(newData.reverse());
    });
  }, [search]);

  const breadCrum = {
    topName: "Tech-Admin",
    lastName: "tech-admin",
  };

  return (
    <div className="w-full min-h-[100vh]">
      <BreadCrum breadCrum={breadCrum} />
      {(
        <ManageTable
          data={data}
          breadCrum={breadCrum}
          create={isLogin?.roleId + 1 == admin_stages[1]?.roleId && "TA"}
        />
      )}
    </div>
  );
}
