import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { MainContext } from "../contexts/Main";

export default function CompletedMatchDetails() {
  const {setPrevPath} = useContext(MainContext);
  const {pathname} = useLocation();
  const location = useLocation();
  console.log("Location: ", location);

    const { eventName, gameId, issettlement, eid } = useParams();

    const btns = [
        {
            title: "Bet Slips",
            path: `/betslips/0/${gameId}/match/all-user/${gameId}/${issettlement ? issettlement : ""}`
        }, 
        {
            title: "Bet Slips2",
            path: `/betslips2/0/${gameId}/match/all-user/${gameId}`
        },
        {
            title: "Session Bet Slip",
            path: `/session-bet-slips/0/${gameId}/fancy/all-user/fancy/${issettlement ? issettlement : ""}`
        },
        {
            title: "Live Report",
            path: `/livegame-analysis/${eid}/${gameId}/${issettlement ? issettlement : ""}`
        },
        {
            title: "Client Report",
            path: `/client-report/${gameId}/${issettlement ? issettlement : ""}`
        },
        {
            title: "Collection Report",
            path: `/collection-report/${gameId}/a`
        },
        {
            title: "Session Earning Report",
            path: `/session-earning-report/${gameId}/${issettlement ? issettlement : ""}`
        },
    ];
  return (
    <div className="w-full min-h-[100vh] bg-gray-100 py-2">
      <div className="py-2 px-4 bg-white">
        <h2 className="text-gray-600 text-2xl mb-2 ">Matches</h2>
        <div className="flex gap-2 text-sm">
          <span>
            <Link to={"/dashboard"}>Dashboard</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={"/liveGames/1"}>Matches</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>{eventName}</span>
        </div>
      </div>
      <div className="w-full bg-white mt-3">
        <h2 className="text-gray-500 text-2xl md:text-3xl py-3 px-4">Agent Match Dashboard</h2><hr />
        <div className="flex justify-center gap-3 flex-wrap items-center p-4">
            {
                btns.map((btn, index)=>{
                    return(
                        <div key={index  } onClick={()=>setPrevPath({pathname, eventName, title: btn.title})} className="sm:py-2 sm:px-4 py-1 px-2 text-sm sm:text-[16px] text-white bg-[#1fabb5] rounded-lg">
                            <Link to={btn.path}>{btn.title}</Link>
                        </div>
                    )
                })
            }
        </div>
      </div>
    </div>
  );
}
