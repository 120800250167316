import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../components/BreadCrum";
import { MdOutlineWatchLater } from "react-icons/md";
import { MainContext } from "../contexts/Main";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";

export default function LiveMatches() {
  const { getInOutPlay, setSpiner } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [inout, setInOut] = useState(1);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const {gameId, eventName} = useParams();

  const [maxPage, setMaxPage] = useState(1);

  const [selectedMatch,setSelectedMatch] = useState(4);

  const breadCrum = {
    topName: "Matches",
    lastName: "Matches",
  };
  const getLiveMatchData = () => {
    setSpiner(true);
    getInOutPlay(inout, page, selectedMatch)
      .then((success) => {
        console.log('object',success)
        if (success.status) {
          if (success.result.length != 0) {
            setData(success.result);
            setDataLength(success.length);
            setMaxPage(success.page);
            setTimeout(()=>{
              setSpiner(false)
            },1000)
          } else {
            setData(null);
            setTimeout(()=>{
            setSpiner(false);
            },2000)
          }
        } else {
          setData(null);
          setDataLength(success.length);
          setMaxPage(success.page);
          setTimeout(()=>{
            setSpiner(false)
          },1000)
        }
      })
      .catch((err) => {
        console.log(err);
        setTimeout(()=>{
          setSpiner(false);
        },2000)
      });
  };

  useEffect(getLiveMatchData, [page, inout, selectedMatch]);

  return (
    <div className="max-w-full min-h-[100vh]">
      <BreadCrum breadCrum={breadCrum} />
      <div className="max-w-full flex gap-3 px-2 py-2 my-2">
        <div
          onClick={() => {
            setInOut(1);
            setPage(1);
            setSelectedMatch(4);
          }}
          className={`cursor-pointer select-none w-[250px] text-center bg-[#1fabb5] text-white rounded p-2 ${inout==1?"border-2 border-black font-bold":""}`}
        >
          InPlay Matches
        </div>
        <div
          onClick={() => {
            setInOut(2);
            setPage(1);
            setSelectedMatch(4)
          }}
          className={`cursor-pointer select-none w-[250px] text-center bg-[#1fabb5] text-white rounded p-2 ${inout==2?"border-2 border-black font-bold":""}`}
        >
          Upcoming Matches
        </div>
      </div>
      <div className="max-w-full flex gap-3 px-10 py-2 my-2">
        <div onClick={()=>{setSelectedMatch(4); setPage(1)}} className={`cursor-pointer select-none w-[250px] text-center bg-[#1fabb5] text-white rounded p-2 ${selectedMatch==4?"border-black shadow-xl font-bold border-2":""}`}>
          Cricket
        </div>
        <div onClick={()=>{setSelectedMatch(2); setPage(1)}} className={`cursor-pointer select-none w-[250px] text-center bg-[#1fabb5] text-white rounded p-2 ${selectedMatch==2?"border-black shadow-xl font-bold border-2":""}`}>
          Tennis
        </div>
        <div onClick={()=>{setSelectedMatch(1); setPage(1)}} className={`cursor-pointer select-none w-[250px] text-center bg-[#1fabb5] text-white rounded p-2 ${selectedMatch==1?"border-black shadow-xl font-bold border-2":""}`}>
          Soccer
        </div>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full select-none text-sm text-left border-2 rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs border-b-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.NO
              </th>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                PID
              </th>
              <th scope="col" className="px-6 py-3">
                Title
              </th>
              <th scope="col" className="px-6 py-3">
                Sport
              </th>
              <th scope="col" className="px-6 py-3">
                DATE
              </th>
              <th scope="col" className="px-6 py-3">
                Profit / Loss
              </th>
            </tr>
          </thead>

          <tbody>
            {data != null ? (
              data.length != 0 ? (
                data.map((d, i) => {
                  return (
                    <tr
                      key={i}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 text-nowrap">
                        {50 * (page - 1) + (i + 1)}
                      </td>
                      <td className="px-6 py-4 text-nowrap">{d?.SrNo}</td>
                      <td className="px-6 py-4 text-nowrap">{d?.gameId}</td>
                      <td onClick={()=>{navigate(`/live-match-details/${d.eid}/${d.gameId}/${d.eventName}/2`)}} className="px-6 py-4 text-nowrap text-blue-600 font-semibold cursor-pointer">
                        {d.eventName}
                      </td>
                      <td className="px-6 py-4 text-nowrap">
                        {d?.eid == 1
                          ? "Soccer"
                          : d?.eid == 2
                          ? "Tennis"
                          : "Cricket"}
                      </td>
                      <td className="px-6 py-4 text-nowrap flex items-center gap-2">
                        <MdOutlineWatchLater className="text-lg" />{" "}
                        <Moment format="DD MMM hh:mm A">{d?.eventDate}</Moment>
                      </td>
                      <td className="px-6 py-4 text-nowrap">
                        <button className="bg-[#1fabb5] text-white rounded py-2 px-3 outline-none shadow-lg">
                          LiveReport
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr colSpan={6}>
                  <td
                    className="text-2xl font-semibold p-2 text-center"
                    colSpan={7}
                  >
                    Loading Data...
                  </td>
                </tr>
              )
            ):(<tr colSpan={6}>
              <td
                className="text-2xl font-semibold p-2 text-center"
                colSpan={7}
              >
                {selectedMatch==1?"Soccer ":selectedMatch==2?"Tennis ": "Cricket "}Data Not Found...
              </td>
            </tr>)
            }
          </tbody>
        </table>
      </div>

      {/* pagination */}
      <div className="pagination flex justify-between mt-4 p-2">
        <div>
          Showing {page * 50 - 50 + 1} to{" "}
          {dataLength > page * 50 ? page * 50 : dataLength} of entries{" "}
          {dataLength}
        </div>
        <div className="border text-md rounded shadow-md flex">
          <button
            onClick={() => {
              if (page == 1) return;
              setPage(page - 1);
            }}
            className="px-2  active:bg-slate-100 py-1 border-e-2"
          >
            Previous
          </button>
          <button className="px-2 py-1">{page}</button>
          <button
            onClick={() => {
              if (page == maxPage) return;
              setPage(page + 1);
            }}
            className="px-2  active:bg-slate-100 py-1 border-s-2"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
