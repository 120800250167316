import React, { useContext, useEffect, useState } from "react";
import { MdAccessTime } from "react-icons/md";
import DashboardInfo from "../../components/DashboardInfo";
import { Link, useLocation, useParams } from "react-router-dom";
import { MainContext } from "../../contexts/Main";
import Moment from "react-moment";

export default function SessionBet() {
  const { prevPath } = useContext(MainContext);
  const { getBetSlips } = useContext(MainContext);
  const { gameId, issettlement } = useParams();
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [IsSettlement, setIsSettlement] = useState({
    settledBets: 0,
    unSettledBets: 0,
  });
  const [user, setUser] = useState("All");
  const [fanc, setFanc] = useState("All");
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [uniqueFancy, setUniqueFancy] = useState([]);

  const [runners, setRunners] = useState([]);
  const [total, setTotal] = useState({
    totalAmount: 0,
    noBet: 0,
    yesBet: 0,
    noShare: 0,
    yesShare: 0,
    plusMinus: 0,
  });

  const [pagination, setPagination] = useState(1);

  const fetchAllData = () => {
    getBetSlips(gameId, issettlement)
      .then((success) => {
        console.log("in session bet slip: ", success);
        if (success.status) {
          let bets = success.result.filter((item) => {
            return item.Market == "Fancy";
          });
          const countSettleBets = success.result.filter((item) => {
            return item.Market == "Fancy" && item.IsSettlement == 1;
          });
          const countunSettleBets = success.result.filter((item) => {
            return item.Market == "Fancy" && item.IsSettlement == 2;
          });
          setData(bets);
          setAllData(bets);
          setIsSettlement({
            settledBets: countSettleBets.length,
            unSettledBets: countunSettleBets.length,
          });
          const uniqueId = bets.map((item) => {
            return item.UserId;
          });
          setUniqueUsers([...new Set(uniqueId)]);
          let uniqueFancyCopy = success.result.filter((item) => {
            return item.Market == "Fancy";
          });
          let makeUnique = [];
          uniqueFancyCopy.forEach((item)=>{
            let exist = makeUnique.find((makeitem=>makeitem.SelectionId == item.SelectionId));
            if(!exist){
              makeUnique.push(item);
            }
          })

          console.log("Make Unique: ", makeUnique);
          setUniqueFancy(makeUnique);
          setRunners(success.runners);
        }
        console.log("success: ", success);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  console.log("Fancy Data : ", data);
  console.log("settle and unsettled bets: ", IsSettlement);
  console.log("Runners: ", runners);
  console.log("uniqueFancy: ", uniqueFancy);

  useEffect(() => {
    if (user != "All" || fanc != "All") {
      let newData = allData.filter((item) => {
        if (user != "All") {
          if (fanc != "All") {
            return item.SelectionId == fanc && item.UserId == user;
          } else {
            return item.UserId == user;
          }
        } else {
          if (fanc != "All") {
            return item.SelectionId == fanc;
          }
        }
      });
      setData(newData);
    } else {
      setData(allData);
    }
  }, [user, fanc]);

  useEffect(fetchAllData, [gameId]);

  const titleAndName = [
    {
      title: "Total Bets",
      value: allData.length,
    },
    {
      title: "Settle bets",
      value: IsSettlement?.settledBets,
    },
    {
      title: "Unsettled bets",
      value: IsSettlement?.unSettledBets,
    },
    // {
    //   title: "Reverted Bets",
    //   value: "0",
    // },
  ];

  const TotalHandler = () => {
    if (data.length != 0) {
      let totalAmm = 0,
        totalNoBet = 0,
        totalYesBet = 0,
        totalResultAmount = 0;
      data.forEach((item) => {
        totalAmm += item.AmountStake;
        totalResultAmount += item.ResultAmount;
        totalNoBet += item.Type == "back" ? item.Liability : item.Profit;
        totalYesBet += item.Type == "back" ? item.Profit : item.Liability;
      });
      console.log("total ammount: ", totalAmm);
      console.log("totalNoBet: ", totalNoBet);
      console.log("totalYesBet: ", totalYesBet);
      console.log("totalResultAmount: ", totalResultAmount);
      setTotal({
        ...total,
        totalAmount: totalAmm,
        noBet: totalNoBet,
        yesBet: totalYesBet,
        plusMinus: totalResultAmount,
      });
    }
  };

  useEffect(TotalHandler, [data]);

  return (
    <div className="w-full min-h-[100vh] bg-gray-100">
      {/* breadCrum */}
      <div className="py-2 px-4 bg-white">
        <h2 className="text-gray-600 text-2xl mb-2 ">Matches</h2>
        <div className="flex gap-2 flex-wrap text-sm">
          <span>
            <Link to={"/dashboard"}>Dashboard</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={"/liveGames/1"}>Matches</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={prevPath?.pathname}>{prevPath?.eventName}</Link>
          </span>
          <span className="font-semibold">/</span>
          <span className="font-semibold">{prevPath?.title}</span>
        </div>
      </div>

      <div className="grid mt-4 grid-cols-1 bg-white sm:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-x-6 gap-y-3">
        {titleAndName.map((data, index) => {
          return <DashboardInfo key={index} data={data} />;
        })}
      </div>

      <div className="flex flex-wrap gap-2 p-4">
        <select
          className="px-2 py-1 w-[40%] min-w-[200px] rounded-lg border border-gray-100 outline-none"
          name=""
          id=""
          value={user}
          onChange={(e) => {
            setUser(e.target.value);
          }}
        >
          <option value="All">All User</option>
          {uniqueUsers.length != 0 &&
            uniqueUsers.map((item) => <option value={item}>{item}</option>)}
        </select>
        <select
          className="px-2 py-1 w-[40%] min-w-[200px] rounded-lg border border-gray-100 outline-none"
          name=""
          id=""
          value={fanc}
          onChange={(e) => {
            setFanc(e.target.value);
          }}
        >
          <option value="All">All Fancy</option>
          {uniqueFancy.length != 0 &&
            uniqueFancy.map((item) => (
              <option value={item.SelectionId}>{item.Selection}</option>
            ))}
        </select>
      </div>

      {/* Bet Slips */}
      <div className="w-full mt-2 bg-white">
        <div className="p-2 border-gray-200 border-t-[0.2em] border-b">
          <h2 className="text-md font-[600]">Bet Slips</h2>
        </div>

        {/* table */}
        <div className="p-4">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    colSpan={10}
                    scope="col"
                    className="p-2 text-center text-nowrap border"
                  >
                    Bet
                  </th>
                  <th
                    colSpan={3}
                    scope="col"
                    className="p-2 text-nowrap border"
                  >
                    My Share / Position
                  </th>
                  <th
                    colSpan={2}
                    scope="col"
                    className="p-2 text-nowrap border"
                  >
                    Settlement
                  </th>
                </tr>
                <tr>
                  <th scope="col" className="p-2 text-nowrap border">
                    Bet Id
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Date
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    User
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Session Title
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    RATE
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Runs
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    AMOUNT
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    MODE
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    No
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Yes
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    My Share
                  </th>

                  <th scope="col" className="p-2 text-nowrap border">
                    No
                  </th>

                  <th scope="col" className="p-2 text-nowrap border">
                    Yes
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Status
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Plus / Minus
                  </th>
                </tr>
              </thead>
              <tbody className="search_user">
                {data.length != 0 &&
                  data.map((item, index) => {
                    return (
                      <tr key={index} className="bg-white border-b align-top dark:bg-gray-800 dark:border-gray-700">
                        <td className="p-2 border text-wrap text-sm">
                          {item?.BetId}
                        </td>
                        <td className="p-2 border text-nowrap text-sm">
                          <span>
                            <MdAccessTime className="inline mb-[0.2rem]" />
                            <Moment format="ddd MMM DD hh:mm:ss z YYYY">
                              {issettlement ? (item?.PlaceTime) : (item?.SettleTime)}
                            </Moment>
                          </span>
                        </td>
                        <td className="p-2 border text-nowrap text-sm">{`${item?.UserId} (${item?.FullName})`}</td>
                        <td className="p-2 border text-nowrap text-sm">
                          {item?.Selection}
                        </td>
                        <td className="p-2 border text-nowrap text-sm">{0}</td>
                        <td className="p-2 border text-nowrap text-sm">
                          {item?.OddsRequest}
                        </td>
                        <td className="p-2 border text-nowrap text-sm">
                          {item?.AmountStake}
                        </td>
                        <td className="p-2 border text-nowrap text-sm">
                          {item?.Type == "back"
                            ? "YES"
                            : item?.Type == "lay"
                            ? "NO"
                            : ""}
                        </td>
                        <td className="p-2 border text-nowrap text-sm">
                          {item?.Type == "back"
                            ? item?.Liability
                            : item?.Type == "lay"
                            ? item?.Profit
                            : "-"}
                        </td>
                        <td className="p-2 border text-nowrap text-sm">
                          {item?.Type == "back"
                            ? item?.Profit
                            : item?.Type == "lay"
                            ? item?.Liability
                            : "-"}
                        </td>
                        <td className="p-2 border text-nowrap text-sm">0%</td>
                        <td className="p-2 border text-nowrap text-sm">0</td>
                        <td className="p-2 border text-nowrap text-sm">0</td>
                        <td className="p-2 border text-nowrap text-sm">
                          <button className={`text-[12px] px-2 text-white rounded-lg ${issettlement ? "bg-[#f8ac59]" : "bg-[#1fabb5]"}`}>
                            {issettlement ? "Pending" : "Settle"}
                          </button>
                        </td>
                        <td className="p-2 border text-nowrap text-sm">
                          {item?.ResultAmount}
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <td className="p-2 border text-sm font-[600]"></td>
                  <td className="p-2 border text-sm font-[600]"></td>
                  <td className="p-2 border text-sm font-[600]">Total</td>
                  <td className="p-2 border text-sm font-[600]"></td>
                  <td className="p-2 border text-sm font-[600]"></td>
                  <td className="p-2 border text-sm font-[600]"></td>
                  <td className="p-2 border text-sm font-[600]">
                    {total.totalAmount}
                  </td>
                  <td className="p-2 border text-sm font-[600]"></td>
                  <td className="p-2 border text-sm font-[600]">
                    {total.noBet}
                  </td>
                  <td className="p-2 border text-sm font-[600]">
                    {total.yesBet}
                  </td>
                  <td className="p-2 border text-sm font-[600]"></td>
                  <td className="p-2 border text-sm font-[600]">
                    {total.noShare}
                  </td>
                  <td className="p-2 border text-sm font-[600]">
                    {total.yesShare}
                  </td>
                  <td className="p-2 border text-sm font-[600]"></td>
                  <td className="p-2 border text-sm font-[600]">
                    {total.plusMinus}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* pagination */}
        <div className="pagination flex justify-end p-2">
          <button onClick={()=>{setPagination(pagination-1)}} disabled={pagination==1} className={`border px-3 text-[0.9rem] ${pagination == 1 ? "cursor-not-allowed" : ""}`}>Previous</button>
          <button className="border px-2 text-[0.9rem] border-l-0 border-r-0">
            {pagination}
          </button>
          <button title="Go To Next Page" onClick={()=>setPagination((prevState => prevState+1))} className="border px-3 text-[0.9rem]">Next</button>
        </div>
      </div>
    </div>
  );
}
