import React, { useContext, useEffect, useState } from 'react'
import BreadCrum from '../components/BreadCrum'
import { MainContext } from '../contexts/Main';
import ManageTable from '../components/ManageTable';

export default function Super() {
  
  const {fetchDatabyParendId, isLogin,admin_stages,search} = useContext(MainContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchDatabyParendId().then(({ data }) => {
      const newData = data.filter((data) => {
        if (data.RoleId == 6) {
          return true;
        }
      });
      setData(newData.reverse());
    });
  }, [search]);
  const breadCrum = {
    topName: "Super",
    lastName: "Super",
  }
  return (
    <div className='w-full min-h-[100vh]'>
      <BreadCrum breadCrum={breadCrum}/>
      {
        // data.length!=0&&
      <ManageTable data={data}  create={isLogin?.roleId+1==admin_stages[5]?.roleId&&"SP"}/>
      }
    </div>
  )
}
