import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../contexts/Main";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

export default function ClientReport() {
  const { prevPath, admin_stages, getLoginInfo, apiBaseUrl, apiAdminsUrl,setSpiner } = useContext(MainContext);
  const [tableInformation, setTableInformation] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loginId, setLoginId] = useState(null);
  const [matchPlusMinusTotal, setMatchPlusMinusTotal] = useState(0);
  const [sessionTotal, setSessionTotal] = useState(0);
  const {issettlement} = useParams();

  useEffect(()=>{ 
    const {roleId, userId} = getLoginInfo();
    setLoginId(userId)
    console.log("UserId of Logged in: ", roleId);
    const next = admin_stages.find(item=>item.roleId > roleId);
    setTableInformation(next);
  }, [admin_stages]);



  const getTableInformation = () => {
    if(tableInformation !=null && loginId != null){
      setSpiner(true);
      axios.get(`${apiBaseUrl+apiAdminsUrl}/getAgentReport/${[loginId]}/${issettlement ? issettlement : ""}`).then((success)=>{
        setTableData(success.data.result);
      }).catch((err)=>{
        console.log("error: ", err);
      })
      setSpiner(false);
    }
  }

  useEffect(()=>{
    if(tableData.length != 0){
      let newmatchPlusMinusTotal = 0;
      let newsessionTotal = 0;
      tableData.forEach(element => {
        newmatchPlusMinusTotal += element.totalMobmResult;
        newsessionTotal += element.totalFancyResult;
      });
      setMatchPlusMinusTotal(newmatchPlusMinusTotal);
      setSessionTotal(newsessionTotal);
    }
  }, [tableData])

  console.log("success: ", tableData);

  useEffect(getTableInformation, [tableInformation]);


  console.log("tableInformation: ",tableInformation);

  return (
    <div className="w-full min-h-[100vh] bg-gray-100">
      <div className="py-2 px-4 bg-white">
        <h2 className="text-gray-600 text-2xl mb-2 ">{prevPath?.title}</h2>
        <div className="flex gap-2 flex-wrap text-sm">
          <span>
            <Link to={"/dashboard"}>Dashboard</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={"/liveGames/1"}>Matches</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={prevPath?.pathname}>{prevPath?.eventName}</Link>
          </span>
          <span className="font-semibold">/</span>
          <span className="font-semibold">{prevPath?.title ? (prevPath?.title) : ""}</span>
        </div>
      </div>
      <div className="w-full mt-4 bg-white">
        <div className="p-2 border-gray-200 border-t-[0.2em] border-b">
          <h2 className="text-md font-[600]">{prevPath?.title}</h2>
        </div>

        {/* table */}
        <div className="p-4">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-2 text-nowrap border">
                    User Name
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Match Plus Minus
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Session Plus Minus
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Total
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Match Commission
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Session Commission
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Total Commission
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Net
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Agent Share
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    OTHERS
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Final
                  </th>
                </tr>
              </thead>
              <tbody className="search_user">
                <tr className="bg-white border-b align-top dark:bg-gray-800 dark:border-gray-700">
                  <td className="p-2 border text-sm">-</td>
                  <td className="p-2 border text-sm">-</td>
                  <td className="p-2 border text-sm">-</td>
                  <td className="p-2 border text-sm">-</td>
                  <td className="p-2 border text-sm">-</td>
                  <td className="p-2 border text-sm">-</td>
                  <td className="p-2 border text-sm">-</td>
                  <td className="p-2 border text-sm">-</td>
                  <td className="p-2 border text-sm">-</td>
                  <td className="p-2 border text-sm">-</td>
                  <td className="p-2 border text-sm">-</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th scope="col" className="p-2 text-nowrap border">
                    Total
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    0.00
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    0.00
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    0.00
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    0.00
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    0.00
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    0.00
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    0.00
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    0.00
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    0.00
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    0.00
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

      <div className="w-full mt-10 bg-white">
        <div className="p-2 border-gray-200 border-t-[0.2em] border-b">
          <h2 className="text-md font-[600]">{tableInformation?.roleName} Report</h2>
        </div>

        {/* table */}
        <div className="p-4">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-2 text-nowrap border">
                    UserName
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Match Plus Minus
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Session Plus Minus
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Total
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Match Commission
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Session Commission
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Total Commission
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Net
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Agent Share
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    OTHERS
                  </th>
                  <th scope="col" className="p-2 text-nowrap border">
                    Final
                  </th>
                </tr>
              </thead>
              <tbody className="search_user">
                {
                  tableData.length != 0 ?
                  tableData.map((item, index)=>{
                    return (
                      <tr className="bg-white border-b align-top dark:bg-gray-800 dark:border-gray-700">
                        <td className="p-2 border text-sm">{item?.UserName}({item?.FullName})</td>
                        <td className="p-2 border text-sm">{item?.totalMobmResult}</td>
                        <td className="p-2 border text-sm">{item?.totalFancyResult}</td>
                        <td className="p-2 border text-sm">{((item.totalMobmResult ? (item.totalMobmResult) : 0) + (item?.totalFancyResult ? (item?.totalFancyResult) : 0)).toFixed(2)}</td>
                        <td className="p-2 border text-sm">0</td>
                        <td className="p-2 border text-sm">0</td>
                        <td className="p-2 border text-sm">0</td>
                        <td className="p-2 border text-sm">0</td>
                        <td className="p-2 border text-sm">0</td>
                        <td className="p-2 border text-sm">0</td>
                        <td className="p-2 border text-sm">{((item.totalMobmResult ? (item.totalMobmResult) : 0) + (item?.totalFancyResult ? (item?.totalFancyResult) : 0)).toFixed(2)}</td>
                      </tr>
                    )
                  }) : ""
                }
                <tr className="bg-white border-b align-top dark:bg-gray-800 dark:border-gray-700">
                        <td className="p-2 border font-[700] text-sm">Total</td>
                        <td className="p-2 border font-[700] text-sm">{matchPlusMinusTotal}</td>
                        <td className="p-2 border font-[700] text-sm">{sessionTotal}</td>
                        <td className="p-2 border font-[700] text-sm">{matchPlusMinusTotal+sessionTotal}</td>
                        <td className="p-2 border font-[700] text-sm">0</td>
                        <td className="p-2 border font-[700] text-sm">0</td>
                        <td className="p-2 border font-[700] text-sm">0</td>
                        <td className="p-2 border font-[700] text-sm">0</td>
                        <td className="p-2 border font-[700] text-sm">0</td>
                        <td className="p-2 border font-[700] text-sm">0</td>
                        <td className="p-2 border font-[700] text-sm">{matchPlusMinusTotal+sessionTotal}</td>
                      </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
