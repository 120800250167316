import React, { useContext, useEffect, useState } from "react";
import { IoIosClose, IoIosSettings } from "react-icons/io";
import BreadCrum from "../../components/BreadCrum";
import { MainContext } from "../../contexts/Main";
import { PiWarningCircleLight } from "react-icons/pi";
import axios from "axios";
import { act } from "react";

export default function Series() {
  const [seriesData, setSeriesData] = useState([]);
  const [filter, setFilter] = useState("");
  const [popup, setPopUp] = useState(false);
  const { apiSportsUrl, apiBaseUrl, notify, findSeriesAllData } =
    useContext(MainContext);
  const [close, setClose] = useState(null);
  const [page, setPage] = useState(1);
  const [actualData, setActualData] = useState([]);

  const [confirmCheck, setConformCheck] = useState({
    seriesId: undefined,
    status: undefined,
  });

  let end = page * 10,
    start = end - 10;
  const findSeries = () => {
    findSeriesAllData()
      .then((success) => {
        console.log("succes.data", success.data)
        if (filter == "") {
          setActualData(success.data);
        } else {
          const filteredData = success.data.filter((d)=>{
            return d.sportId == filter;
          })
          setActualData(filteredData);
        }
      })
      .catch((err) => {
        notify("Client Side Error");
      });
  };
  useEffect(findSeries, [filter]);

  useEffect(() => {
    if (actualData.length != 0) {
      const updatedData = actualData.slice(start, end);
      if (updatedData.length != 0) {
        setSeriesData(updatedData);
      } else {
        setPage(page - 1);
      }
    }
  }, [page, actualData]);

  const updateStatus = () => {
    if (
      confirmCheck.seriesId != undefined &&
      confirmCheck.status != undefined
    ) {
      axios
        .patch(
          `${apiBaseUrl + apiSportsUrl}/update-status/${
            confirmCheck.seriesId
          }?status=${confirmCheck.status}`
        )
        .then((success) => {
          if (success.data.status) {
            setConformCheck({
              seriesId: undefined,
              status: undefined,
            });
            findSeries();
            notify(success.data.message, "success");
          } else {
            notify(success.data.message, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          notify("Client Side Error", "error");
        });
    }
  };

  const breadCrum = {
    topName: "Series Settings",
    lastName: "series",
  };
  return (
    <div className="w-full min-h-[100vh]">
      <BreadCrum breadCrum={breadCrum} />
      <div className="fliterData mb-4 p-2 shadow-sm">
        <select
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          className="p-2 min-w-[250px] outline-none border border-[#1fabb5] rounded-lg"
          name=""
          id=""
        >
          <option value={""}>Select Sport</option>
          <option value={4}>Cricket</option>
          <option value={2}>Tennis</option>
          <option value={1}>Soccer</option>
        </select>
      </div>
      <div className="lists border">
        <div className="heading font-semibold text-lg p-3 bg-white">List</div>
        <div className="overflow-x-auto">
          <table className="select-none w-full text-sm text-left border-2 rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs border-b-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  So.
                </th>
                <th scope="col" className="px-6 py-3">
                  Series Id
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Series Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Sports Name
                </th>
                <th scope="col" className="px-6 py-3">
                  ON/OFF
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="">
              {seriesData.length != 0 ? (
                seriesData.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4">{((10 * page)-10)+(index+1)}</td>
                      <td className="px-6 py-4">{data.seriesId}</td>
                      <td className=" py-4 text-blue-600 font-semibold cursor-pointer">
                        {data.seriesName}
                      </td>
                      <td className="px-6 py-4 text-green-600">
                        {data.sportId == 1
                          ? "Soccer"
                          : data.sportId == 2
                          ? "Tennis"
                          : data.sportId == 4
                          ? "Cricket"
                          : ""}
                      </td>
                      <td className="px-6 py-4 text-green-600">
                        <input
                          type="checkbox"
                          onChange={(ev) => {
                            setPopUp(true);
                            const newStatus = ev.target.checked ? 1 : 0;
                            setConformCheck({
                              seriesId: data.seriesId,
                              status: newStatus,
                            });
                          }}
                          checked={data.status == 1 ? true : false}
                          name=""
                          id=""
                        />
                      </td>
                      <td className="px-6 py-4 ">
                        <button
                          onClick={() => setClose(data?.seriesId)}
                          className="bg-[#1fabb5] flex p-2 relative  text-white items-center text-md rounded gap-1"
                        >
                          <IoIosSettings className="text-lg settingsIcon text-white" />
                          <span>Settings</span>
                          {/* <GamePopUp/> */}
                          <GamePopUp
                            data={data}
                            close={close}
                            setClose={setClose}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="text-center">
                  <td className="font-bold text-center  p-4 w-full" colSpan={5}>
                    Loading Data....
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Toggle-PopUp */}
          <div
            style={{ background: "rgba(0,0,0,0.3)" }}
            className={`select-none z-[999999999] top-0 left-0 fixed w-[100vw] h-[100vh] duration-50 scale-0 opacity-0 ${
              popup ? "scale-100 opacity-100 duration-200" : ""
            }`}
          >
            <div className="grid place-items-center w-full  h-[100vh]">
              <div className="max-w-[70%] bg-white px-6 md:px-12 py-3 md:py-5 rounded-lg shadow-2xl">
                <div className="text-8xl text-yellow-500 flex justify-center items-center">
                  <PiWarningCircleLight />
                </div>
                <div className="flex items-center flex-col gap-10">
                  <div className="flex flex-col items-center gap-2">
                    <h2 className="font-semibold text-2xl text-center">
                      Are You Sure
                    </h2>
                    <p className="text-center">You Want to Change Status!</p>
                  </div>
                  <div className="flex gap-2 justify-center">
                    <button
                      onClick={() => {
                        updateStatus();
                        setPopUp(false);
                      }}
                      className="bg-blue-500 text-white px-3 rounded py-2"
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => {
                        setPopUp(false);
                      }}
                      className="bg-red-500 text-white px-2 rounded py-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex text-sm my-4 p-3 sm:flex-row flex-col gap-5 items-center sm:justify-between">
        <div>
          Showing {start+1} to {(start+10)>actualData.length?actualData.length:(start+10)} of entries {actualData.length}
        </div>
        <div className="border rounded">
          <button
            onClick={() => {
              if (page == 1) return;
              setPage(page - 1);
            }}
            className="px-2 py-1 border-r "
          >
            Previous
          </button>
          <button className="px-2">{page}</button>
          <button
            onClick={(e) => {
              if (actualData.length != 0) {
                setPage(page + 1);
              }
            }}
            className="px-2 py-1 border-l"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

const GamePopUp = ({ data, close, setClose }) => {
  const { apiBaseUrl, apiSportsUrl, notify } = useContext(MainContext);
  const [updateData, setUpdateData] = useState({
    MaxOddLimit: "",
    MinOddLimit: "",
    MaxBookmakerLimit: "",
    MinBookmakerLimit: "",
    MaxFancyLimit: "",
    MinFancyLimit: "",
  });

  const findEachData = () => {
    if (data != undefined) {
      axios
        .get(
          `${apiBaseUrl + apiSportsUrl}/settings/${data.sportId}/${
            data.seriesId
          }`
        )
        .then((success) => {
          if (success.data.status) {
            setUpdateData({
              MaxOddLimit: success.data.result.MaxOddLimit,
              MinOddLimit: success.data.result.MinOddLimit,
              MaxBookmakerLimit: success.data.result.MaxBookmakerLimit,
              MinBookmakerLimit: success.data.result.MinBookmakerLimit,
              MaxFancyLimit: success.data.result.MaxFancyLimit,
              MinFancyLimit: success.data.result.MinFancyLimit,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  useEffect(findEachData, [data]);
  const updateHandler = () => {
    axios.put(`${apiBaseUrl+apiSportsUrl}/update/${data.seriesId}`,updateData).then((success)=>{
      if(success.data.status){
        findEachData();
        setClose(null);
        notify(success.data.message, "success");
      }else{
        notify(success.data.message, "error")
      }
    }).catch((err)=>{
      notify("Client Side Error", "error")
    })
  };

  return (
    <div
      className={`fixed bg-[rgba(0,0,0,0.5)] z-[999999] p-4 top-0 left-0 w-full min-h-[100vh] duration-200 ${
        data?.seriesId == close ? "visible opacity-100" : "opacity-0 invisible"
      }`}
    >
      <div className="max-w-[650px] mx-auto p-2">
        <div className="flex justify-between font-medium text-[15px] p-2 bg-[#1fabb5]">
          <span>{data?.SportName}</span>
          <span className="text-2xl">
            <IoIosClose
              onClick={(e) => {
                e.stopPropagation();
                setClose(null);
              }}
            />
          </span>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 border py-3"></th>
                <th scope="col" className="px-6 border py-3">
                  Min
                </th>
                <th scope="col" className="px-6 border py-3">
                  Max
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 border">Odds</td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MinOddLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MinOddLimit"
                    defaultValue={updateData?.MinOddLimit}
                    id=""
                  />
                </td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MaxOddLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MaxOddLimit"
                    defaultValue={updateData?.MaxOddLimit}
                    id=""
                  />
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 border">BookMarker</td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MinBookmakerLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MinBookmakerLimit"
                    defaultValue={updateData?.MinBookmakerLimit}
                    id=""
                  />
                </td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MaxBookmakerLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MaxBookmakerLimit"
                    defaultValue={updateData?.MaxBookmakerLimit}
                    id=""
                  />
                </td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4 border">Session</td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MinFancyLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MinFancyLimit"
                    defaultValue={updateData?.MinFancyLimit}
                    id=""
                  />
                </td>
                <td
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      MaxFancyLimit: e.target.value,
                    });
                  }}
                  className="px-6 py-4 border"
                >
                  <input
                    className="border-2 p-1 rounded outline-none"
                    type="number"
                    name="MaxFancyLimit"
                    defaultValue={updateData?.MaxFancyLimit}
                    id=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex bg-white gap-2 justify-start p-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setClose(null);
            }}
            className="bg-[#1fabb5] rounded p-2"
          >
            Cancel
          </button>
          <button onClick={updateHandler} className="bg-[#1fabb5] rounded p-2">
            Update
          </button>
        </div>
      </div>
    </div>
  );
};
