import React, { useContext, useRef } from "react";
import BreadCrum from "../components/BreadCrum";
import { MainContext } from "../contexts/Main";
import DashboardInfo from "../components/DashboardInfo";
import { Link } from "react-router-dom";

export default function UserDashBoard() {
  const { edit, admin_stages } = useContext(MainContext);
  console.log("edit", edit);
  //breadCrum details
  const breadCrum = admin_stages.find((d) => edit?.RoleId == d.roleId);
  const data = [
    {
      title: "coins",
      value: edit?.ResultAmountU ? edit?.ResultAmountU + "" : "0.00",
    },
    {
      title: "Rs.Exposure",
      value: edit?.ResultAmountU ? edit?.ResultAmountU + "" : "0.00",
    },
  ];

  const transactionOp = [
    {
      name: "Recieve Case",
      path: "/agent/recieve-case/",
    },
    {
      name: "Pay Case",
      path: "/agent/pay-case/",
    },
    {
      name: "Ledger",
      path: "/agent/ledger-show-ledger/",
    },
    {
      name: "Case Ledger",
      path: "/agent/ledger-show-case/",
    },
    {
      name: "Match Ledger",
      path: "/agent/ledger-show-match/",
    },
    {
      name: "Coin History",
      path: "/agent/coin-history/",
    },
    {
      name: "Statement",
      path:
        "/statementByUser/admin/" +
        edit?.UserName +
        "/" +
        edit?.Id +
        `${edit?.RoleId == 8 ? "/" + edit?.RoleId : ""}`,
    },
  ];
console.log("editss: ",edit)
  if (edit?.RoleId != 8) {
    transactionOp.push(
      {
        name: "Direct Agents",
        path: "/agent/direct-agents/",
      },
      {
        name: "Direct Clients",
        path: "/agent/direct-clients/",
      }
    );
  }

  const dashinfo = data.map((d, i) => {
    return <DashboardInfo key={i} data={d} />;
  });
  return (
    <div className="w-full bg-slate-100 min-h-[100vh]">
      <div className="px-4 select-none bg-white md:py-2 py-4 border-t-[1px] border-b-[1px] my-2">
        <h2 className="lg:text-2xl text-lg font-sans text-gray-500 uppercase">
          Agents
        </h2>
        <div className="">
          <Link to={"/dashboard"}>
            <span className="text-sm font-semibold text-gray-500">
              Dashboard
            </span>
          </Link>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            <Link to={breadCrum?.path}>{edit?.UserName.slice(0, 2)}</Link>
          </span>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            {edit?.UserName + " (" + edit?.FullName + ")"}
          </span>
        </div>
      </div>

      <div className="flex mt-3 bg-white flex-col items-start justify-center gap-5 border-b-2 ">
        <h2 className="text-4xl p-4 border-b-2 block w-full text-gray-500">
          Agent Dashboard
        </h2>
        <div className="flex px-4 gap-x-2 gap-y-5 justify-center flex-wrap py-4">
          {transactionOp.map((d, i) => {
            return (
              <div
                key={i}
                className="bg-[#1fabb5] text-white py-2 px-4 cursor-pointer rounded-lg"
              >
                <Link to={d.name != "Statement" ? d.path + edit?.UserName + "/" : d.path}>{d.name}</Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className="p-5 bg-white sm:my-[4rem]">{dashinfo}</div>
    </div>
  );
}
