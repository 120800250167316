import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../../components/BreadCrum";
import { useLocation, useNavigate } from "react-router-dom";
import { MainContext } from "../../contexts/Main";
import axios from "axios";

function SetMsgSS() {
  const { state } = useLocation();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const {
    getAppHeading1,
    heading1,
    setHeading1,
    notify,
    apiBaseUrl,
    apiAdminsUrl,
  } = useContext(MainContext);
  const breadCrum = {
    topName: "Set Message For SS/Dealer",
    lastName: "Settings Dashboard",
    prevPath: state,
    finalName: "Set Message For SS/Dealer",
  };

  const fetchMsgSs = () => {
    getAppHeading1()
      .then((ok) => {
        console.log("ok", ok);
        setMessage(ok);
      })
      .catch((err) => {
        setMessage("");
        console.log("err", err);
      });
  };

  console.log("Heading1 from Main Context: ", heading1);
  console.log("Message in Ss: ", message);

  useEffect(fetchMsgSs, []);

  const changeMessage = () => {
    if (message != heading1) {
        axios.patch(`${apiBaseUrl+apiAdminsUrl}/update/message/ss`, {message}).then((success)=>{
            console.log("Success After Updated Message: ", success);
            if(success.data.status){
                fetchMsgSs();
              notify("Message Changed", "success");
              navigate(state);
            }
          }).catch((err)=>{
            console.log("Error: ", err);
          })
    } else {
      notify("message not updated!! create a new message", "error");
    }
  };
  return (
    <div className="w-full min-h-[100vh] bg-slate-100">
      <BreadCrum breadCrum={breadCrum} />
      <textarea
        className="w-full border-[2px] outline-none min-h-[70px] p-2 border-[#1fabb5]"
        name=""
        id=""
        placeholder="Enter You message.."
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      ></textarea>
      <div className="p-2">
        <button
          onClick={changeMessage}
          className="bg-[#23c6c8] text-white px-2 py-1 rounded-md"
        >
          Set Message
        </button>
      </div>

      <p className="p-2 font-[400]">
        <span>Current Message :: </span> {heading1}
      </p>
    </div>
  );
}

export default SetMsgSS;
