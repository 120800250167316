import React, { useContext, useEffect, useState } from 'react'
import { TiEdit } from 'react-icons/ti';
import BreadCrum from '../components/BreadCrum';
import ManageTable from '../components/ManageTable';
import { MainContext } from '../contexts/Main';

export default function BlockedClients() {
  const { fetchDatabyParendId, isLogin, admin_stages } =
    useContext(MainContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchDatabyParendId().then(({ data }) => {
      const newData = data.filter((data) => {
        if (data.RoleId == 8 && data.LockUser == 1) {
          return true;
        }
      });
      setData(newData.reverse());
    });
  }, []);
  const breadCrum = {
    topName: "User",
    lastName: "User",
  };
  return (
    <div className="w-full min-h-[100vh]">
      <BreadCrum breadCrum={breadCrum} />
      {(
        <ManageTable
          data={data}
          blockUser = {"/blockedUser/1"}
          create={isLogin?.roleId + 1 == admin_stages[7]?.roleId && "US"}
        />
      )}
    </div>
  );
}