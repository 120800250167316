import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { MainContext } from '../../contexts/Main';
import SessionEarningReportComponent from '../../components/SessionEarningReportComponent';

export default function SessionEarningReport() {
  const {
    prevPath,
    admin_stages,
    getLoginInfo,
  } = useContext(MainContext);

  const {issettlement} = useParams();

  const [tableName, setTableName] = useState([]);

  useEffect(() => {
    if (admin_stages.length !== 0) {
      let tables = [];
      let login = getLoginInfo().roleId;
      admin_stages.forEach((element) => {
        if (element.roleId > login) {
          tables.push(element);
        }
      });
      // Sort tables by roleId or any other criteria
      tables.sort((a, b) => a.roleId - b.roleId);
      setTableName(tables);
    }
  }, [admin_stages, getLoginInfo]);

  return (
    <div className="w-full bg-gray-100 mb-5 min-h-[100vh]">
      <div className="py-2 px-4 bg-white">
        <h2 className="text-gray-600 text-2xl mb-2 ">{prevPath?.title}</h2>
        <div className="flex gap-2 flex-wrap text-sm">
          <span>
            <Link to={"/dashboard"}>Dashboard</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={"/liveGames/1"}>Matches</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={prevPath?.pathname}>{prevPath?.eventName}</Link>
          </span>
          <span className="font-semibold">/</span>
          <span className="font-semibold">{prevPath?.title}</span>
        </div>
      </div>
      {tableName.length !== 0
        ? tableName.map((item, index) => {
            return (
              <div key={index} className="w-full my-10 bg-white">
                <div className="p-2 border-gray-200 border-t-[0.2em] border-b">
                  <h2 className="text-md font-[600]">{item?.roleName}</h2>
                </div>
                <div className="p-4">
                  <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="p-2 text-nowrap border"></th>
                          <th colSpan={3} scope="col" className="p-2 text-nowrap border text-center">SYSTEM</th>
                          <th scope="col" className="p-2 text-nowrap border">My Share</th>
                          <th scope="col" className="p-2 text-nowrap border">Company Share</th>
                        </tr>
                        <tr>
                          <th scope="col" className="p-2 text-nowrap border">CLIENT NAME</th>
                          <th scope="col" className="p-2 text-nowrap border">SESSION</th>
                          <th scope="col" className="p-2 text-nowrap border">Comm. Session</th>
                          <th scope="col" className="p-2 text-nowrap border">Total</th>
                          <th scope="col" className="p-2 text-nowrap border">SHR AMT</th>
                          <th scope="col" className="p-2 text-nowrap border">Final</th>
                        </tr>
                      </thead>
                      <tbody className="search_user">
                        <SessionEarningReportComponent key={item.roleId} issettlement={issettlement} table={item} />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}
