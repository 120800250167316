import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../contexts/Main";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { IoEyeOffSharp } from "react-icons/io5";
import Cookies from 'js-cookie';

export default function Login() {
  const naviGate = useNavigate();
  const [togglePassword, setTogglePassword] = useState(false);

  const { apiBaseUrl, apiAdminsUrl, ip, geoLoc, isLogin, notify, setIsLogin,encryptData } =
    useContext(MainContext);
  useEffect(() => {
    const logininfo = Cookies.get("LoginData");
    if(logininfo!=undefined){
      const path = localStorage.getItem("currenturl");
      naviGate(path);
    }
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();
    const { username, password } = event.target;
    if (geoLoc != undefined && ip != undefined) {
      axios
        .post(`${apiBaseUrl + apiAdminsUrl}/login`, {
          username: username.value,
          password: password.value,
          ip,
          geolocation: `${geoLoc.cityName}/${geoLoc.regionName}/${geoLoc.countryName}/${geoLoc.zipCode}`,
        })
        .then((success) => {
          console.log(success.data);
          if (success.data.status) {
            notify(success.data.message, "success");
            naviGate("/dashboard");
            const encryptedLoginData = encryptData(success.data.data);
            Cookies.set("LoginData", encryptedLoginData);
            setIsLogin(success.data.data);
          } else {
            notify(success.data.message, "error");
          }
        })
        .catch((err) => {
          notify(err.message, "success");
          console.log("errorrrr", err);
        });
    }
  };

  return (
    <div className="w-full h-[100vh] text-black bg-white overflow-hidden">
      <div className="flex flex-col items-center h-screen select-none  justify-center gap-2 p-2">
        <div className="text-9xl text-[#e6e6e6] font-[800] tracking-[-12px]">
          N247
        </div>
        <div className="flex flex-col gap-2 ">
          <div className="flex flex-col  justify-center text-[16px] sm:text-[22px]  items-center text-gray-400 tracking-[100]">
            <div className=" tracking-[100] font-semibold text-center">
              Welcome to NEW247
            </div>
            <div className=" tracking-[100] font-semibold">Sign In</div>
          </div>
          <div className="flex justify-center">
            {/* Login Form */}
            <form
              onSubmit={submitHandler}
              className="lg:w-[70%] sm:w-[60%] flex-col justify-center items-center"
            >
              <input
                className="px-4 w-full my-3 rounded py-1 border outline-[#1fabb5]"
                placeholder="User Name"
                type="text"
                name="username"
                id=""
              />
              <span onClick={(e)=>{e.stopPropagation()}} className="relative">
                <input
                  className="px-4 w-full my-3 rounded py-1 border outline-[#1fabb5]"
                  placeholder="Password"
                  type={togglePassword?"text":"password"}
                  name="password"
                />{
                  !togglePassword?<FaEye onClick={()=>{setTogglePassword(true)}} className="absolute cursor-pointer top-[4px] right-[14px]"/>:
                  <IoEyeOffSharp onClick={()=>{setTogglePassword(false)}} className="absolute cursor-pointer top-[4px] right-[14px]"/>
                }
                
              </span>
              <input
                className="px-4 w-full cursor-pointer my-3 rounded py-1 border bg-[#1fabb5] text-white"
                type="submit"
                value="Login"
              />
              <div className="text-center text-[12px] text-gray-500">
                NEW247 © 2015
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
