import React from 'react'
import BreadCrum from '../components/BreadCrum';

export default function LiveCashino() {
  const breadCrum = {
    topName: "Casino",
    lastName: "Live Casino"
  };
  return (
    <div className='w-full  p-2 '>
       <BreadCrum breadCrum={breadCrum}/>
        <div className="flex gap-2 w-full justify-start flex-col md:flex-row">
          <div className='md:w-[200px] text-center p-2 my-2 w-full bg-[#1fabb5] text-white font-semibold'>Casino Daily PL</div>
          <div className='md:w-[200px] text-center p-2 my-2 w-full bg-[#1fabb5] text-white font-semibold'>Casino List</div>
        </div>
        <div className="grid bg-white grid-cols-1 gap-2 md:grid-cols-3">
            <div className='w-full my-5 rounded-md overflow-hidden h-[200px]' style={{
              boxShadow: "0px 0px 10px 1px gray"
            }}>
              <img className='w-full h-full' src="./images/cas2.jpg" alt="" />
            </div>
            <div className='w-full my-5 rounded-md overflow-hidden h-[200px]' style={{
              boxShadow: "0px 0px 10px 1px gray"
            }}>
              <img className='w-full h-full' src="./images/cas2.jpg" alt="" />
            </div>
            <div className='w-full my-5 rounded-md overflow-hidden h-[200px]' style={{
              boxShadow: "0px 0px 10px 1px gray"
            }}>
              <img className='w-full h-full' src="./images/cas2.jpg" alt="" />
            </div>
            <div className='w-full my-5 rounded-md overflow-hidden h-[200px]' style={{
              boxShadow: "0px 0px 10px 1px gray"
            }}>
              <img className='w-full h-full' src="./images/cas2.jpg" alt="" />
            </div>
            <div className='w-full my-5 rounded-md overflow-hidden h-[200px]' style={{
              boxShadow: "0px 0px 10px 1px gray"
            }}>
              <img className='w-full h-full' src="./images/cas2.jpg" alt="" />
            </div>  
        </div>
    </div>
  )
}
