import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { MainContext } from "../../contexts/Main";
import DashboardInfo from "../../components/DashboardInfo";
import { MdAccessTime } from "react-icons/md";
import Moment from "react-moment";

export default function BetSlips() {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const { prevPath, getBetSlips } = useContext(MainContext);
  const [user, setUser] = useState([]);
  const [plusMinus, setPlusMinus] = useState(0);
  console.log("prevPath: ", prevPath);
  const { gameId, issettlement } = useParams();
  const [matchBetween, setMatchBetween] = useState([]);
  const [sumofMatches, setSumofMatches] = useState([]);
  console.log("matchBetween: ", matchBetween);
  console.log("gameid: ", gameId);
  console.log("AllData: ", data);
  const [betType, setBetType] = useState("Matchodds");
  const [users, setUsers] = useState("All");
  
  console.log("IsSettlement: ", issettlement);

  const getSettleBets = () =>{
    const item = data.filter((item)=>{
      return item.IsSettlement == 1
    })
    return item.length
  }

  const getUnSettleBets = () =>{
    const item = data.filter((item)=>{
      return item.IsSettlement == 2
    })
    return item.length
  }

  const titleAndName = [
    {
      title: "Total Bets",
      value: data?.length,
    },
    {
      title: "Settle bets",
      value: getSettleBets(),
    },
    {
      title: "Unsettled bets",
      value: getUnSettleBets(),
    },
    // {
    //   title: "Reverted Bets",
    //   value: "0",
    // },
  ];

  const fetchAllData = () => {
    if (betType != undefined) {
      getBetSlips(gameId, issettlement)
        .then((success) => {
          console.log("Successs: ", success)
          if (success.status) {
            let copyUser = success.result.map((d) => {
              return d.UserId;
            });
            console.log("coppyy: ", copyUser);
            copyUser = [...new Set(copyUser)];
            setUser(copyUser);
            setAllData(
              success.result.filter((item) => {
                return item.Market == betType;
              })
            );
            setMatchBetween(success.runners);
            setData(
              users != "All"
                ? success.result.filter((item) => {
                    return item.Market == betType && item.UserId == users;
                  })
                : success.result.filter((item) => {
                    return item.Market == betType;
                  })
            );
          } else {
            setData([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(fetchAllData, [betType, users]);

  // useEffect(() => {
  //   if (users != "All" && data.length != 0) {
  //     setData(
  //     data.filter((user) => {
  //       return user.UserId == users;
  //     }));
  //   }
  // }, [users]);

  let TotalSum = [];
  let sumplusMinus = 0;
  useEffect(() => {
    if (allData.length != 0 && matchBetween.length != 0) {
      matchBetween.forEach((d) => {
        let sum = 0;
        allData.forEach((item) => {
          sum +=
            item.Type == "lay"
              ? item.Selection == d
                ? item.Liability
                : item.Profit
              : item.Selection == d
              ? item.Profit
              : item.Liability;
        });
        TotalSum.push(sum);
      });
      allData.forEach((item) => {
        sumplusMinus += parseInt(item.ResultAmount);
      });
      // let copyUser = data.map((d)=>{
      //   return d.UserId;
      // })
      // console.log("coppyy: ",copyUser)
      // copyUser = [...new Set(copyUser)];
      // setUser(copyUser);
      setSumofMatches(TotalSum);
      setPlusMinus(sumplusMinus);
    }
  }, [data, matchBetween]);

  console.log(TotalSum);
  console.log(sumofMatches);

  return (
    <div className="w-full min-h-[100vh] bg-gray-100">
      {/* breadCrum */}
      <div className="py-2 px-4 bg-white">
        <h2 className="text-gray-600 text-2xl mb-2 ">Matches</h2>
        <div className="flex gap-2 flex-wrap text-sm">
          <span>
            <Link to={"/dashboard"}>Dashboard</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={"/liveGames/1"}>Matches</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={prevPath?.pathname}>{prevPath?.eventName}</Link>
          </span>
          <span className="font-semibold">/</span>
          <span className="font-semibold">{prevPath?.title}</span>
        </div>
      </div>

      <div className="grid mt-4 grid-cols-1 bg-white sm:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-x-6 gap-y-3">
        {titleAndName.map((data, index) => {
          return <DashboardInfo key={index} data={data} />;
        })}
      </div>

      <div className="flex flex-wrap gap-2 p-4">
        <select
          className="px-2 py-1 w-[40%] min-w-[200px] rounded-lg border border-gray-100 outline-none"
          name=""
          id=""
          value={users}
          onChange={(e) => {
            setUsers(e.target.value);
          }}
        >
          <option value="All">All User</option>
          {user.length != 0 &&
            user.map((item, index) => {
              return <option key={index} value={item}>{item}</option>;
            })}
        </select>
        <select
          className="px-2 py-1 w-[40%] min-w-[200px] rounded-lg border border-gray-100 outline-none"
          value={betType}
          onChange={(e) => {
            setBetType(e.target.value);
            setSumofMatches([]);
            setPlusMinus(0);
          }}
          name=""
          id=""
        >
          <option className="" value="Matchodds">
            Matchodds
          </option>
          <option className="" value="BookMaker">
            BookMaker
          </option>
          {/* <option disabled value="Fancy">Fancy</option> */}
        </select>
      </div>

      {/* Market Position */}
      <div className="w-full bg-white">
        <div className="p-2 border-gray-200 border-t-[0.2em] border-b">
          <h2 className="text-md font-[600]">Market Position</h2>
        </div>
        <div className="p-4 ">
          {/* Heading */}
          <div className="flex border-gray-400 border-b py-2 px-1">
            <div className="text-md font-[600] uppercase w-full">Runner</div>
            <div className="text-md font-[600] uppercase w-full">Position</div>
          </div>
          {/* Data.. */}
          {matchBetween.length != 0 &&
            matchBetween.map((item, index) => {
              return (
                <div className="flex itemm border-gray-300 border-b py-2 px-1">
                  <div className="text-sm w-full">{item}</div>
                  <div className="text-sm w-full">
                    {sumofMatches.length != 0 ? sumofMatches[index] : 0}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* Bet Slips */}
      {data.length != 0 ? (
        <div className="w-full mt-2 bg-white">
          <div className="p-2 border-gray-200 border-t-[0.2em] border-b">
            <h2 className="text-md font-[600]">Bet Slips</h2>
          </div>

          {/* table */}
          <div className="p-4">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th
                      colSpan={9}
                      scope="col"
                      className="p-2 text-nowrap border"
                    >
                      Bet
                    </th>
                    <th
                      colSpan={4}
                      scope="col"
                      className="p-2 text-nowrap border"
                    >
                      My Share / Position
                    </th>
                    <th scope="col" className="p-2 text-nowrap border">
                      Settlement
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" className="p-2 text-nowrap border">
                      Date
                    </th>
                    <th scope="col" className="p-2 text-nowrap border">
                      Market Title
                    </th>
                    <th scope="col" className="p-2 text-nowrap border">
                      RATE
                    </th>
                    <th scope="col" className="p-2 text-nowrap border">
                      AMOUNT
                    </th>
                    <th scope="col" className="p-2 text-nowrap border">
                      MODE
                    </th>
                    <th scope="col" className="p-2 text-nowrap border">
                      Runner Name
                    </th>
                    <th scope="col" className="p-2 text-nowrap border">
                      User
                    </th>
                    {matchBetween.length != 0 &&
                      matchBetween.map((item) => {
                        return (
                          <th scope="col" className="p-2 text-nowrap border">
                            {item}
                          </th>
                        );
                      })}
                    <th scope="col" className="p-2 text-nowrap border">
                      My Share
                    </th>
                    {matchBetween.length != 0 &&
                      matchBetween.map((item) => {
                        return (
                          <th scope="col" className="p-2 text-nowrap border">
                            {item}
                          </th>
                        );
                      })}
                    <th scope="col" className="p-2 text-nowrap border">
                      Status
                    </th>
                    <th scope="col" className="p-2 text-nowrap border">
                      Plus / Minus
                    </th>
                  </tr>
                </thead>
                <tbody className="search_user">
                  {data.length != 0 &&
                    data.map((item, key) => {
                      return (
                        <tr
                          key={key}
                          className="bg-white border-b align-top dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td className="p-2 border text-sm">
                            <span>
                              <MdAccessTime className="inline mb-[0.2rem] me-1" />
                              {
                                item.SettleTime ?
                                <Moment format="ddd MMM DD hh:mm:ss z YYYY">
                                  {item?.SettleTime}
                                </Moment> : <Moment format="ddd MMM DD hh:mm:ss z YYYY">
                                  {item?.PlaceTimes}
                                </Moment>
                              }
                            </span>
                          </td>
                          <td className="p-2 border text-sm">{item?.Market}</td>
                          <td className="p-2 border text-sm">
                            {item?.OddsRequest}
                          </td>
                          <td className="p-2 border text-sm">
                            {item?.AmountStake}
                          </td>
                          <td className="p-2 border text-sm">
                            {item.Type == "back"
                              ? "LAGAI"
                              : item.Type == "lay"
                              ? "KHAI"
                              : ""}
                          </td>
                          <td className="p-2 border text-sm">
                            {item?.Selection}
                          </td>
                          <td className="p-2 border text-sm">{item?.UserId}</td>
                          {matchBetween.map((d) => {
                            return (
                              <td className="p-2 border text-sm">
                                {item.Type == "lay"
                                  ? item.Selection == d
                                    ? item.Liability
                                    : item.Profit
                                  : item.Selection == d
                                  ? item.Profit
                                  : item.Liability}
                              </td>
                            );
                          })}
                          <td className="p-2 border text-sm">0%</td>
                          <td className="p-2 border text-sm">0</td>
                          <td className="p-2 border text-sm">0</td>
                          <td className="p-2 border text-sm">
                            <button className={`text-[12px] px-2 text-white rounded-lg ${issettlement?"bg-[#f8ac59]":"bg-[#1fabb5]"}`}>
                              {issettlement?"Pending":"Settle"}
                            </button>
                          </td>
                          <td className="p-2 border text-sm">
                            {item?.ResultAmount}
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    ></td>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    ></td>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    ></td>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    ></td>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    ></td>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    ></td>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    >
                      Total
                    </td>
                    {sumofMatches.length != 0 ? (
                      sumofMatches.map((item) => {
                        return (
                          <td
                            scope="col"
                            className="p-2 text-nowrap border font-[600]"
                          >
                            {item}
                          </td>
                        );
                      })
                    ) : (
                      <>
                        <td
                          scope="col"
                          className="p-2 text-nowrap border font-[600]"
                        >
                          0
                        </td>
                        <td
                          scope="col"
                          className="p-2 text-nowrap border font-[600]"
                        >
                          0
                        </td>
                      </>
                    )}
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    ></td>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    >
                      0
                    </td>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    >
                      0
                    </td>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    ></td>
                    <td
                      scope="col"
                      className="p-2 text-nowrap border font-[600]"
                    >
                      {plusMinus}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-2 border-gray-200 border-t-[0.2em] border-b">
          <h2 className="text-md font-[600] text-center">Data Not Exist</h2>
        </div>
      )}
      <div className="pagination flex justify-end bg-white">
        <button className="px-2 py-1 text-[0.9rem] font-[400] border" style={{borderRadius: "2px 0px 0px 2px"}}>Previous</button>
        <button className="px-2 py-1 text-[0.9rem] font-[400] border">1</button>
        <button className="px-2 py-1 text-[0.9rem] font-[400] border">Next</button>
      </div>
    </div>
  );
}
