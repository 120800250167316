import React from 'react'
import BreadCrum from '../../components/BreadCrum'

export default function UpComingMatches() {
  const breadCrum = {
    topName: "UpComing Matches",
    lastName: "UpComing Matches"
  }
  return (
    <div className='w-full min-h-[100vh] bg-slate-100'>
    <BreadCrum breadCrum={breadCrum}/>
    <div className='p-2'>
      <div className='font-semibold border-2 p-2'>Enter Upcoming Match</div>
      <div className='bg-white p-2'>
      <div className="grid rounded my-2 grid-cols-5">
          <div className=' col-span-2'>Enter Match Name</div>
          <div className='col-span-3'>
          <input type="text" placeholder='Match name' name="" className='w-full py-1 px-4 border' id="" />
          </div>
        </div>
        <div className="grid rounded my-2 grid-cols-5">
          <div className=' col-span-2'>Enter Match Name</div>
          <div className='col-span-3'>
          <input type="date" name="" className='w-full py-1 px-4 border' id="" />
          <button className='px-2 my-5 py-1 text-white rounded bg-[#1fabb5]'>Add Match</button>

          </div>
        </div>
      </div>
      <input type="text" name="" placeholder='Search..' className='mt-4 px-2 py-1 border' id="" />

      <div className='border-t-2 bg-white mt-2'>
          <div className='p-2'>
          All UpComing Matches
          </div>
          <div className="grid grid-cols-3 p-4">
            <div className='bg-slate-100 py-1 px-2 border'>Match Name</div>
            <div className='bg-slate-100 py-1 px-2 border'>Date & Time</div>
            <div className='bg-slate-100 py-1 px-2 border'>Action</div>
          </div>
      </div>
    </div>
  </div>
  )
}
