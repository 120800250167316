import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../../components/BreadCrum";
import { MainContext } from "../../contexts/Main";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function PayCash() {
  const navigator = useNavigate();
  const { isLogin, apiBaseUrl, apiAdminsUrl, notify, setEdit } =
    useContext(MainContext);
  const { edit } = useContext(MainContext);
  const [deposit, setDeposit] = useState({
    manage: isLogin?.userName,
    depositAmmount: 0,
    type: "withdraw",
  });

  useEffect(() => {
    setDeposit({
      ...deposit,
      manage: isLogin?.userName,
      depositAmmount: 0,
      type: "withdraw",
    });
  }, [isLogin]);

  const breadCrum = {
    topName: "Agent",
    lastName: edit?.UserName + " (" + edit?.FullName + ")",
    finalName: "Pay Cash",
  };

  console.log("deposit: ", deposit);

  const depositHandler = () => {
    console.log("Deposit Handler", deposit);
    if (deposit.depositAmmount != 0 && deposit.depositAmmount != "") {
      if (deposit.type == "deposit") {
        if (deposit.depositAmmount <= deposit.currentAmmountP) {
          axios
            .post(`${apiBaseUrl + apiAdminsUrl}/user/deposit`, deposit)
            .then((success) => {
              if (success.data.status) {
                console.log("success: ", success);
                setEdit({
                  ...edit,
                  ResultAmountU:
                    parseInt(
                      edit?.ResultAmountU != null ? edit?.ResultAmountU : 0
                    ) + parseInt(deposit.depositAmmount),
                });
                setDeposit({
                  manage: isLogin?.userName,
                  depositAmmount: 0,
                });
                notify(success.data.message, "success");
              } else {
                notify("Something Went Wrong", "error");
              }
              navigator("/user-dashboard/" + edit?.UserName);
              // setTimeout(() => {
              //   navigator("/user-dashboard/" + edit?.UserName);
              // }, 500);
              //console.log(success);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          notify("Insufficient balance", "error");
        }
      } else {
        if (deposit.depositAmmount <= deposit.currentAmmountU) {
          axios
            .post(`${apiBaseUrl + apiAdminsUrl}/user/deposit`, deposit)
            .then((success) => {
              console.log("withdraw success: ", success);
              if (success.data.status) {
                setEdit({
                  ...edit,
                  ResultAmountU:
                    parseInt(
                      edit?.ResultAmountU != null ? edit?.ResultAmountU : 0
                    ) - parseInt(deposit.depositAmmount),
                    ResultAmountP:  parseInt(edit?.ResultAmountP != null ? edit?.ResultAmountP : 0) + parseInt(deposit.depositAmmount)
                });
                setDeposit({
                  manage: isLogin?.userName,
                  depositAmmount: 0,
                });
                notify("success.data.message", "success");
                navigator("/user-dashboard/" + edit?.UserName);
              }
              //console.log(success);
            })
            .catch((err) => {
              //console.log(err);
            });
        } else {
          notify("Insufficient balance", "error");
        }
      }
    } else {
      notify("please input a valid number!!", "error");
    }
  };

  return (
    <div className="w-full bg-slate-100 min-h-[100vh]">
      <div className="px-4 bg-white py-2 border-t-[1px] border-b-[1px] my-2">
        <h2 className="lg:text-2xl text-lg font-sans text-gray-500 uppercase">
          {breadCrum?.topName}
        </h2>
        <div className="">
          <Link to={"/dashboard"}>
            <span className="text-sm font-semibold text-gray-500">
              Dashboard
            </span>
          </Link>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            <Link to={"/user-dashboard/" + edit?.UserName}>
              {breadCrum?.lastName}
            </Link>
          </span>
          {breadCrum.finalName && (
            <>
              <span className="text-gray-400 px-2">/</span>
              <span className="text-sm font-semibold text-black uppercase">
                {breadCrum?.finalName}
              </span>
            </>
          )}
        </div>
      </div>
      <h2 className="text-md bg-white font-bold text-slate-500 py-3 ps-4 border-gray-200 border-b w-full">
        Pay Cash to User
      </h2>
      <div className="flex flex-col gap-2 mt-3 py-3 px-5 bg-white items-start">
        <h2 className="text-md font-bold text-slate-500 mt-3 w-full">
          Agent Name:
        </h2>
        <div className="text-sm">
          {edit?.UserName} ({edit?.FullName})
        </div>
        <h2 className="text-md font-bold text-slate-500 mt-4 w-full">
          Rs. Exposure:
        </h2>
        <div className="text-sm">{edit?.ResultAmountU}</div>
      </div>
      <div className="my-5 flex flex-col gap-5 bg-white p-4 pe-[2rem]">
        <div className="flex  items-center gap-3 justify-center">
          <span className="sm:w-[15%] w-[25%] sm:text-nowrap leading-4 text-right text-sm font-bold text-slate-500">
            Update Ledger
          </span>
          <input
            type="number"
            value={deposit.depositAmmount}
            onChange={(e) => {
              e.target.value = e.target.value < 0 ? 0 : e.target.value;
              setDeposit({
                ...deposit,
                userId: edit?.Id,
                ParentId: edit?.ParentId,
                currentAmmountU: edit?.ResultAmountU,
                currentAmmountP: edit?.ResultAmountP,
                depositAmmount: e.target.value.replace("-", ""),
              });
            }}
            className="px-2 py-1 border rounded outline-none sm:w-[85%] w-[75%]"
          />
        </div>
        <div className="flex items-center gap-3 justify-center">
          <span className=" sm:text-nowrap leading-4 sm:w-[15%] w-[25%] text-right text-sm font-bold text-slate-500">
            Note
          </span>
          <textarea
            className="px-2 py-1 border rounded outline-none sm:w-[85%] w-[75%]"
            type="text"
          />
        </div>
        <button
          onClick={depositHandler}
          className="px-1 sm:px-3  py-2 border rounded max-w-[8rem] sm:max-w-[10rem] bg-[#1fabb5] text-white sm:ms-[8rem]"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}
