import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MainContext } from "../contexts/Main";
import { useNavigate } from "react-router-dom";

export default function AgentTables({roleId}) {
    const navigate = useNavigate();
  const { edit,setEdit, apiBaseUrl, apiAdminsUrl } = useContext(MainContext);
  const [agents,setAgents] = useState([]);
  const fetchDataByParentId = () => {
    axios
      .get(`${apiBaseUrl + apiAdminsUrl}/getUserByParentId/${edit.Id}`)
      .then((success) => {
        const filterAgent = (success.data.data).filter((d)=>{
            return d.RoleId == roleId;
        })
        setAgents(filterAgent);
      })
      .catch((err) => {
        setAgents([]);
      });
  };
  useEffect(fetchDataByParentId,[edit])
  return (
    <>
      {agents.length != 0
        ? agents.map((info, ind) => {
              return (
                <tr key={ind} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td className="px-6 py-4 text-nowrap">{ind+1}</td>
                  <td onClick={()=>{
                        navigate('/user-dashboard/'+info?.UserName)
                        setEdit(info);
                      }} className="px-6 py-4 text-blue-700 cursor-pointer text-nowrap">{info?.UserName}</td>
                  <td className="px-6 py-4 text-nowrap">{info?.FullName}</td>
                  <td className="px-6 py-4 text-nowrap">{edit?.UserName}</td>
                  <td className="px-6 py-4 text-nowrap">
                    <button className="px-3 py-1 flex items-center gap-2 border rounded">
                      <FaEdit /> Edit
                    </button>
                  </td>
                </tr>
              );
          })
        : ""}
    </>
  );
}
