import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../components/BreadCrum";
import { MainContext } from "../contexts/Main";
import ManageTable from "../components/ManageTable";

export default function User() {
  const { fetchDatabyParendId, isLogin, admin_stages, search } =
    useContext(MainContext);
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchDatabyParendId().then(({ data }) => {
      const newData = data.filter((data) => {
        if (data.RoleId == 8) {
          return true;
        }
      });
      setData(newData.reverse());
    });
  }, [search]);
  const breadCrum = {
    topName: "User",
    lastName: "User",
  };
  console.log("Search in User.jsx: ",search)

  console.log("Actual Data : ",data)
  return (
    <div className="w-full min-h-[100vh]">
      <BreadCrum breadCrum={breadCrum} />
      {(
        <ManageTable
          data={data}
          create={isLogin?.roleId + 1 == admin_stages[7]?.roleId && "US"}
        />
      )}
    </div>
  );
}
