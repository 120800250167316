import React, { useContext, useEffect, useState } from 'react'
import { AiFillTool } from 'react-icons/ai';
import { FaAngleUp } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import BreadCrum from '../../components/BreadCrum';
import { MainContext } from '../../contexts/Main';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import { IoMdTime } from 'react-icons/io';

export default function CashLedger() {
    const { edit, apiBaseUrl, apiAdminsUrl } = useContext(MainContext);
    const [dataByUserId, setDataByUserId] = useState([]);
    const [date, setDate] = useState({
      from: new Date(new Date().setDate(new Date().getDate() - 2))
        .toISOString()
        .slice(0, 10),
      to: new Date().toISOString().slice(0, 10),
    });
  
    const breadCrum = {
      topName: "Agent",
      lastName: edit?.UserName + " (" + edit?.FullName + ")",
      finalName: "Ledger",
    };


    const fetchAllData = () => {
      if (edit?.Id != undefined && date.from != undefined && date.to != undefined) {
        axios
          .get(
            `${apiBaseUrl + apiAdminsUrl}/getUserProfitLoss/${edit.Id}?from=${
              date.from
            }&to=${date.to}`
          )
          .then((success) => {
            if (success.data.status) {
              console.log("success in case ladger: ", success);
              setDataByUserId(success.data.result);
            } else {
              console.log("success: ", success);
            }
          })
          .catch((err) => {
            console.log("error: ", err);
          });
      }
    };

    useEffect(fetchAllData,[edit]);

    console.log("Data: ",dataByUserId);

    return (
      <div className="w-full bg-slate-100 min-h-[100vh]">
      <div className="px-4 bg-white py-2 border-t-[1px] border-b-[1px] my-2">
        <h2 className="lg:text-2xl text-lg font-sans text-gray-500 uppercase">
          {breadCrum?.topName}
        </h2>
        <div className="">
          <Link to={"/dashboard"}>
            <span className="text-sm font-semibold text-gray-500">
              Dashboard
            </span>
          </Link>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            <Link to={'/user-dashboard/'+edit?.UserName}>{breadCrum?.lastName}</Link>
          </span>
          {breadCrum.finalName && (
            <>
              <span className="text-gray-400 px-2">/</span>
              <span className="text-sm font-semibold text-black uppercase">
                {breadCrum?.finalName}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 p-2 lg:flex gap-2">
        <input
          value={date.from}
          max={date.to}
          onChange={(e) => {
            setDate({ ...date, from: e.target.value });
          }}
          className="px-2 lg:w-[200px] py-1 text-gray-500 text-[14px] border border-black"
          type="date"
          name=""
          id=""
        />
        <input
          value={date.to}
          min={date.from}
          onChange={(e) => {
            setDate({ ...date, to: e.target.value });
          }}
          className="px-2 lg:w-[200px] py-1 text-gray-500 text-[14px] border border-black"
          type="date"
          name=""
          id=""
        />
        <button
          onClick={fetchAllData}
          className="bg-[#1fabb5] col-span-2 sm:col-span-1 text-white py-1 px-2 rounded text-[14px]"
        >
          Search
        </button>
      </div>
        <div className="bg-white my-3">
          <div className="flex justify-between p-2 items-center">
            <h2 className="text-gray-600 font-semibold text-lg">Client Ledger</h2>
            <div className="flex text-[16px]">
              <FaAngleUp className="text-gray-400" />
              <AiFillTool className="text-gray-400" />
              <IoClose className="text-gray-400 " />
            </div>
          </div>
          <div className="relative overflow-x-auto p-2 shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs border-b-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    DATE/TIME
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Collection Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    DEBIT
                  </th>
                  <th scope="col" className="px-6 py-3">
                    CREDIT
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Note
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  dataByUserId.length != 0 && 
                  dataByUserId.map((item, unique)=>{
                    return(
                      <tr key={unique} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className="px-6 py-4 flex items-center gap-2"><IoMdTime className="font-bold text-black" /><Moment className="font-semibold" format='MMM D,'>{item?.Date}</Moment><Moment format=' HH:mm:ss a'>{item?.Date}</Moment></td>
                        <td className="px-6 py-4">{item.Withdraw == 0 ? "Cash Recieved" : "Cash Paid"}</td>
                        <td className="px-6 py-4">{item.Withdraw ? item.Withdraw : 0}</td>
                        <td className="px-6 py-4">{item.Deposit ? item.Deposit : 0}</td>
                        <td className="px-6 py-4">-</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }