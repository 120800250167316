import React from 'react'
import BreadCrum from '../components/BreadCrum'

export default function SearchUser() {
  const breadCrum = {
    topName: "DASHBOARD",
    lastName: "Search User"
  }
  return (
    <div className='w-full min-h-[100vh] bg-gray-100 '>
        <BreadCrum breadCrum={breadCrum}/>
        <div className="userID w-full bg-white flex gap-2 p-4 shadow my-5">
          <input type="text" className='w-[35%] outline-green-600 border py-1 px-2' placeholder='Enter User Id' />
          <button className='py-1 px-6 rounded text-white bg-[#1fabb5]'>Submit</button>
        </div>
        <div className='p-4'>
        <div className="grid bg-white grid-cols-2 border mt-10 border-gray-400">
            <div className=' border-r border-gray-400 search_user'>
              <div className=' border-b text-[12px] font-semibold py-2 px-2 border-gray-400'>Admin</div>
              <div className=' border-b text-[12px] font-semibold py-2 px-2 border-gray-400'>SC</div>
              <div className=' border-b text-[12px] font-semibold py-2 px-2 border-gray-400'>SST</div>
              <div className=' border-b text-[12px] font-semibold py-2 px-2 border-gray-400'>SS</div>
              <div className=' border-b text-[12px] font-semibold py-2 px-2 border-gray-400'>SA</div>
              <div className='text-[12px] font-semibold py-2 px-2'>Punter</div>
            </div>
            <div className=' search_user'>
              <div className=' border-b text-[12px] font-semibold py-2 px-2 border-gray-400'>-</div>
              <div className=' border-b text-[12px] font-semibold py-2 px-2 border-gray-400'>-</div>
              <div className=' border-b text-[12px] font-semibold py-2 px-2 border-gray-400'>-</div>
              <div className=' border-b text-[12px] font-semibold py-2 px-2 border-gray-400'>-</div>
              <div className=' border-b text-[12px] font-semibold py-2 px-2 border-gray-400'>-</div>
              <div className='text-[12px] font-semibold py-2 px-2'>-</div>
            </div>
        </div>
        </div>

    </div>
  )
}
