import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MainContext } from "../../contexts/Main";
import DashboardInfo from "../../components/DashboardInfo";
import { MdAccessTime } from "react-icons/md";

export default function Betslips2() {
  const { prevPath } = useContext(MainContext);
  const [tabs, setTabs] = useState(1);
  const titleAndName = [
    {
      title: "Total Bets",
      value: "2334",
    },
    {
      title: "Settle bets",
      value: "2334",
    },
    {
      title: "Unsettled bets",
      value: "0",
    },
    {
      title: "Reverted Bets",
      value: "0",
    },
  ];
  return (
    <div className="w-full bg-gray-100 mb-5 min-h-[100vh]">
      <div className="py-2 px-4 bg-white">
        <h2 className="text-gray-600 text-2xl mb-2 ">{prevPath?.title}</h2>
        <div className="flex gap-2 flex-wrap text-sm">
          <span>
            <Link to={"/dashboard"}>Dashboard</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={"/liveGames/1"}>Matches</Link>
          </span>
          <span className="font-semibold">/</span>
          <span>
            <Link to={prevPath?.pathname}>{prevPath?.eventName}</Link>
          </span>
          <span className="font-semibold">/</span>
          <span className="font-semibold">{prevPath?.title}</span>
        </div>
      </div>

      <div className="grid mt-4 grid-cols-1 bg-white sm:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-x-6 gap-y-3">
        {titleAndName.map((data, index) => {
          return <DashboardInfo key={index} data={data} />;
        })}
      </div>

      <div className="flex flex-wrap gap-2 p-4">
        <select
          className="px-2 py-1 w-[40%] min-w-[200px] rounded-lg border border-gray-100 outline-none"
          name=""
          id=""
        >
          <option value="0">All User</option>
          <option value="2"></option>
          <option value="3"></option>
        </select>
      </div>
      <div className="p-2">
        <div className="flex relative top-[1px]">
          <div onClick={()=>setTabs(1)}
            style={{ borderRadius: "6px 6px 0px 0px" }}
            className={`w-auto px-3 py-2 cursor-pointer ${tabs==1?"bg-white border border-b-0":"bg-none text-slate-400"}  text-[14px] font-[600] `}
          >
            Match Winer
          </div>
          <div style={{ borderRadius: "6px 6px 0px 0px" }} onClick={()=>setTabs(2)} className={`w-auto px-3 py-2 cursor-pointer ${tabs!=1?"bg-white border border-b-0":"bg-none text-slate-400"} text-[14px] font-[600]`}>
            Who will win the Toss
          </div>
        </div>
        <div className="px-2 py-4 bg-white border">
          <div>{tabs==1 ? "Match Winner" : "Who will win the Toss"}</div>
          <div className="grid mt-4 grid-cols-1 bg-white sm:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-x-6 gap-y-3">
            {titleAndName.map((data, index) => {
              return <DashboardInfo key={index} data={data} />;
            })}
          </div>

          {/* <div className="flex flex-wrap gap-2 p-4">
            <select
              className="px-2 py-1 w-[40%] min-w-[200px] rounded-lg border border-gray-100 outline-none"
              name=""
              id=""
            >
              <option value="0">All User</option>
              <option value="2"></option>
              <option value="3"></option>
            </select>
            <select
              className="px-2 py-1 w-[40%] min-w-[200px] rounded-lg border border-gray-100 outline-none"
              name=""
              id=""
            >
              <option value="0">Match Winner</option>
              <option value="2"></option>
              <option value="3"></option>
            </select>
          </div> */}

          {/* Market Position */}
          <div className="w-full bg-white">
            <div className="p-2 border-gray-200 border-t-[0.2em] border-b">
              <h2 className="text-md font-[600]">Market Position</h2>
            </div>
            <div className="p-4 ">
              {/* Heading */}
              <div className="flex border-gray-400 border-b py-2 px-1">
                <div className="text-md font-[600] uppercase w-full">
                  Runner
                </div>
                <div className="text-md font-[600] uppercase w-full">
                  Position
                </div>
              </div>
              {/* Data.. */}
              <div className="flex itemm border-gray-300 border-b py-2 px-1">
                <div className="text-sm w-full">England</div>
                <div className="text-sm w-full">-43099.93</div>
              </div>
              <div className="flex itemm border-gray-300 border-b py-2 px-1">
                <div className="text-sm w-full">West Indies</div>
                <div className="text-sm w-full">-1106839.63</div>
              </div>
            </div>
          </div>

          {/* Bet Slips */}
          <div className="w-full mt-2 bg-white">
            <div className="p-2 border-gray-200 border-t-[0.2em] border-b">
              <h2 className="text-md font-[600]">Bet Slips</h2>
            </div>

            {/* table */}
            <div className="p-4">
              <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th
                        colSpan={9}
                        scope="col"
                        className="p-2 text-nowrap border"
                      >
                        Bet
                      </th>
                      <th
                        colSpan={4}
                        scope="col"
                        className="p-2 text-nowrap border"
                      >
                        My Share / Position
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        Settlement
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" className="p-2 text-nowrap border">
                        Date
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        Market Title
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        RATE
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        AMOUNT
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        MODE
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        Runner Name
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        User
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        England
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        West Indies
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        My Share
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        England
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        West Indies
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        Status
                      </th>
                      <th scope="col" className="p-2 text-nowrap border">
                        Plus / Minus
                      </th>
                    </tr>
                  </thead>
                  <tbody className="search_user">
                    <tr className="bg-white border-b align-top dark:bg-gray-800 dark:border-gray-700">
                      <td className="p-2 border text-sm">
                        <span>
                          <MdAccessTime className="inline mb-[0.2rem]" /> Thu
                          Jun 20 05:55:20 IST 2024
                        </span>
                      </td>
                      <td className="p-2 border text-sm">Match Winner</td>
                      <td className="p-2 border text-sm">0.91</td>
                      <td className="p-2 border text-sm">100</td>
                      <td className="p-2 border text-sm">KHAI</td>
                      <td className="p-2 border text-sm">England</td>
                      <td className="p-2 border text-sm">SP354708</td>
                      <td className="p-2 border text-sm"> -91.0</td>
                      <td className="p-2 border text-sm">100.0</td>
                      <td className="p-2 border text-sm">10.0%</td>
                      <td className="p-2 border text-sm">-9.1</td>
                      <td className="p-2 border text-sm"> 10.0</td>
                      <td className="p-2 border text-sm">
                        <button className="text-[12px] px-2 text-white rounded-lg bg-[#1fabb5]">
                          Settle
                        </button>
                      </td>
                      <td className="p-2 border text-sm">-9.1</td>
                    </tr>
                    <tr className="bg-white border-b align-top dark:bg-gray-800 dark:border-gray-700">
                      <td className="p-2 border text-sm">
                        <span>
                          <MdAccessTime className="inline mb-[0.2rem]" /> Thu
                          Jun 20 05:55:20 IST 2024
                        </span>
                      </td>
                      <td className="p-2 border text-sm">Match Winner</td>
                      <td className="p-2 border text-sm">0.91</td>
                      <td className="p-2 border text-sm">100</td>
                      <td className="p-2 border text-sm">KHAI</td>
                      <td className="p-2 border text-sm">England</td>
                      <td className="p-2 border text-sm">SP354708</td>
                      <td className="p-2 border text-sm"> -91.0</td>
                      <td className="p-2 border text-sm">100.0</td>
                      <td className="p-2 border text-sm">10.0%</td>
                      <td className="p-2 border text-sm">-9.1</td>
                      <td className="p-2 border text-sm"> 10.0</td>
                      <td className="p-2 border text-sm">
                        <button className="text-[12px] px-2 text-white rounded-lg bg-[#1fabb5]">
                          Settle
                        </button>
                      </td>
                      <td className="p-2 border text-sm">-9.1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
