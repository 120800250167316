import React, { useContext } from 'react'
import DashboardInfo from '../components/DashboardInfo';
import { RxCross2 } from "react-icons/rx";
import { MainContext } from '../contexts/Main';

export default function DashBoard() {
  // login notify
  const {setLoginNotify, loginNotify, isLogin, findAdminStage} = useContext(MainContext);

  const titleAndName = [
    {
      title: "MY USERNAME",
      value: isLogin==false?"":isLogin.userName,
      copy: "admin"
    },
    {
      title: "MY LEVEL",
      value: isLogin==false?"":findAdminStage?.roleName
    },{
      title: "MY FIX LIMIT",
      value: "6,984,102,959"
    },{
      title: "Company Contact",
      value: "Admin"
    },{
      title: "Maximum My Share",
      value: "100%"
    },{
      title: "Minimum Company Share",
      value: "0%"
    },{
      title: "Match Commission",
      value: "0"
    },{
      title: "Session Commission",
      value: "0"
    }
  ];
  return (
    <div className='w-full p-4 '>
      <hr />
      <div style={{boxShadow: "0px 0px 5px 0px gray"}} className="breadCurm rounded-md flex gap-2 px-2 pb-1 flex-col text-gray-500">
        <h2 className='font-semibold text-2xl'>Home</h2>
        <span className='text-black text-[14px]'>Home</span>
      </div>
      <hr />
      <div className={`bg-[#dff0d8] text-green-800 py-3 px-6 rounded text-sm mt-4 relative ${loginNotify?"":"hidden"}`}>Signed in successfully
         <RxCross2 onClick={()=>{setLoginNotify(false)}} className='absolute top-0 right-6 translate-y-4 cursor-pointer' />
      </div>
      <div className="grid mt-4 grid-cols-1 xl:grid-cols-3 gap-x-6 gap-y-3">
        {
            titleAndName.map((data,index)=>{
              return(
                  <DashboardInfo key={index} data={data}/>
              )
            })
        }
      </div>

      <div className={`bg-[#ebccd1] mb-4 text-[#a94442] py-4 px-6 rounded text-sm mt-4 relative `}>Sports Sharing Disabled.
      </div>
      <hr />
      <div className={`bg-[#ebccd1] mb-20 text-[#a94442] py-4 px-6 rounded text-sm mt-4 relative `}>Sports Sharing Disabled.
      </div>
    </div>
  )
}
