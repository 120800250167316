import React, { useContext, useEffect, useMemo, useState } from "react";
import BreadCrum from "../../components/BreadCrum";
import { MainContext } from "../../contexts/Main";
import { FaEdit } from "react-icons/fa";
import axios from "axios";
import AgentTables from "../../components/AgentTables";
import { Link } from "react-router-dom";

export default function DirectAgents() {
  const { edit, admin_stages, apiBaseUrl, apiAdminsUrl } =
    useContext(MainContext);
  const [isShowTable, setIsShowTable] = useState([]);

  useEffect(() => {
    if (edit != null) {
      const isShow = admin_stages.filter((data) => {
        return edit.RoleId < data.roleId;
      });
      setIsShowTable(isShow);
    }
  }, [edit]);

  const breadCrum = {
    topName: "Agent",
    lastName: edit?.UserName + " (" + edit?.FullName + ")",
    finalName: " Direct Agents",
  };

  return (
    <div className="w-full bg-slate-100 min-h-[100vh]">
      <div className="px-4 bg-white py-2 border-t-[1px] border-b-[1px] my-2">
        <h2 className="lg:text-2xl text-lg font-sans text-gray-500 uppercase">
          {breadCrum?.topName}
        </h2>
        <div className="">
          <Link to={"/dashboard"}>
            <span className="text-sm font-semibold text-gray-500">
              Dashboard
            </span>
          </Link>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            <Link to={"/user-dashboard/" + edit?.UserName}>
              {breadCrum?.lastName}
            </Link>
          </span>
          {breadCrum.finalName && (
            <>
              <span className="text-gray-400 px-2">/</span>
              <span className="text-sm font-semibold text-black uppercase">
                {breadCrum?.finalName}
              </span>
            </>
          )}
        </div>
      </div>
      {isShowTable.length != 0
        ? isShowTable.map((data, index) => {
            return (
              <div key={index} className="bg-white my-3">
                <div className="flex justify-between p-2 items-center">
                  <h2 className="text-gray-600 font-semibold text-lg">
                    All {data?.roleName}
                  </h2>
                </div>
                <div className="relative overflow-x-auto p-2 shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs border-b-2 border-t-2 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-nowrap">
                          ID
                        </th>
                        <th scope="col" className="px-6 py-3 text-nowrap">
                          User Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-nowrap">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-nowrap">
                          Agent Under
                        </th>
                        <th scope="col" className="px-6 py-3 text-nowrap">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <AgentTables roleId={data.roleId} />
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
}
