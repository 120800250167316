import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../components/BreadCrum";
import { RiSettings3Fill } from "react-icons/ri";
import { FaCaretDown } from "react-icons/fa";
import { MainContext } from "../contexts/Main";
import CommissionTable from "../components/CommissionTable";

export default function CommisionLimits() {
  const [fetch, setFetch] = useState(true);
  const { fetchDatabyParendId, admin_stages, isLogin, setSpiner } =
    useContext(MainContext);
  const [allData, setAllData] = useState([]);
  const [table, setTable] = useState([]);
  const fetchAllData = () => {
    setSpiner(true);
    fetchDatabyParendId()
      .then((success) => {
        setAllData(success.data);
        setInterval(() => {
          setSpiner(false);
        }, 1000);
      })
      .catch((err) => { 
        console.log("comm: ", err);
        setInterval(() => {
          setSpiner(false);
        }, 1000);
      });
    const filterTable = admin_stages.filter((d) => {
      return d.roleId > isLogin.roleId;
    });
    setTable(filterTable);
  };
  useEffect(fetchAllData, [isLogin, fetch]);
  const breadCrum = {
    topName: "CLIENTS",
    lastName: "Commission & Limits",
    btnName: "new User",
  };
  return (
    <div className="w-full min-h-[100vh] mb-8">
      <BreadCrum breadCrum={breadCrum} />
      {table.length != 0
        ? table.map((dataTable, indexTable) => {
            return (
              <CommissionTable setFetch={setFetch} fetch={fetch} dataTable={dataTable} allData={allData}/>
            );
          })
        : ""}
    </div>
  );
}
