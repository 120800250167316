import React, { useContext, useEffect, useState } from "react";
import BreadCrum from "../../components/BreadCrum";
import { useLocation } from "react-router-dom";
import { MainContext } from "../../contexts/Main";
import moment from "moment";
import { BsExclamationCircle } from "react-icons/bs";
import axios from "axios";
import { IoIosClose } from "react-icons/io";
function InactiveMatch() {
  const [inActive, setInActive] = useState([]);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const { getAllInActiveMatch, apiSportsUrl, apiBaseUrl, notify } =
    useContext(MainContext);

  const { state } = useLocation();
  //   breadCrum
  const breadCrum = {
    topName: "IN-Active Match List",
    lastName: "Settings Dashboard",
    prevPath: state,
    finalName: "IN-Active Match List",
  };

  // search management--
  const [search, setSearch] = useState("");

  const [popup, setpopup] = useState(null);
  const [updateDate, setUpdateDate] = useState({
    id: undefined,
    date: undefined,
    gameId: undefined,
  });

  //   Table Heading
  const tableHeading = [
    "Sport",
    "Match",
    "MatchId",
    "MarketId",
    "Date",
    "Market",
    "Status",
    "Action",
    "Show Bets",
    "Update Date",
  ];

  const fetchInActiveData = () => {
    getAllInActiveMatch(page, search)
      .then((success) => {
        console.log("Success of Inactive: ", success);
        if (success.status) {
          setInActive(success.result);
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  useEffect(fetchInActiveData, [page, search]);

  console.log("All Inactives: ", inActive);

  const statusUpdater = (item) => {
    console.log("item: ", item);
    if (item) {
      axios
        .patch(
          `${apiBaseUrl + apiSportsUrl}/match/update-active-status/${item.gameId}/${item.ActiveMatch == 1 ? 0 : 1}/${item.Status == 1 ? 0 : 1}`
        )
        .then((ok) => {
          console.log("Success in Inactive: ", ok);
          fetchInActiveData();
        })
        .catch((err) => {
          console.log("error: ", err);
        });
    }
  };

  return (
    <div className="w-full min-h-[100vh] bg-slate-100">
      <BreadCrum breadCrum={breadCrum} />
      <div className="w-full max-w-[300px]">
        <input
          defaultValue={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          type="search"
          className="w-full p-2 border border-[#23c6c8] rounded-lg ms-2 mt-1 outline-none"
          placeholder="Search Match.."
        />
      </div>

      <div className="w-full overflow-auto bg-white my-4">
        {tableHeading.length != 0 && (
          <table>
            <thead>
              <tr>
                {tableHeading.map((item) => {
                  return (
                    <th className="border px-6 py-1 text-nowrap">{item}</th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {inActive.length != 0
                ? inActive.map((item) => {
                    return (
                      <tr className="align-top">
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {item.eid == "1"
                            ? "Soccer"
                            : item.eid == "2"
                            ? "Tennis"
                            : item.eid == "4"
                            ? "Cricket"
                            : ""}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {item?.eventName}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {item?.gameId}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {item?.marketId}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {moment(item?.eventDate).format(
                            "ddd MMM DD HH:mm:ss [GMT]Z YYYY"
                          )}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          {"MatchOdds"}
                        </td>
                        <td className="border px-6 py-2 text-[14px] tracking-wide font-[500]">
                          false
                        </td>
                        <td className="border px-6 py-2 text-nowrap">
                          <div className="flex flex-col gap-2">
                            <button
                              onClick={() => {
                                setpopup(item?.SrNo);
                              }}
                              className="bg-[#23c6c8] outline-none text-white px-2 py-1 rounded-lg  "
                            >
                              Update Status
                              <div
                                className={`fixed  flex justify-center items-center z-[9999] ${
                                  popup == item?.SrNo ? "visible" : "invisible"
                                } top-0 left-0 w-full h-[100vh] bg-[rgba(0,0,0,0.6)]`}
                              >
                                <div className="lg:w-[40vw]  sm:w-[50vw] w-[80vw]  p-6 px-10 rounded-lg flex flex-col gap-3 justify-center items-center bg-white popup duration-150">
                                  <BsExclamationCircle className="text-8xl my-2 font-[100] text-[#f8bb86]" />
                                  <h2 className="font-[500] text-black text-[30px]">
                                    Are You Sure?
                                  </h2>
                                  <p className="text-slate-500 text-lg font-[500]">
                                    You Want to active this Bet!
                                  </p>
                                  <div className="flex justify-center my-5 gap-2">
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        statusUpdater(item);
                                        setpopup(null);
                                      }}
                                      className="px-5 text-lg py-1 rounded bg-[#3085d6]"
                                    >
                                      Yes
                                    </button>
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setpopup(null);
                                      }}
                                      className="px-3 text-lg py-1 rounded bg-[#dd3333]"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </button>

                            <button className="bg-[#23c6c8] outline-none text-white px-2 py-1 rounded-lg  ">
                              Rollback PDC
                            </button>
                          </div>
                        </td>
                        <td className="border px-6 py-2 text-nowrap">
                          <button className="bg-[#23c6c8] outline-none text-white px-2 py-1 rounded-lg  ">
                            Show Bets
                          </button>
                        </td>
                        <td className="border px-6 py-2 text-nowrap">
                          <button
                            onClick={() =>
                              setUpdateDate({
                                id: item.SrNo,
                                date: moment(item.eventDate).format('YYYY-MM-DD HH:mm:ss'),
                                gameId: item.gameId,
                              })
                            } className="bg-[#23c6c8] outline-none text-white px-2 py-1 rounded-lg  ">
                            Update Game Date
                          </button>
                        </td>
                        <div
                          className={`fixed bg-[rgba(0,0,0,0.6)] z-[99999] top-0 left-0 w-[100vw] min-h-[100vh] flex justify-center items-start scale-y-0 opacity-0 duration-100 origin-top ${updateDate.id == item.SrNo?"scale-y-100 opacity-100":""}`}
                        >
                          <div className="w-[60vw] overflow-hidden mt-4 bg-white rounded-lg h-auto border">
                            <div className="flex p-2 lg:p-3  justify-between items-center border- border-b-1">
                              <h2 className="text-[14px] lg:text-[16px] text-gray-700 font-[500]">{item?.eventName}</h2>
                              <IoIosClose onClick={() =>
                              setUpdateDate({
                                id: undefined,
                                date: undefined,
                                gameId: undefined,
                              })
                            } className="text-2xl cursor-pointer lg:text-3xl" />
                            </div>
                            <div className="flex justify-center items-center p-6 border border-b-2">
                              <input className="text-black w-[80%] px-2 py-1 outline-none border rounded" type="text" onChange={(e)=>setUpdateDate({...updateDate,date: e.target.value})} value={updateDate.date} name="" id="" />
                            </div>
                            <div className="border-t-1 p-3 flex justify-end">
                              <button onClick={()=>{
                                axios.patch(`${apiBaseUrl+apiSportsUrl}/match/updateDateTime/${updateDate.gameId}/${updateDate.date}`).then((success)=>{
                                  console.log("Success Date update: ", success);
                                  notify(success.data.message, success.data.status?"success":"error");
                                  if(success.data.status){
                                    fetchInActiveData();
                                    setUpdateDate({
                                      id: undefined,
                                      date: undefined,
                                      gameId: undefined,
                                    })
                                  }
                                }).catch((err)=>{
                                  console.log("error date update: ",err);
                                })
                              }} className="bg-[#1c84c6] mx-1 text-white px-3 py-1 rounded">Update</button><button onClick={() =>
                              setUpdateDate({
                                id: undefined,
                                date: undefined,
                                gameId: undefined,
                              })
                            } className="mx-1 bg-[#c2c2c2] rounded px-3 py-1 text-white">Close</button>
                            </div>
                          </div>
                        </div>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default InactiveMatch;
