import React, { useContext, useState } from "react";
import BreadCrum from "../components/BreadCrum";
import { MainContext } from "../contexts/Main";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function PasswordManager() {
  const navigate = useNavigate();
  const { isLogin, notify, apiBaseUrl, apiAdminsUrl } = useContext(MainContext);
  const [changePass, setChangePass] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  console.log(isLogin);
  const saveChanges = () => {
    if (isLogin != false) {
      let id = isLogin.userId;
      let { old_password, new_password, confirm_password } = changePass;
      if (new_password != "" && old_password != "" && confirm_password != "") {
        if (new_password.length >= 5) {
          if (new_password == confirm_password) {
            if (old_password != new_password) {
              axios
                .put(`${apiBaseUrl + apiAdminsUrl}/change-password/${id}`, {
                  old_password,
                  new_password,
                })
                .then((success) => {
                  if (success.data.status) {
                    notify(success.data.message, "success");
                    setChangePass({
                      old_password: "",
                      new_password: "",
                      confirm_password: "",
                    });
                    navigate("/dashboard");
                  } else {
                    notify(success.data.message, "error");
                  }
                })
                .catch((err) => {
                  notify("error", "error");
                  console.log(err);
                });
            } else {
              notify("Password Already Used Once", "error");
            }
          } else {
            notify("Both password must match", "error");
          }
        } else{
          notify("The length of the input exceeds 5 characters.")
        }
      } else {
        notify("Enter password", "error");
      }
    } else {
      notify("User not logged in", "error");
    }
  };

  const breadCrum = {
    topName: "admin",
    lastName: "Manage Password",
  };
  return (
    <div className="w-full min-h-[100vh]  bg-white text-black">
      <BreadCrum breadCrum={breadCrum} />
      <div className="passBox ">
        <div className="border font-semibold p-2 my-2">Change Password</div>
        <div className="py-2 px-6 text-sm">
          <div className="flex my-2 justify-center gap-2 md:gap-3 items-center font-semibold">
            <span className="w-[150px]">OLD PASSWORD</span>
            <input
              onChange={(e) => {
                let value = e.target.value;
                setChangePass({
                  ...changePass,
                  old_password: value.replace(" ", ""),
                });
              }}
              type="password"
              placeholder="OLD PASSWORD"
              value={changePass.old_password}
              className="outline-green-600 text-[12px] font-normal border-gray-400 w-[80%] border-2 rounded py-2 px-4"
              name=""
              id=""
            />
          </div>
          <div className="flex my-2 justify-center gap-2 md:gap-3 items-center font-semibold">
            <span className="w-[150px]">NEW PASSWORD</span>
            <input
              onChange={(e) => {
                let value = e.target.value;
                setChangePass({
                  ...changePass,
                  new_password: value.replace(" ", ""),
                });
              }}
              type="password"
              placeholder="NEW PASSWORD"
              value={changePass.new_password}
              className="outline-green-600 text-[12px] font-normal border-gray-400 w-[80%] border-2 rounded py-2 px-4"
              name=""
              id=""
            />
          </div>
          <div className="flex my-2 justify-center gap-2 md:gap-3 items-center font-semibold">
            <span className="w-[150px]">CONFIRM PASSWORD</span>
            <input
              onChange={(e) => {
                let value = e.target.value;
                setChangePass({
                  ...changePass,
                  confirm_password: value.replace(" ", ""),
                });
              }}
              type="password"
              placeholder="CONFIRM PASSWORD"
              value={changePass.confirm_password}
              className="outline-green-600 text-[12px] font-normal border-gray-400 w-[80%] border-2 rounded py-2 px-4"
              name=""
              id=""
            />
          </div>
          <div className="flex justify-center w-[45%]">
            <button
              onClick={saveChanges}
              className="py-2 px-3 rounded-lg bg-[#1fabb5] text-white my-5"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
