import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BreadCrum from "../../components/BreadCrum";
import { MainContext } from "../../contexts/Main";
import BetFairMatches from "./BetFairMatches";

function BetFair() {
  const [betFair, setBetFair] = useState([]);
  const [data, setData] = useState([]);
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { getAllBetFair, setBetFairInfo, setSpiner } = useContext(MainContext);
  const breadCrum = {
    topName: "Matches",
    lastName: "Settings Dashboard",
    prevPath: "/Settings",
    finalName: "Betfair Matches",
  };

  const fetchBetFair = () => {
    setSpiner(true);
    getAllBetFair()
      .then((success) => {
        console.log("Success: ", success);
        if (success.status) {
          setBetFair(success.result);
          setSpiner(false);
        } else {
          setBetFair([]);
          setSpiner(false);
        }
      })
      .catch((err) => {
        console.log("error: ", err);
        setSpiner(false);
      });
  };

  useEffect(fetchBetFair, []);

  console.log("BetFair Data : ", betFair);


  useEffect(()=>{
    if(betFair.length!=0){
      setData(betFair.slice((page-1)*50,page*50));
    }
  },[betFair,page]);


  return (
    <div className="w-full min-h-[100vh] bg-slate-100">
      <BreadCrum breadCrum={breadCrum} />

      <div className="w-full overflow-auto my-2 bg-white">
        <table className="w-full">
          <thead className="w-full">
            <tr className="w-full border-b-2 border-slate-300 px-6 py-3">
            <td className="py-2 px-4 text-sm font-[500] text-gray-500">SrNo</td>
              <td className="py-2 px-4 text-sm font-[500] text-gray-500">Id</td>
              <td className="py-2 px-4 text-sm font-[500] text-gray-500">
                Sport
              </td>
              <td className="py-2 px-4 text-sm font-[500] text-gray-500">
                Series Name
              </td>
              <td className="py-2 px-4 text-sm font-[500] text-gray-500">
                Market Count
              </td>
              <td className="py-2 px-4 text-sm font-[500] text-gray-500">
                Region
              </td>
              <td className="py-2 px-4 text-sm font-[500] text-gray-500">
                Action
              </td>
            </tr>
          </thead>
          <tbody>
            {data.length != 0
              ? data.map((item, index) => {
                  return (
                    <tr
                      key={item.Id}
                      className="w-full border-b-2 border-slate-300 px-6 py-3"
                    >
                      <td className="py-2 px-4">{(index+1)+(page-1)*50}</td>
                      <td className="py-2 px-4">{item?.seriesId}</td>
                      <td className="py-2 px-4">
                        {item.sportId == "1"
                          ? "Soccer"
                          : item.sportId == "2"
                          ? "Tennis"
                          : item.sportId == "4"
                          ? "Cricket"
                          : ""}
                      </td>
                      <td className="py-2 px-4">{item?.seriesName}</td>
                      <td className="py-2 px-4">{item?.marketCount}</td>
                      <td className="py-2 px-4">{item?.region}</td>
                      <td className="py-2 px-4">
                        <button className="px-3 py-1 text-nowrap rounded-md text-white bg-[#23c6c8]">
                          <Link to={"/betfairMatches/" + item.seriesId}>
                            Event List
                          </Link>
                        </button>
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between py-2 px-4">
        <div className="
        text-gray-600 text-[16px]">Showing {data.length!=0?((page-1)*50)+1:0} to {data.length!=0?(((page-1)*50)+data.length):0} of entries {betFair.length}</div>
        <div className="border border-gray-400 shadow rounded-lg overflow-hidden ">
          <button onClick={()=>{if(page>1){setPage(page-1)}}} className="px-3  text-black py-1">Previous</button>
          <button className="px-3 py-1 border-x-2 border-gray-400">{page}</button>
          <button onClick={()=>{
            if((((page-1)*50)+data.length) == betFair.length){
              return;
            }
            setPage(page+1)
          }} className="px-3  text-black py-1">Next</button>
        </div>
      </div>
    </div>
  );
}

export default BetFair;
