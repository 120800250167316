import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { RiSettings3Fill } from "react-icons/ri";
import { MainContext } from "../contexts/Main";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function CommissionTable({
  dataTable,
  allData,
  setFetch,
  fetch,
}) {
  const [search, setSearch] = useState("");
  const [balancePopUp, setBalancePopUp] = useState(null);
  const [depositPopUp, setDepositPopUp] = useState(null);
  const [exposPopUp, setExposPopUp] = useState(null);
  const [withdrowPopUp, setWithdrowPopUp] = useState(null);
  const [clientSettings, setClientSettings] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [exposure, setExposure] = useState([]);
  const {
    apiBaseUrl,
    apiAdminsUrl,
    isLogin,
    setSpiner,
    notify,
    fetchExposure,
    matchOddsNegativeValue,
    bookMakerNegativeValue,
    fancyNegativeValue,
  } = useContext(MainContext);

  useEffect(() => {
    exposPopUp != null
      ? setExposure([
          ...matchOddsNegativeValue,
          ...bookMakerNegativeValue,
          ...fancyNegativeValue,
        ])
      : setExposure([]);
  }, [
    matchOddsNegativeValue,
    bookMakerNegativeValue,
    fancyNegativeValue,
    exposPopUp,
  ]);

  console.log("Exposuress: ", exposure);

  const [deposit, setDeposit] = useState({
    manage: isLogin?.userName,
    depositAmmount: 0,
  });
  //console.log("data: ", dataTable);

  const dataFiltering = () => {
    if (allData.length != undefined) {
      const newData = allData.filter((d) => {
        return d.RoleId == dataTable.roleId;
      });
      const newData1 = newData.filter((d) => {
        let name = JSON.stringify(d.FullName);
        let user = JSON.stringify(d.UserName);
        return (
          name.toLowerCase().includes(search.toLowerCase()) ||
          user.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredData(newData1);
    }
  };

  useEffect(dataFiltering, [allData, search]);

  const [balanceID, setBalanceID] = useState("");
  const [bal, setBal] = useState(0);

  useEffect(() => {
    if (balanceID != "" && balanceID != null) {
      axios
        .get(`${apiBaseUrl + apiAdminsUrl}/downlineByParentId/${balanceID}`)
        .then((ok) => {
          setBal(ok.data.result);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, [balanceID]);

  const depositHandler = () => {
    console.log("Deposit Handler", deposit)
    if (deposit.depositAmmount != 0) {
      if (deposit.type == "deposit") {
        if (deposit.depositAmmount <= deposit.currentAmmountP) {
          axios
            .post(`${apiBaseUrl + apiAdminsUrl}/user/deposit`, deposit)
            .then((success) => {
              setDeposit({
                manage: isLogin?.userName,
                depositAmmount: 0,
              });
              if (success.data.status) {
                notify(success.data.message, "success");
                setFetch(!fetch);
              }
              //console.log(success);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          notify("Insufficient balance", "error");
        }
      } else {
        if (deposit.depositAmmount <= deposit.currentAmmountU) {
          axios
            .post(`${apiBaseUrl + apiAdminsUrl}/user/deposit`, deposit)
            .then((success) => {
              setDeposit({
                manage: isLogin?.userName,
                depositAmmount: 0,
              });
              // if (success.data.status) {
              notify("success.data.message", "success");
              setFetch(!fetch);
              // }
              //console.log(success);
            })
            .catch((err) => {
              //console.log(err);
            });
        } else {
          notify("Insufficient balance", "error");
        }
      }
    } else {
      notify("Try again!!", "error");
    }
  };

  return (
    <div className="p-2 mt-4">
      <div className="top ps-2 text-lg font-bold pb-2">
        {dataTable?.roleName}
      </div>
      <hr />
      <input
        onKeyUp={(e) => {
          //console.log(e.target.value);
          setSearch(e.target.value);
        }}
        className="border outline-green-600 px-2 py-1 mt-4 text-sm"
        placeholder="Search SCId"
        type="text"
      />
      <div className="mytbl">
        <div className="overflow-x-auto pb-[70px] mt-5">
          <table className="w-full table-auto border-collapse border border-gray-50">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 font-normal px-4 py-2"></th>
                <th
                  colSpan={2}
                  className="border border-gray-300 font-normal px-4 py-2"
                >
                  Client Commission
                </th>
                <th
                  colSpan={2}
                  className="border border-gray-300 font-normal px-4 py-2"
                >
                  Client Limit
                </th>
                <th className="border border-gray-300 font-normal px-4 py-2"></th>
              </tr>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 font-normal px-4 py-2">
                  Client Name
                </th>
                <th className="border border-gray-300 font-normal px-4 py-2">
                  Match Comm.
                </th>
                <th className="border border-gray-300 font-normal px-4 py-2">
                  Ssn Comm.
                </th>
                <th className="border border-gray-300 font-normal px-4 py-2">
                  Current Limit
                </th>
                <th className="border border-gray-300 font-normal px-4 py-2">
                  {dataTable.roleId != 8 ? "Down Balance" : "Show Expo"}
                </th>
                <th className="border border-gray-300 font-normal px-4 py-2">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length != 0
                ? filteredData.map((data, index) => {
                    if (data.RoleId == dataTable.roleId) {
                      return (
                        <tr className="text-[14px]" key={index}>
                          <td className="border text-nowrap border-gray-300 px-4 py-2">
                            {`${data?.UserName} (${data?.FullName})`}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 max-w-[100px]">
                            <button className="w-full text-sm bg-gray-100 border">
                              {data?.MCommission}
                            </button>
                          </td>
                          <td className="border border-gray-300 px-4 py-2 max-w-[100px]">
                            <button className="w-full text-sm bg-gray-100 border">
                              {data?.SCommission}
                            </button>
                          </td>
                          <td className="border border-gray-300 px-4 py-2 ">
                            <button className="w-full font-semibold py-1 text-sm text-left px-4 bg-gray-50 border border-black rounded">
                              {data.ResultAmountU != null
                                ? data.ResultAmountU
                                : "0"}
                            </button>
                          </td>
                          <td className="border border-gray-300 px-4 py-2 flex justify-center ">
                            {dataTable.roleId != 8 ? (
                              <button
                                onClick={() => {
                                  setBalanceID(data?.Id);
                                  if (balancePopUp == data?.Id) {
                                    setBalancePopUp(null);
                                  } else {
                                    setBalancePopUp(data?.Id);
                                  }
                                }}
                                className="py-1 rounded-md outline-none px-3 bg-[#1c84c6] text-white"
                              >
                                Balance
                                <div
                                  onClick={(e) => e.stopPropagation()}
                                  className={`fixed p-4 flex justify-center items-center top-0 left-0 w-full min-h-screen z-[99999] backdrop-blur-sm  ${
                                    balancePopUp == data?.Id
                                      ? "visible"
                                      : "invisible"
                                  } bg-[rgba(0,0,0,0.6)]`}
                                >
                                  <div
                                    className={`bg-white max-w-[550px] shadow-lg rounded-md px-4 py-10 flex flex-col gap-10 ${
                                      balancePopUp == data?.Id ? "popup" : ""
                                    }`}
                                  >
                                    <div className="text-center p-4 flex flex-col gap-7">
                                      <h2 className="font-bold text-slate-600 text-3xl">
                                        Downline Balance Of - {data?.UserName}
                                      </h2>
                                      <p className="text-red-500 text-3xl font-bold">
                                        {bal != null ? bal : 0}
                                      </p>
                                    </div>
                                    <div>
                                      <button
                                        onClick={() => setBalancePopUp(null)}
                                        className="bg-blue-500 text-white px-5 rounded py-2"
                                      >
                                        OK
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            ) : (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  fetchExposure(data.Id);
                                  setExposPopUp(data.Id);
                                }}
                                className="py-1 rounded-md outline-none px-3 bg-[red] text-white"
                              >
                                Expos
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  className={`fixed p-4 flex flex-col justify-start items-center  top-0 left-0 w-full min-h-screen z-[99999] backdrop-blur-sm ${
                                    exposPopUp == data.Id
                                      ? "visible"
                                      : "invisible"
                                  } bg-[rgba(0,0,0,0.6)]`}
                                >
                                  <div className="w-full md:w-[700px]">
                                    <div className="px-2 flex justify-between items-center py-4 w-full bg-[#1FABB5] text-white border">
                                      <span className="text-nowrap text-sm">
                                        USER EXPOSURE INFORMATION
                                      </span>
                                      <IoClose
                                        onClick={() => setExposPopUp(null)}
                                        className="text-xl"
                                      />
                                    </div>
                                  </div>
                                  <div className="relative w-full md:w-[700px] overflow-x-auto">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                          <th
                                            scope="col"
                                            className="px-6 py-3 text-nowrap bg-black text-white border border-white"
                                          >
                                            Match Name
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-6 py-3 text-nowrap bg-black text-white border border-white"
                                          >
                                            Market/FancyName
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-6 py-3 text-nowrap bg-black text-white border border-white"
                                          >
                                            Exposure
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {exposure.length != 0 ? (
                                          exposure.map((exp) => {
                                            return (
                                              <tr className="bg-white  dark:bg-gray-800 dark:border-gray-700">
                                                <td className="px-6 py-2 border">
                                                  {exp.Match
                                                    ? exp.Match
                                                    : "Not Defined"}
                                                </td>
                                                <td className="px-6 py-2 border">
                                                  {exp?.Market}
                                                </td>
                                                <td className="px-6 py-2 border">
                                                  {exp?.exposure}
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (""
                                          // <td
                                          //   colSpan={3}
                                          //   className="flex translate-x-[100%] p-10 justify-center overflow-hidden"
                                          // >
                                          //   <div role="status" className="">
                                          //     <svg
                                          //       aria-hidden="true"
                                          //       className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                          //       viewBox="0 0 100 101"
                                          //       fill="none"
                                          //       xmlns="http://www.w3.org/2000/svg"
                                          //     >
                                          //       <path
                                          //         d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                          //         fill="currentColor"
                                          //       />
                                          //       <path
                                          //         d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                          //         fill="currentFill"
                                          //       />
                                          //     </svg>
                                          //     <span className="sr-only">
                                          //       Loading...
                                          //     </span>
                                          //   </div>
                                          // </td>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </button>
                            )}
                          </td>
                          <td className="border relative border-gray-300 px-4">
                            <div className="w-full gap-1 items-center flex">
                              <button
                                onClick={() => setDepositPopUp(data?.Id)}
                                className="border rounded bg-[#1c84c6] text-white px-3 py-1"
                              >
                                D
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    console.log("amount: ", data.ResultAmountP)
                                    setDeposit({
                                      ...deposit,
                                      userId: data.Id,
                                      ParentId: data.ParentId,
                                      currentAmmountU: data.ResultAmountU ? data.ResultAmountU : 0,
                                      currentAmmountP: data.ResultAmountP,
                                    });
                                  }}
                                  className={`fixed p-4 flex justify-center items-center top-0 left-0 w-full min-h-screen z-[99999] backdrop-blur-sm  ${
                                    depositPopUp == data?.Id
                                      ? "visible"
                                      : "invisible"
                                  } bg-[rgba(0,0,0,0.6)]`}
                                >
                                  <div
                                    className={`bg-white max-w-[550px] shadow-lg rounded-md px-4 py-10 flex flex-col gap-10 ${
                                      depositPopUp == data?.Id ? "popup" : ""
                                    }`}
                                  >
                                    <div className="text-center p-4 flex flex-col gap-7">
                                      <h2 className="font-bold text-slate-600 text-3xl">
                                        Deposit in - {data?.UserName}
                                      </h2>
                                      <input
                                        defaultValue={deposit.depositAmmount}
                                        onChange={(e) =>{
                                          console.log('object',e.target.value);
                                          setDeposit({
                                            ...deposit,
                                            depositAmmount: e.target.value,
                                            type: "deposit",
                                          })}
                                        }
                                        min={0}
                                        placeholder="Amount"
                                        className="newClient rounded shadow border px-3 text-black py-1 outline-[#1fabb5]"
                                        type="number"
                                        name=""
                                        id=""
                                      />
                                    </div>
                                    <div className="flex justify-center gap-4">
                                      <button
                                        onClick={() => {
                                          depositHandler();
                                          setDepositPopUp(null);
                                        }}
                                        className="bg-blue-500 text-white px-5 rounded py-2"
                                      >
                                        Submit
                                      </button>
                                      <button
                                        onClick={() => {
                                          setDepositPopUp(null);
                                          setDeposit({
                                            manage: isLogin?.userName,
                                            depositAmmount: 0,
                                          });
                                        }}
                                        className="bg-slate-400 text-white px-5 rounded py-2"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <button
                                onClick={() => setWithdrowPopUp(data?.Id)}
                                className="border rounded text-white bg-[#ed5565] px-3 py-1"
                              >
                                W
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeposit({
                                      ...deposit,
                                      userId: data.Id,
                                      ParentId: data.ParentId,
                                      currentAmmountU: data.ResultAmountU,
                                      currentAmmountP: data.ResultAmountP,
                                    });
                                  }}
                                  className={`fixed p-4 flex justify-center items-center top-0 left-0 w-full min-h-screen z-[99999] backdrop-blur-sm  ${
                                    withdrowPopUp == data?.Id
                                      ? "visible"
                                      : "invisible"
                                  } bg-[rgba(0,0,0,0.6)]`}
                                >
                                  <div
                                    className={`bg-white max-w-[550px] shadow-lg rounded-md px-4 py-10 flex flex-col gap-10 ${
                                      withdrowPopUp == data?.Id ? "popup" : ""
                                    }`}
                                  >
                                    <div className="text-center p-4 flex flex-col gap-7">
                                      <h2 className="font-bold text-slate-600 text-3xl">
                                        Withdrow from - {data?.UserName}
                                      </h2>
                                      <input
                                        value={deposit.depositAmmount}
                                        onChange={(e) =>
                                          setDeposit({
                                            ...deposit,
                                            depositAmmount: e.target.value,
                                            type: "withdraw",
                                          })
                                        }
                                        min={0}
                                        placeholder="Amount"
                                        className="newClient rounded shadow border px-3 text-black py-1 outline-[#1fabb5]"
                                        type="number"
                                        name=""
                                        id=""
                                      />
                                    </div>
                                    <div className="flex justify-center gap-4">
                                      <button
                                        onClick={() => {
                                          depositHandler();
                                          setWithdrowPopUp(null);
                                        }}
                                        className="bg-blue-500 text-white px-5 rounded py-2"
                                      >
                                        Submit
                                      </button>
                                      <button
                                        onClick={() => {
                                          setWithdrowPopUp(null);
                                          setDeposit({
                                            manage: isLogin?.userName,
                                            depositAmmount: 0,
                                          });
                                        }}
                                        className="bg-slate-400 text-white px-5 rounded py-2"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </button>
                              <button
                                onClick={() => {
                                  if (clientSettings == data?.Id) {
                                    setClientSettings(null);
                                  } else {
                                    setClientSettings(data?.Id);
                                  }
                                }}
                                className="flex bg-[#1fabb5] px-2 rounded py-2 text-white"
                              >
                                <RiSettings3Fill />
                                <FaCaretDown />
                                <div
                                  className={`absolute bg-white flex flex-col gap-[2px] z-[9999] top-[85%] left-0 w-[91%] ${
                                    clientSettings == data?.Id
                                      ? "visible"
                                      : "invisible"
                                  }`}
                                >
                                  <div className="bg-[#1fabb5] py-1 hover:bg-slate-700">
                                    <Link
                                      to={"/profitandloss/admin/" + data.Id +
                                        `${
                                          dataTable.roleId == 8
                                            ? "/" + dataTable.roleId
                                            : ""
                                        }`}
                                    >
                                      P&L
                                    </Link>
                                  </div>
                                  <div className="bg-[#1fabb5] py-1 hover:bg-slate-700">
                                    <Link
                                      to={
                                        "/statementByUser/admin/" +
                                        data?.UserName +
                                        "/" +
                                        data?.Id +
                                        `${
                                          dataTable.roleId == 8
                                            ? "/" + dataTable.roleId
                                            : ""
                                        }`
                                      }
                                    >
                                      STMT
                                    </Link>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })
                : ""}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
