import React from 'react';
import {Link} from 'react-router-dom';

export default function BreadCrum({breadCrum}) {
  return (
    <div className='px-4 bg-white py-2 border-t-[1px] border-b-[1px] my-2'>
        <h2 className='lg:text-2xl text-lg font-sans text-gray-500 uppercase'>{breadCrum?.topName}</h2>
        <div className=''>
            <Link to={"/dashboard"}>
                <span className='text-sm font-semibold text-gray-500'>
                    Dashboard
                </span>
            </Link>
            <span className='text-gray-400 px-2'>/</span>
            {
              breadCrum.prevPath ? <Link to={breadCrum.prevPath}>{breadCrum?.lastName}</Link> :
              <span className='text-sm font-semibold text-gray-500 uppercase'>{breadCrum?.lastName}</span>
            }
            
            {
              breadCrum.finalName &&
              <>
              <span className='text-gray-400 px-2'>/</span>
              <span className='text-sm font-semibold text-black uppercase'>{breadCrum?.finalName}</span>
              </>
            }
        </div>
    </div>
  )
}
