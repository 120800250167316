import React, { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MainContext } from "../contexts/Main";
import BreadCrum from "../components/BreadCrum";
import axios from "axios";

export default function UserPasswordChange() {
  const navigate = useNavigate();
  const { user } = useParams();
  const { isLogin, notify, apiBaseUrl, apiAdminsUrl, apiUserUrl,admin_stages, edit } = useContext(MainContext);
//   console.log("edit data: ", edit)
  const [changePass, setChangePass] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const saveChanges = () => {
    if (isLogin != false) {
      let id = isLogin.userId;
      let { old_password, new_password, confirm_password } = changePass;
      if (new_password != "" && old_password != "" && confirm_password != "") {
        if (new_password.length >= 5) {
          if (new_password == confirm_password) {
            if (old_password != new_password) {
                if(edit!=null){
                    const userId = edit.Id
                    axios.patch(`${apiBaseUrl+apiUserUrl}/change-password/${userId}`,{new_password,old_password}).then((success)=>{
                        console.log(success)
                        if(success.data.status){
                            notify(success.data.message, "success");
                            setTimeout(()=>{
                                navigate(breadCrum?.path);
                            }, 2000)
                            setChangePass({
                                old_password: "",
                                new_password: "",
                                confirm_password: "",
                              })
                        }else{
                            notify(success.data.message, "error");
                        }
                    })
                }else{
                    notify("user details missing..", "error");
                }
            } else {
              notify("Password Already Used Once", "error");
            }
          } else {
            notify("Both password must match", "error");
          }
        } else {
          notify("The length of the input exceeds 5 characters.");
        }
      } else {
        notify("Enter password", "error");
      }
    } else {
      notify("User not logged in", "error");
    }
  };

  //breadCrum details
  const breadCrum = admin_stages.find((d) => edit?.RoleId == d.roleId);
  return (
    <div className="w-full min-h-[100vh]  bg-white text-black">
      <div className="px-4 select-none bg-white md:py-2 py-4 border-t-[1px] border-b-[1px] my-2">
        <h2 className="lg:text-2xl text-lg font-sans text-gray-500 uppercase">
          Change Password
        </h2>
        <div className="">
          <Link to={"/dashboard"}>
            <span className="text-sm font-semibold text-gray-500">
              Dashboard
            </span>
          </Link>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            <Link to={breadCrum?.path}>agents</Link>
          </span>
          <span className="text-gray-400 px-2">/</span>
          <span className="text-sm font-semibold text-gray-500 uppercase">
            user
          </span>
        </div>
      </div>
      <div className="passBox ">
        <div className="border font-semibold p-2 my-2">Change Password</div>
        <div className="py-2 px-6 text-sm">
          <div className="flex my-2 justify-center gap-2 md:gap-3 items-center font-semibold">
            <span className="w-[150px]">OLD PASSWORD</span>
            <input
              onChange={(e) => {
                let value = e.target.value;
                setChangePass({
                  ...changePass,
                  old_password: value.replace(" ", ""),
                });
              }}
              type="password"
              placeholder="OLD PASSWORD"
              value={changePass.old_password}
              className="outline-green-600 text-[12px] font-normal border-gray-400 w-[80%] border-2 rounded py-2 px-4"
              name=""
              id=""
            />
          </div>
          <div className="flex my-2 justify-center gap-2 md:gap-3 items-center font-semibold">
            <span className="w-[150px]">NEW PASSWORD</span>
            <input
              onChange={(e) => {
                let value = e.target.value;
                setChangePass({
                  ...changePass,
                  new_password: value.replace(" ", ""),
                });
              }}
              type="password"
              placeholder="NEW PASSWORD"
              value={changePass.new_password}
              className="outline-green-600 text-[12px] font-normal border-gray-400 w-[80%] border-2 rounded py-2 px-4"
              name=""
              id=""
            />
          </div>
          <div className="flex my-2 justify-center gap-2 md:gap-3 items-center font-semibold">
            <span className="w-[150px]">CONFIRM PASSWORD</span>
            <input
              onChange={(e) => {
                let value = e.target.value;
                setChangePass({
                  ...changePass,
                  confirm_password: value.replace(" ", ""),
                });
              }}
              type="password"
              placeholder="CONFIRM PASSWORD"
              value={changePass.confirm_password}
              className="outline-green-600 text-[12px] font-normal border-gray-400 w-[80%] border-2 rounded py-2 px-4"
              name=""
              id=""
            />
          </div>
          <div className="flex justify-center w-[45%]">
            <button
              onClick={saveChanges}
              className="py-2 px-3 rounded-lg bg-[#1fabb5] text-white my-5"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
